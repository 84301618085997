import { Injectable } from '@angular/core';
import { Actions, ofActionCompleted } from '@ngxs/store';
import { delay, filter, throttleTime } from 'rxjs';

import { ItineraryAction } from '@hiptraveler/data-access/itinerary';

@Injectable()
export class ItineraryDateViewStateService {

  constructor(
    private actions$: Actions
  ) { }

  deletedMarkerObserver(callback: () => void): void {
    this.actions$.pipe(
      ofActionCompleted(ItineraryAction.UpdateItineraryActivity),
      filter(e => e.result.successful),
      delay(100),
      throttleTime(1000),
      filter(Boolean)
    ).subscribe(callback.bind(this));
  }

}
