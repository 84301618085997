import { BasicInfoData } from "@hiptraveler/data-access/api";

export function parseBasicInfo(basicInfo?: Partial<BasicInfoData> | null): Partial<BasicInfoData> | null | undefined {

  if (basicInfo && !basicInfo?.coverImage) {
    basicInfo.coverImage = {
      imgUrl: "https://www.hiptraveler.com/assets/img/contactimg.webp",
      imgThumbnail: "https://www.hiptraveler.com/assets/img/contactimg.webp",
      mobileUrl: "https://www.hiptraveler.com/assets/img/contactimg.webp",
      originalUrl: "https://www.hiptraveler.com/assets/img/contactimg.webp",
      attribution: "HipTraveler",
      hasCoverImage: true
    }
  }

  if (basicInfo && basicInfo?.author?.authorReference === 'htVisitor') {
    basicInfo.author.authorName = 'Visitor';
  }

  return basicInfo;
}
