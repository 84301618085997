import { Directive, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Actions, Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { BasicInfoData } from '@hiptraveler/data-access/api';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { AppListenerService, NavbarControlStateService, SearchPageControlStateService, currentLang, updateOrderItinerariesKey } from '@hiptraveler/common';
import { ComponentStateService } from '@hiptraveler/features/itinerary';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { ItineraryActionDirective, toLocationObject } from '../common';

/**
 * A directive that handles actions triggered from navbar buttons.
 */
@Directive({
  selector: '[navbarAction]'
})
export class NavbarActionDirective extends ItineraryActionDirective implements OnInit {

  constructor(
    private location: Location,
    a: Router, b: MatDialog, c: Store, d: Actions, e: AppListenerService,
    f: AuthDialogActionService, g: NavbarControlStateService,
    h: SearchPageControlStateService, i: ComponentStateService
  ) {
    super(a, b, c, d, e, f, g, h, i);
  }

  ngOnInit(): void {
    this.authenticationObserver(this.composePreview.bind(this));
    this.navbarButtonListener('exitFocusMode', this.exitFocusMode);
    this.navbarButtonListener('composePreview', this.composePreview);
    this.navbarButtonListener('composeSave', this.composeSave);
  }

  ngOnDestroy(): void {
    this.resetAuthHandle();
    this.componentState.reset();
    this.subscription$.next();
  }

  /**
   * Generate a preview of the blog and navigate to the preview page.
   */
  async composePreview(): Promise<void> {
    if (!this.store.selectSnapshot(ItineraryState.basicInfo)?.pageTitle) {
      setTimeout(() => this.navbarControl.navbarToolbarActionComplete$$.next(), 350);
      return;
    }
    const pageTitle = this.location.path().replace(`/${currentLang()}/compose/itinerary/`, '');
    this.router.navigate([ currentLang(), 'itinerary', pageTitle ]);
    setTimeout(() => this.navbarControl.navbarToolbarActionComplete$$.next(), 350);
  }

  /**
   * Save the blog content to the server.
   */
  async composeSave(): Promise<void> {

    if (this.authTrigger('itineraryComposePage')) return;

    this.observeDispatchSuccess();

    try {
      const updateOrderItineraries = sessionStorage.getItem(updateOrderItinerariesKey);
      const promises = [ this.basicInfoUpdate() ];
      updateOrderItineraries && promises.push(this.updateOrderUpdate(updateOrderItineraries));
      this.searchPageControl.featureCardProcessing$$.next(true);
      await Promise.all(promises);
    } finally {
      this.searchPageControl.featureCardProcessing$$.next(false);
      this.navbarControl.navbarToolbarActionComplete$$.next();
    }
  }

  /**
   * Update basic information of the blog in the store and dispatch a save action.
   */
  private async basicInfoUpdate(): Promise<void> {

    const basicInfo = this.store.selectSnapshot(ItineraryState.basicInfo);
    if (!basicInfo) return;
    
    let newBasicInfo = { ...basicInfo } as any;
    const value = this.componentState.subscription$$.value;
    newBasicInfo.title = this.navbarControl.navbarTitleState$$.value || basicInfo.title;
    // newBasicInfo.content = encryptString(value?.storyContent || basicInfo?.content || '');
    const element = document.getElementsByClassName('hiptraveler-content-editable')[0] as HTMLDivElement;
    newBasicInfo.content = element?.innerHTML || basicInfo?.content;
    newBasicInfo.blogContent = value?.hasStoryContent;
    newBasicInfo.locations = value?.locationList?.map(e => toLocationObject(e)).filter(Boolean);
    newBasicInfo.coverImage = this.parsedCoverImageByComponentState;
    newBasicInfo.lang = newBasicInfo?.setLang;

    delete newBasicInfo['setLang'];
    delete newBasicInfo['locationList'];

    return firstValueFrom(this.store.dispatch(new ItineraryAction.UploadItineraryBlog<Partial<BasicInfoData>>(newBasicInfo, 'itinerary')));
  }

}
