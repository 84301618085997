import { Directive, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { flatten, forEach, map as _map } from 'lodash';
import { takeUntil, tap, filter } from 'rxjs';

import { LocationData } from '@hiptraveler/data-access/api';
import { SearchState } from '@hiptraveler/data-access/search';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { LeafletMap, LeafletMapControlStateService } from '@hiptraveler/features/leaflet-map';
import { LeafletRef, NewItineraryObserverService } from './new-itinerary-observer.service';
import { markerIconBuilder, toGeoJSON } from '../utils';
import { promiseDelay, SearchLocationData, SearchLocationService, SearchPageControlStateService, SearchResultData } from '@hiptraveler/common';
import { filterValidSearchResults as parse, getUnaddedSearchResults } from './search-page-map-fn';

@Directive()
export class SearchPageMap extends LeafletMap {

  #mapMarkersState: boolean;

  constructor(
    a: Router, b: ElementRef<HTMLElement>, c: LeafletMapControlStateService,
    protected store: Store,
    protected searchLocation: SearchLocationService,
    protected searchPageControl: SearchPageControlStateService,
    protected newItineraryObserver: NewItineraryObserverService,
  ) {
    super(a, b, c);
  }

  /**
   * Subscribes to updates in search location data from getLocationDataByQuery request and recenters the map accordingly.
   *
   * This method listens for changes to the search location data from the store. When a valid location is provided,
   * it clears all current map layers and updates the map view center to the new location's latitude and longitude
   * with a zoom level of 9.
   */
  searchLocationDataObserver(): void {
    this.store.select(SearchState.locationData).pipe( /* Search result location changes observer */
      filter(Boolean),
      tap((locationData: LocationData) => {
        if (!this.mapView || this.#mapMarkersState) return;
        this.clearAllLayers();
        this.updateMapView(
          [ +(locationData?.latitude ?? '0'), +(locationData?.longitude ?? '0') ],
          locationData?.latitude ? 9 : 2
        );
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Subscribes to updates in search location data from getAutocompleteLocation request and recenters the map accordingly.
   *
   * This method listens for changes to the search location data from the store. When a valid location is provided,
   * it clears all current map layers and updates the map view center to the new location's latitude and longitude
   * with a zoom level of 9.
   */
  searchLocationChangesObserver(): void {
    this.searchLocation.searchLocation$.pipe(
      tap((searchLocation: SearchLocationData) => {
        this.clearAllLayers();
        this.newItineraryObserver.removeAllAddedMarkers(); 
        this.updateMapView(
          [ +(searchLocation?.latitude ?? 0), +(searchLocation?.longitude ?? 0) ],
          searchLocation?.latitude ? 9 : 2
        );
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Initializes colored markers for each itinerary activity.
   *
   * Extracts each activity's day color and generates themed marker icons for 'adventure', 
   * 'hotel', and 'food' categories using the markerIconBuilder. The generated icons are 
   * then applied to the map. Finally, the observer for updating marker colors is started.
   */
  initializeItineraryColoredMarkers(): void {
    const activities = this.store.selectSnapshot(ItineraryState.actDateArr) || [];
    activities.map((e: any) => e.dayColor).forEach((theme: string, index: number) => {
      [ 'adventure', 'hotel', 'food' ].forEach((type: any) => {
        const { field, icon } = markerIconBuilder({ theme, type, day: index + 1 });
        (this.markerIcon as any)[field] = this.Leaflet.divIcon(icon);
      });
    });
    this.startItineraryMarkerColorObserver();
  }

  /**
   * Starts an observer to monitor the addition of new itinerary POI markers and update their colors
   * based on the backend specifications for each itinerary activity date.
   *
   * This method periodically checks for the presence of markers on the map every 100ms. Once markers
   * are detected, it creates a reference object containing the necessary Leaflet properties, invokes
   * the observer to update the marker colors, and then clears the interval.
   */
  private startItineraryMarkerColorObserver(): void {
    const interval = setInterval(() => {

      if (this.newItineraryObserver.observing) {
        clearInterval(interval); return;
      }

      if (!this.mapMarkers.length) return;
      const leafletRef: LeafletRef = {
        Leaflet: this.Leaflet, mapView: this.mapView!, mapMarkers: this.mapMarkers,
        markerIcon: this.markerIcon, subscription$: this.subscription$
      };
      this.newItineraryObserver.observe(leafletRef);
      clearInterval(interval!);
    }, 100);
  }
  
  /**
   * Renders map markers based on the type of search results provided.
   *
   * This method checks the type of the first search result:
   * - If it is a "profile" result, it renders profile-specific markers.
   * - Otherwise, it renders itinerary markers for unadded search results.
   *
   * @param results - An array of search result data objects from the search request.
   */
  renderMarkersFromSearchResults(results: SearchResultData[]): SearchResultData[] | [] {
    this.#mapMarkersState = true;
    return results[0]?.type === 'profile'
      ? this.renderProfileMapMarkers(results)
      : this.renderItineraryMapMarkers(results);
  }

  /**
   * Renders map markers for all user profile search results.
   *
   * @param results - An array of search result itineraries retrieved from the search request.
   */
  private renderProfileMapMarkers(results: SearchResultData[]): [] {
    this.setGeoJSON(toGeoJSON(results), this.markerIcon.profileMarkerIcon);
    return [];
  }

  /**
   * Plots markers on the itinerary map for search results that haven't been added yet.
   *
   * This method retrieves the unadded search result POIs (points of interest) that have valid coordinates from the provided results,
   * groups them by category (hotels, adventures, foods), parses each group, and then plots each group on the map using the appropriate marker icon.
   *
   * @param results - An array of search result itineraries obtained from the search request.
   * @returns Array of all search result data objects that were successfully plotted on the map.
   */
  private renderItineraryMapMarkers(results: SearchResultData[]): SearchResultData[] {
    const activities = this.store.selectSnapshot(ItineraryState.actDateArr) || [];
    const { hotels, adventures, foods } = getUnaddedSearchResults(results, activities);
  
    const groups = {
      hotels: { data: parse(hotels), icon: this.markerIcon.hotelMarkerIcon },
      adventures: { data: parse(adventures), icon: this.markerIcon.adventureMarkerIcon },
      foods: { data: parse(foods), icon: this.markerIcon.foodMarkerIcon }
    };
  
    forEach(groups, ({ data, icon }) => {
      data.length && this.setGeoJSON(toGeoJSON(data), icon);
    });
  
    return flatten(_map(groups, 'data'));
  }
  
  /**
   * Recenters the map view on the replacement itinerary's position, if available.
   *
   * This asynchronous method checks if a replacement itinerary is defined in the searchPageControl.
   * If present, it waits for 1 second to allow any ongoing UI transitions to complete, then
   * sets the map view's center to the itinerary's position while maintaining the current zoom level.
   */
  async setReplaceItineraryPosition(): Promise<void> {
    
    const itinerary = this.searchPageControl.replaceItineraryActivity;
    if (!itinerary) return;

    await promiseDelay(1000); // Wait for 1s after Leaflet Map Bound UX to zoom to the itinerary position
    this.updateMapView(itinerary.position, this.mapView?.getZoom());
  }

}
