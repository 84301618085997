import { ElementRef, Injectable } from '@angular/core';
import { Actions, ofActionCompleted, ofActionDispatched, Select, Store } from '@ngxs/store';
import { delay, filter, Observable, Subject, take, takeUntil, tap } from 'rxjs';

import { UserItineraryData } from '@hiptraveler/data-access/api';
import { AuthAction } from '@hiptraveler/data-access/auth';
import { UserAction, UserState } from '@hiptraveler/data-access/user';

@Injectable()
export class SkeletonUiService {

  @Select(UserState.itineraries) itineraries$: Observable<UserItineraryData[] | null>;

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private store: Store,
    private actions$: Actions
  ) {}

  /**
   * Initializes subscriptions to update the skeleton UI based on user session and itinerary data.
   * - Shows the skeleton UI when a visitor session check or user details request is dispatched.
   * - Updates/hides the skeleton UI upon completion of these actions.
   */
  initSkeletonUIUpdates(): void {
    // When visitor session check is dispatched, ensure the skeleton UI is visible.
    this.actions$.pipe(
      ofActionDispatched(UserAction.CheckVisitorSession),
      tap(() => this.showSkeleton()),
      takeUntil(this.subscription$)
    ).subscribe();

    // When visitor session check is completed, update the skeleton UI.
    // After a delay (to allow for animation), hide the skeleton UI if the user is not authenticated and has no itineraries.
    this.actions$.pipe(
      ofActionCompleted(UserAction.CheckVisitorSession),
      tap(() => this.updateSkeletonVisibilityBasedOnItineraries()),
      delay(1000), // Animation transition duration
      tap(() => {
        const authenticated = this.store.selectSnapshot(UserState.authenticated);
        const hasItineraries = !!this.store.selectSnapshot(UserState.itineraries)?.length;
        if (!authenticated && !hasItineraries) {
          this.hideSkeleton();
        }
      }),
      takeUntil(this.subscription$)
    ).subscribe();

    // For authenticated users, when user details are requested, show the skeleton UI.
    this.actions$.pipe(
      filter(() => this.store.selectSnapshot(UserState.authenticated)),
      ofActionDispatched(AuthAction.GetUserDetails),
      tap(() => this.showSkeleton()),
      takeUntil(this.subscription$)
    ).subscribe();

    // Once user details are loaded, update the skeleton UI based on itinerary data.
    this.actions$.pipe(
      filter(() => this.store.selectSnapshot(UserState.authenticated)),
      ofActionCompleted(AuthAction.GetUserDetails),
      tap(() => this.updateSkeletonVisibilityBasedOnItineraries()),
      takeUntil(this.subscription$)
    ).subscribe();

    // When blogs and itinerary data are set for authenticated users,
    // after a delay, hide the skeleton UI if no itineraries exist.
    this.actions$.pipe(
      filter(() => this.store.selectSnapshot(UserState.authenticated)),
      ofActionCompleted(UserAction.SetBlogsAndItineraryData),
      delay(1000),
      tap(() => this.hideSkeletonIfNoItineraries()),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Subscribes to itinerary data and toggles the skeleton UI display:
   * - Hides the skeleton if itineraries exist.
   * - Shows the skeleton if no itineraries exist.
   */
  private updateSkeletonVisibilityBasedOnItineraries(): void {
    this.itineraries$.pipe(
      tap(itineraries => {
        const displayStyle = itineraries?.length ? 'none' : 'block';
        this.setSkeletonDisplay(displayStyle);
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Hides the skeleton UI if there are no itineraries.
   * This subscription takes only one emission.
   */
  private hideSkeletonIfNoItineraries(): void {
    this.itineraries$.pipe(
      tap(itineraries => {
        if (!itineraries?.length) {
          this.hideSkeleton();
        }
      }),
      take(1)
    ).subscribe();
  }

  /**
   * Sets the display style of the skeleton UI element.
   * @param displayStyle - A valid CSS display value (e.g., 'block' or 'none').
   */
  private setSkeletonDisplay(displayStyle: string): void {
    this.element.nativeElement.style.display = displayStyle;
  }

  /**
   * Shows the skeleton UI.
   */
  private showSkeleton(): void {
    this.setSkeletonDisplay('block');
  }

  /**
   * Hides the skeleton UI.
   */
  private hideSkeleton(): void {
    this.setSkeletonDisplay('none');
  }
}
