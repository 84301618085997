import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SkyscannerViewModule } from '@hiptraveler/features/skyscanner-view';
import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';

import { NavigationDialogComponent } from './navigation-dialog.component';

const MODULES = [
  CommonModule,
  MatProgressSpinnerModule
];

const LIBRARIES = [
  SkyscannerViewModule,
  DialogCloseButtonModule
];

const COMPONENTS = [
  NavigationDialogComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ]
})
export class NavigationDialogModule { }
