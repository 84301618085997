import { Pipe, PipeTransform } from '@angular/core';

import { TranslationService } from '@hiptraveler/common';
import { Type } from './common';

@Pipe({
  name: 'titleByType'
})
export class TitleByTypePipe implements PipeTransform {

  constructor(
    private i18n: TranslationService
  ) { }

  transform(value: Type): string {
    switch (value) {
      case 'travelAgent': return this.i18n.getText('reg-form', 'complete-reg');
      case 'ambassador':  return this.i18n.getText('reg-form', 'creator-house-application');
      default:            return this.i18n.getText('reg-form', 'complete-reg');
    }
  }

}
