import { getWindowRef, PromiseReject, PromiseResolve } from "@hiptraveler/common";

export function googleMapsClient(): Promise<any> {
  return new Promise((resolve: PromiseResolve, reject: PromiseReject) => {

    const windowRef = getWindowRef();

    let
      bodyElement: HTMLBodyElement | null = null,
      scriptElement: HTMLScriptElement | null = null;
  
    if (typeof windowRef === 'object' && windowRef) {
      bodyElement = windowRef?.document?.body as HTMLBodyElement;
      scriptElement = windowRef?.document?.createElement('script') as HTMLScriptElement;
    }
  
    if (!bodyElement || !scriptElement) return;

    scriptElement.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCqAtSJtOWfmyHaq3GrU1z3F-bnKi8FOA0&libraries=visualization,places&callback=Function.prototype&loading=async';
    scriptElement.defer = true;

    bodyElement.appendChild(scriptElement);

    scriptElement.onload = () => {
      try {

        resolve(null);
        console.log('** 3rd party CDN: Google Maps script loaded successfully.');
      } catch (innerError) {

        reject(innerError);
        console.error('Error initializing Google Maps:', innerError);
      }
    };
  });
}
