
/*  */
export const preventRemoveKey: string = 'prevent_itinerary_blog_remove_state';

/*  */
export const preventUpdateFormStateByPlaceResult = 'prevent_update_form_state_by_place_result';

/*  */
export const fromComposePageNotOwner = 'from_compose_page_not_owner';

/*  */
export const exitFocusModeGlobalListenerKey = 'exit_focus_mode_global_listener_key';
