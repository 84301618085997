<div class="itinerary-action-panel mat-elevation-z12">

  <ng-container *ngIf="searchPageControl.activityDate$ | async as value;else selection">

    <div
      [actionPanelStyles]="actionPanel" [activityDate]="value.actDate"
      [itineraryAdded]="(activityId | addedToCurrentDay | async)!">
    </div>

    <ng-container *ngIf="activityId | addedToCurrentDay | async">
      <div class="panel--selection panel--date panel--add-date" mat-ripple (pointerdown)="addToCurrentDay()">
        <mat-icon>add</mat-icon>
        <p><strong>{{ 'itinerary-action.add-current-day' | translate }}</strong></p>
      </div>
    </ng-container>

    <div
      mat-ripple
      class="panel--selection panel--date"
      [class.highlight-date]="
        (searchPageControl.activityDate$ | async)?.day === activity.day 
        && !(activityId | validateId: i+1 | async)"
      *ngFor="let activity of value.actDate;let i = index"
      (pointerdown)="service.addToDate(activity)">
      
      <mat-icon [ngStyle]="{ color: value?.actDateMap?.[i+1]?.dayColor || '#525558' }">pin_drop</mat-icon>

      <p [ngStyle]="{ color: value?.actDateMap?.[i+1]?.dayColor || '#525558' }">{{ activity | dateString: i }}</p>

      <mat-icon
        class="panel--date-marker"
        [ngStyle]="{ color: value?.actDateMap?.[i+1]?.dayColor || '#525558' }"
        *ngIf="!!(activityId | validateId: i+1 | async)">
        done
      </mat-icon>

    </div>

    <ng-container *ngIf="activityId | addedToAllDays | async">
      <div class="panel--selection panel--date" mat-ripple (pointerdown)="service.addToAllDays(activityId)" *ngIf="hotel">
        <mat-icon>pin_drop</mat-icon>
        <p translate="itinerary-action.add-all-days"></p>
      </div>
    </ng-container>

  </ng-container>
  <ng-template #selection>
    <div class="panel--selection" mat-ripple (pointerdown)="service.openMyTrips()">{{ 'itinerary-action.my-trips' | translate }}</div>
    <div class="panel--selection" mat-ripple (pointerdown)="service.openCreateNewTrip()">{{ 'itinerary-action.create-new-trip' | translate }}</div>
  </ng-template>

</div>
