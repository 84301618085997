import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ChatCompletion } from 'openai/resources';
import { firstValueFrom, map, switchMap, take, tap } from 'rxjs';
import { flatMap, uniqBy } from 'lodash';

import { SearchApiService } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { ChatCompletionsService } from '@hiptraveler/core/openai';
import { DEFAULT_BRAND_NAME, promiseDelay, subdomain } from '@hiptraveler/common';
import { EFAStateServiceService, CarouselFilterType, CarouselService, FormValue, mainSelectionPrompts, parseBrandState, inputLocationPrompt, parseStateLocation, FormCarouselData, responseToValue, ScreenView } from '../../shared';

@Injectable()
export class ScreenMainSelectionService {

  constructor(
    private store: Store,
    private searchApi: SearchApiService,
    private completions: ChatCompletionsService,
    private stateService: EFAStateServiceService,
    private carousel: CarouselService
  ) { }

  observe(): void {
    this.stateService.inputMessage$.subscribe((message: string) => {
      this.observeInputMessage(message);
    });
  }

  private observeInputMessage(message: string): void {
    this.stateService.screenViewValue$.pipe(
      switchMap(() => {
        this.stateService.inputPending$$.next(true);
        this.stateService.pauseBrandInfoUXState$$.next(false);
        const experiences = parseBrandState(this.store.selectSnapshot(BrandState.state))?.experiences;
        const keyCodes = experiences?.map(e => e.code || '').filter(Boolean) || [];
        return this.completions.sendMessage(message, mainSelectionPrompts(keyCodes));
      }),
      tap(async (response: ChatCompletion) => {
        try {
          const messageContent = response.choices[0].message.content || '{}';
          console.log('@@@ ', '...0', messageContent);

          const formValue = await JSON.parse(messageContent) as FormValue;
          this.stateService.scrollToView$$.next('next');

          console.log('@@@ ', '.1');

          const parsedFormValue = this.parseValue(formValue);
          this.stateService.patchFormValue(parsedFormValue);

          console.log('@@@ MSS ::', parsedFormValue);

          const highlights = flatMap(parsedFormValue.carouselData, (codes: string[], key: string) =>
            this.carousel.brandState?.experiences
              ?.filter((e: any) => codes.includes(e.code))
              .map((e: any) => ({ output: e?.name, carouselData: key })) || []
            );
          
          await promiseDelay(500);
          const summaryItem$$ = this.stateService.summaryItems$$;
          const value = uniqBy((summaryItem$$.value || []).concat(highlights), 'output');
          summaryItem$$.next(value);

          await promiseDelay(100);
          await this.getLocationResponse(message);

        } catch (e: any) {
          if (e?.message?.includes('not valid JSON')) {
            this.getLocationResponse(message);
          }
        }
        finally {
          this.stateService.inputPending$$.next(false);
          this.stateService.pauseBrandInfoUXState$$.next(true);
        }
      }),
      take(1)
    ).subscribe();
  }

  private parseValue(formValue: any): FormValue {

    const clientInfo = JSON.parse(formValue.clientInfo || '{}');
    const formValueDateRange = JSON.parse(formValue.dateRange || '{}');

    const dateRange =  {
      start: formValueDateRange?.start || '',
      end: formValueDateRange?.end || ''
    };

    const carouselScreenData = (filter: CarouselFilterType) => this.carousel.getDataFromResponseByFilter(
      filter, formValue.carouselData as any
    );

    const carouselData = {
      [ScreenView.experienceCarousel]: carouselScreenData('experience'),
      [ScreenView.travelCarousel]: carouselScreenData('travelStyle'),
      [ScreenView.activitiesCarousel]: carouselScreenData('activity'),
      [ScreenView.locationCarousel]: carouselScreenData('location')
    };

    return {
      clientInfo, dateRange, carouselData
    }
  }

  private async getLocationResponse(inputMessage: string): Promise<void> {

    if (subdomain() !== DEFAULT_BRAND_NAME) return;

    const chatCompletion = await this.completions.sendMessage(inputMessage, inputLocationPrompt);
    const locationResponse = chatCompletion.choices[0].message.content || '';

    if (locationResponse.includes('No location mentioned')) return;

    const request = this.searchApi.getLocationDataByQuery({ q: locationResponse, format: 'basic' });
    const locationData = await firstValueFrom(request.pipe( map(e => responseToValue(e)) ));

    if (!locationData) {
      console.error('[MainSelectionService]: No location data returned from the backend.');
      return;
    }

    const experience = parseStateLocation(locationData);
    this.stateService.appendLocation(experience);
    const data = [ experience?.code || '' ].filter(Boolean);

    if (!data?.length) {
      console.error('[MainSelectionService]: App error code `mss.1`');
      return;
    }

    const carouselData = { ...this.stateService.formValue.carouselData, screen6: data } as FormCarouselData;
    this.stateService.patchFormValue({ carouselData });

    const highlight = { output: experience.name, carouselData: ScreenView.locationCarousel };
    const summaryItem$$ = this.stateService.summaryItems$$;
    const value = uniqBy((summaryItem$$.value || []).concat([ highlight ]), 'output');
    summaryItem$$.next(value);
  }

}
