import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { getLocationData } from '../../functions';

@Directive({
  selector: '[scrollToView]'
})
export class ScrollToViewDirective implements OnInit {

  @Input('scrollToView') id?: string;

  constructor(
    private element: ElementRef<HTMLDivElement>,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.id && this.element.nativeElement.setAttribute('fragment', this.id);
    // setTimeout(() => window.scrollTo({ top: 1700, behavior: 'smooth' }), 0);
  }

  @HostListener('click')
  hostClick(): void {
    this.id && this.navigate();
  }

  async navigate(): Promise<void> {
    const { pathname, locationString } = getLocationData();
    await this.router.navigate([ pathname ], {
      fragment: this.id,
      queryParamsHandling: 'preserve'
    });
    this.location.replaceState(`${locationString}#${this.id}`);
  }

}
