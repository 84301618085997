
export type ScrollDirection = 'up' | 'down';

export type ScreenState = Record<ScreenView, boolean>;

export type CarouselFilterType = 'location' | 'experience' | 'travelStyle' | 'activity';

export type CarouselScreenValue = Extract<ScreenView, ScreenView.experienceCarousel | ScreenView.travelCarousel | ScreenView.activitiesCarousel | ScreenView.locationCarousel>;

export type FormCarouselData = Record<CarouselScreenValue, string[]>;

export type SummaryItems = Record<string, any>[] | null;

export type CarouselHighlight = 'Experiences' | 'Style' | 'Activities';

export interface FormDateRange {
  start: Date | null;
  end: Date | null;
}

export interface FormClientInfo {
  adults: number;
  children: number;
  infants: number;
}

export interface FormValue {
  dateRange: FormDateRange;
  carouselData: FormCarouselData
  clientInfo: FormClientInfo;
}

export type ScreenViewValue = Record<ScreenView, number>;

export enum ScreenView {

  myTrips = 'screen0',

  // Desktop screens
  mainSelection = 'screen1',
  experienceCarousel = 'screen2',
  travelCarousel = 'screen3',
  activitiesCarousel = 'screen4',
  locationCarousel = 'screen5',
  calendar = 'screen6',
  clientInfo = 'screen7',
  summary = 'screen8',
  screen9 = 'screen9',
  screen10 = 'screen10',
  screen11 = 'screen11',
  screen12 = 'screen12',

  // Generic
  firstScreen = 'screen1',
  screen1 = 'screen1',
  screen2 = 'screen2',
  screen3 = 'screen3',
  screen4 = 'screen4',
  screen5 = 'screen5',
  screen6 = 'screen6',
  screen7 = 'screen7',
  screen8 = 'screen8',

  // Mobile screens
  brandMainSelection = 'screen1',
  carouselGrid = 'screen2',
  mobileLocationCarousel = 'screen3',
  mobileCalender = 'screen4',
  mobileClientInfo = 'screen5',
  mobileSummary = 'screen6',

}
