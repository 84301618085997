<form class="footer-nav" [formGroup]="service.form" (ngSubmit)="service.subscribe()">

  <div class="footer-nav--content">
    <div class="footer-nav--data-column">
      <p class="footer-nav--text-title" translate="footer.get-social"></p>
  
      <div class="footer-nav--social-media">
        <mat-icon svgIcon="footer-c.facebook"></mat-icon>
        <mat-icon svgIcon="footer-c.instagram"></mat-icon>
        <mat-icon svgIcon="footer-c.twitter"></mat-icon>
        <mat-icon svgIcon="footer-c.pinterest"></mat-icon>
      </div>
  
      <p class="footer-nav--text-data-item" (click)="service.optionAction('become-ambassador')">
        <span translate="footer.apply-ambassador"></span>
      </p>
  
      <ng-container *ngIf="service.footerImage$ | async as footerImage">
        <img [src]="footerImage" alt="ambassador photo" width="120" height="auto">
      </ng-container>
  
    </div>
  
    <div class="footer-nav--data-column">
  
      <p class="footer-nav--text-title" translate="footer.planning-tools"></p>
      <p class="footer-nav--text-data-item" (click)="service.optionAction('blog-creator')">
        <span translate="footer.blog-creator"></span>
      </p>
      <p class="footer-nav--text-data-item" (click)="service.optionAction('trip-planner')">
        <span translate="footer.trip-planner"></span>
      </p>
      <p class="footer-nav--text-data-item" (click)="service.navigate('hotels')">
        <span translate="footer.find-hotels"></span>
      </p>
      <p class="footer-nav--text-data-item" (click)="service.navigate('travel')">
        <span translate="footer.find-flights"></span>
      </p>
  
      <br>
  
      <p class="footer-nav--text-title" translate="footer.hiptraveler-services"></p>
      <p class="footer-nav--text-data-item" (click)="service.optionAction('tourism-board-solutions')">
        <span translate="footer.tourism-board-solutions"></span>
      </p>
      <p class="footer-nav--text-data-item" (click)="service.optionAction('destination-specialist-program')">
        <span translate="footer.destination-specialist-program"></span>
      </p>
      <p class="footer-nav--text-data-item" (click)="service.optionAction('become-ambassador')">
        <span translate="footer.creator-house"></span>
      </p>
  
    </div>
  
    <div class="footer-nav--data-column">
      <br>
      <p class="footer-nav--text-title" translate="footer.get-in-touch"></p>
      <p class="footer-nav--text-data-item" (click)="service.optionAction('media')">
        <span translate="footer.media"></span>
      </p>
      <p class="footer-nav--text-data-item" (click)="service.optionAction('jobs')">
        <span translate="footer.jobs"></span>
      </p>
      <p class="footer-nav--text-data-item" (click)="service.optionAction('copyright')">
        <span translate="footer.copyright"></span>
      </p>
  
      <br>
  
      <p class="footer-nav--text-title" translate="footer.deliver-the-#hip10-to-your-inbox"></p>
  
      <mat-form-field underlined white appearance="outline">
        <mat-label>{{ 'footer.your-name' | translate }}</mat-label>
        <input matInput name="name" formControlName="name">
      </mat-form-field>
      <mat-form-field underlined white appearance="outline">
        <mat-label>{{ 'footer.your-e-mail-address' | translate }}</mat-label>
        <input matInput name="email" formControlName="email">
      </mat-form-field>
      <ui-action-button type="submit" [pending]="service.processingNewSignUp">{{ 'footer.subscribe' | translate }}</ui-action-button>
  
    </div>
  </div>

</form>
