import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FormBuilder, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subject, EMPTY, Observable, catchError, filter, switchMap, take, tap, map, expand, timer, takeWhile, takeUntil } from 'rxjs';
import { omit } from 'lodash';

import { Ambassador, CSRF_KEY, RegistrationFormDestination, SocialApiPopupService } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { ProfileAction, ProfileState } from '@hiptraveler/data-access/profile';
import { UserState, UserStateModel } from '@hiptraveler/data-access/user';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { RegistrationFormStateService, SocialMediaType } from '@hiptraveler/features/registration-form';
import { CDNReaderService, getWindowRef } from '@hiptraveler/common';

const ASSET_IMAGE: string = 'https://d36j46pvk6qi4f.cloudfront.net/site/main/images/Destination-Specialist-1200.webp';

@Injectable()
export class AmbassadorService implements OnDestroy {

  @Select(UserState.authenticated) authenticated$: Observable<boolean>;

  destinationSpecialistImg: string = ASSET_IMAGE;

  subscription$ = new Subject<void>();

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private http: HttpClient,
    private store: Store,
    private authDialog: AuthDialogActionService,
    private socialApi: SocialApiPopupService,
    private taFormStateService: RegistrationFormStateService,
    private cdnReader: CDNReaderService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
    this.taFormStateService.resetForm();
    getWindowRef()?.sessionStorage?.removeItem(CSRF_KEY);
  }

  get ambassadorHighlightImage$(): Observable<string> {
    return this.cdnReader.getCdnData('base64.ambassadorHighlight').pipe(
      map((e: any) => e.value)
    );
  }

  verifyAuthentication(): void {
    this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open();
  }

  observeSocialMediaPopUp(): void {
    getWindowRef()?.sessionStorage?.removeItem(CSRF_KEY);

    const request = (social: SocialMediaType, value: string) => {
      const url = `/trip-planner/getsmconnect/${social}?state=${value}`
      this.http.get(url).pipe(
        expand((response: any) => {
          if (response?.success) return EMPTY;
          return timer(1000 * 2 /* 2 seconds */).pipe(
            switchMap(() => this.http.get(url)),
            takeUntil(this.socialApi.timer$)
          );
        }),
        takeWhile((response: any) => !response?.success, true),
        takeUntil(timer(1000 * 30 /* 30 seconds */)),
        takeUntil(this.socialApi.timer$),
        takeUntil(this.subscription$)
      ).subscribe({
        next: (response) => {
          if (response?.success) {
            console.log('Got a success response:', response);
            const { medium, handle, id } = response.data
            const form = this.taFormStateService.socialForm;
            form.patchValue({ [medium]: `${handle}::${id}` });
            this.socialApi.poolingSuccess$.next();
          } else {
            console.log('Still waiting, polling again...');
          }
        },
        error: err => console.error('Error while polling:', err),
        complete: () => {
          console.log('Polling complete.');
          this.socialApi.poolingSuccess$.next();
        }
      });
    };

    this.socialApi.stateValue$.pipe(
      takeUntil(this.subscription$)
    ).subscribe(([ type, value ]) => request(type, value));
  }

  validateUser(): void {
    this.store.select(UserState.state).pipe(
      filter(Boolean),
      filter(userState => userState.ambUser),
      switchMap((userState: UserStateModel) => this.store.dispatch(new ProfileAction.GetAmbassador(userState.ambId)).pipe(
        catchError(() => EMPTY)
      )),
      tap(() => {

        const snapshot = this.store.selectSnapshot(ProfileState.ambassador);
        const ambassador = omit(snapshot, [ 'instaConnect', 'tkConnect', 'fbConnect', 'ytConnect', 'twConnect', 'website', 'rss', 'reference', 'experience' ]) as Ambassador;
        if (!ambassador) return;

        this.taFormStateService.form.patchValue({
          ...ambassador,
          promoCode: snapshot?.reference
        });

        const generateConnectString = (connect: { handle: string; id: string } | undefined) =>
          connect ? `${connect.handle}::${connect.id}` : '';

        this.taFormStateService.socialForm.patchValue({
          tiktok: generateConnectString(snapshot?.tkConnect),
          twitter: generateConnectString(snapshot?.twConnect),
          facebook: snapshot?.fbConnect?.handle,
          instagram: snapshot?.instaConnect?.handle,
          youtube: snapshot?.ytConnect?.handle,
          website: snapshot?.website || '',
          rss: snapshot?.rss || ''
        });
        snapshot?.categories?.forEach((item: string) => {
          this.taFormStateService.categoriesForm.push(new FormControl(item));
        });
        snapshot?.subCategories?.forEach((item: string) => {
          this.taFormStateService.subCategoriesForm.push(new FormControl(item));
        });
        snapshot?.languages?.forEach((item: string) => {
          this.taFormStateService.langForm.push(new FormControl(item));
        });
        snapshot?.destinations?.forEach((item: RegistrationFormDestination) => {
          this.taFormStateService.destinationsForm.push(this.fb.group(item));
        });
        this.taFormStateService.trExpForm.patchValue({
          desc: snapshot?.experience
        })
      }),
      take(1)
    ).subscribe();
  }

}
