import { Injectable, ElementRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { firstValueFrom, takeUntil } from 'rxjs';

import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryAction } from '@hiptraveler/data-access/itinerary';
import { CarouselService, EFAStateServiceService, FORM_VALUE, getRequestPayload, ScreenView } from '../shared';

@Injectable()
export class FormSubmitObserverService {

  constructor(
    private element: ElementRef<HTMLElement>,
    private store: Store,
    private stateService: EFAStateServiceService,
    private carousel: CarouselService
  ) { }

  observe(): void {
    this.stateService.formSubmit$.asObservable().pipe(
      takeUntil(this.stateService.subscription$)
    ).subscribe(async () => {
    
      this.toggleLoaderDisplay(true);
      this.stateService.inputPending$$.next(true);
      this.stateService.pauseBrandInfoUXState$$.next(false);

      const formValue = this.stateService.formValue;
      const summaryItems = [ ...(this.stateService.summaryItems$$.value || []) ];

      try {
        await firstValueFrom(this.store.dispatch(new ItineraryAction.AddItinerary(getRequestPayload({
          formValue: this.stateService.formValue$$.value,
          brandState: this.carousel.brandState,
          authenticated: this.store.selectSnapshot(UserState.authenticated)
        }))));
        this.stateService.patchFormValue(FORM_VALUE);
        this.stateService.summaryItems$$.next([]);
        this.stateService.scrollToScreen$$.next(this.stateService.view[ScreenView.mainSelection]);
      } catch (error) {
        this.stateService.patchFormValue(formValue);
        this.stateService.summaryItems$$.next(summaryItems);
      } finally {
        this.stateService.formSubmitCompleted$.next();
      }

      this.toggleLoaderDisplay(false);
      this.stateService.inputMessage$$.next('');
      this.stateService.inputPending$$.next(false);
      this.stateService.pauseBrandInfoUXState$$.next(true);
    });
  }

  private toggleLoaderDisplay(state: boolean): void {
    const parentElement = this.element.nativeElement.parentElement!;
    const loaderScreen = parentElement.getElementsByClassName('experience-finder-ai-loader-screen')[0]! as HTMLElement;

    if (state) {
      loaderScreen.style.zIndex = '3';
      loaderScreen.style.display = 'block';
    } else {
      loaderScreen.style.zIndex = '0';
      loaderScreen.style.display = 'none';
    }
  }

}
