import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivationEnd, Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subject, delay, filter, map, of, startWith, switchMap, takeUntil } from 'rxjs';

import { LocationData, SearchResultPagination } from '@hiptraveler/data-access/api';
import { SearchAction, SearchState } from '@hiptraveler/data-access/search';
import { AppListenerService, SearchLocationService, WINDOW, currentLang } from '@hiptraveler/common';

const mapVisibility_notAllowedRoutes = [ 'experiences' ];
const loadMoreVisibility_allowedRoutes = [ 'thingstodo', 'hotels', 'foodanddrink', 'community', 'experiences' ];
const pageOnly_allowedRoutes = [ 'search' ];

@Injectable()
export class SearchPageRootService implements OnDestroy {

  subscription$ = new Subject<void>();
  
  constructor(
    @Inject(WINDOW) private window: any,
    private router: Router,
    private store: Store,
    private appListener: AppListenerService,
    private searchLocation: SearchLocationService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  observeHomepage(): void {
    this.homepageState$.pipe(filter(Boolean)).subscribe(() => {
      this.searchLocation.updateSearchLocation(undefined);
      this.store.dispatch(new SearchAction.ResetSearchState([ 'locationData' ]));
    });
  }

  observeMapViewState(): void {

    const expUrl = `/${currentLang()}/experiences`;
    const initialUrl = this.routerUrl.includes(expUrl) ? this.routerUrl : expUrl;
    let previousState = this.appListener.mapExpansionState$$.value;

    this.router.events.pipe(
      filter((event: Event): event is NavigationStart => event instanceof NavigationStart),
      map(e => e.url),
      startWith(initialUrl),
      takeUntil(this.subscription$)
    ).subscribe((url: string) => {
      const state = url.includes(expUrl);
      if (!previousState && !state) return;
      previousState = !state;
      this.appListener.mapExpansionState$$.next(!state);
    });
  }

  get loadMoreDisplayVisibility$(): Observable<boolean> {
    return this.store.select(SearchState.pagination).pipe(
      switchMap((pagination: SearchResultPagination | null) => {
        return this.loadMoreVisibility$.pipe(
          map((visibility: boolean) => !!pagination?.moreResults && visibility),
          delay(0)
        )
      })
    );
  }

  get homepageState$(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event: Event): event is ActivationEnd => event instanceof ActivationEnd),
      map((event: any) => event['_routerState']?.url === `/${currentLang()}`),
      startWith(this.routerUrl === `/${currentLang()}`),
      takeUntil(this.subscription$)
    );
  }

  get searchPageState$(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event: Event): event is ActivationEnd => event instanceof ActivationEnd),
      map((event: any) => event['_routerState']?.url === `/${currentLang()}/search`),
      startWith(this.routerUrl === `/${currentLang()}/search`)
    );
  }

  get searchPageVisibility$(): Observable<boolean> {
    return this.searchPage$.pipe(
      switchMap((state: boolean) => this.store.select(SearchState.locationData).pipe(
        map((locationData: LocationData | null) => state && !!locationData)
      ))
    );
  }

  get searchPage$(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url.split('/')[2] === 'search'),
      startWith(this.routerUrl.split('/')[2] === 'search')
    );
  }

  get pageOnly$(): Observable<boolean> {
    const pathname = this.window.location.pathname;
    const pageOnly = pageOnly_allowedRoutes.some(e => pathname.split('/')[2]?.includes(e))
    || pathname === `/${currentLang()}`
    return of(pathname).pipe(
      switchMap((pathname: string) => this.router.events.pipe(
        filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
        map(() => 
          pageOnly_allowedRoutes.some(e => pathname.split('/')[2]?.includes(e)) 
          || pathname === `/${currentLang()}`
        )
      )),
      startWith(pageOnly)
    );
  }

  get mapVisibility$(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => !mapVisibility_notAllowedRoutes.some(e => event.url.split('/')[2]?.includes(e))),
      startWith(!mapVisibility_notAllowedRoutes.some(e => this.routerUrl.split('/')[2]?.includes(e)))
    );
  }

  get loadMoreVisibility$(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => loadMoreVisibility_allowedRoutes.some(e => event.url.split('/')[2]?.includes(e))),
      startWith(loadMoreVisibility_allowedRoutes.some(e => this.routerUrl.split('/')[2]?.includes(e)))
    );
  }

  private get routerUrl(): string {
    return this.router.url.split('?')[0].split('#')[0];
  }

}
