import { Component, OnDestroy, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';

import { CarouselHighlight, EFAStateServiceService, ScreenView } from '../../shared';
import { StepperService } from './stepper.service';
import { textFadeTrigger } from './stepper.animation';

@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss', './stepper-responsiveness.scss'],
  host: { class: 'experience-finder-ai--host-stepper' },
  viewProviders: [ StepperService ],
  animations: [ textFadeTrigger ],
  encapsulation: ViewEncapsulation.None,
})
export class StepperComponent implements AfterViewInit, OnDestroy {

  subscription$ = new Subject<void>();
  highlight: CarouselHighlight | null;

  constructor(
    public service: StepperService,
    public stateService: EFAStateServiceService
  ) { }

  ngAfterViewInit(): void {
    this.stepperVisibilityObserver();
    this.service.refreshStepperVisibility();

    this.stateService.carouselHighlight$.pipe(takeUntil(this.subscription$)).subscribe((highlight: CarouselHighlight) => {
      if (this.stateService.isDesktop) return;
      this.highlight = highlight;
    });
    this.stateService.screenViewValue$.pipe(takeUntil(this.subscription$)).subscribe((screen: ScreenView) => {
      if (screen === ScreenView.carouselGrid) return;
      this.highlight = null;
    });
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  stepperVisibilityObserver(): void {
    this.stateService.screenViewValue$.pipe(
      tap(screen => this.service.refreshStepperVisibility(screen)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  navigateToDefaultView(): void {
    this.stateService.myTripsViewState$$.next(false);
  }

  navigate(screen: ScreenView): void {
    const value = this.stateService.view[screen];
    this.stateService.scrollToScreen$$.next(value);
  }

}
