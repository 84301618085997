import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'skyscanner-widget-dialog',
  template: `
    <dialog-close-button></dialog-close-button>
    <skyscanner-view></skyscanner-view>
  `,
  styleUrls: ['./skyscanner-widget-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'skyscanner-widget-dialog' }
})
export class SkyscannerWidgetDialogComponent { }
