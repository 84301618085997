import { BasicInfoData, GetBlogResponse, GetItineraryResponse, ItineraryApiService } from "@hiptraveler/data-access/api";
import { Store } from "@ngxs/store";
import { catchError, EMPTY, filter, map, Observable, tap } from "rxjs";
import { get, set, upperFirst } from "lodash";

import { ItineraryState } from "@hiptraveler/data-access/itinerary";
import { currentLang, getWindowRef, limitString } from "@hiptraveler/common";
import * as Feature from '@hiptraveler/features/meta-tag';

const defaultKeywords = Feature.defaultMetaTagData.keywords?.split(', ') || [];

export function handlePageMetadata(store: Store, callback: (metadata: Feature.Metadata) => void): void {

  const pathname = getWindowRef()?.location?.pathname.toLowerCase();
  const langPath = `/${currentLang()}/`;

  if (pathname?.includes(`${langPath}itinerary/`) || pathname?.includes(`${langPath}travel-story/`)) {
    fetchMetadata(store.select(ItineraryState.basicInfo), callback);
  }
}

export function handlePageMetadata_server(api: ItineraryApiService, callback: (metadata: Feature.Metadata) => void): void {

  const pathname = getWindowRef()?.location?.pathname?.toLowerCase();

  pathname?.includes(`/${currentLang()}/itinerary/`)
    && fetchMetadata_server(api.getItineraryByPageTitle(Feature.getPageTitle()), v => callback(v));

  pathname?.includes(`/${currentLang()}/travel-story/`)
    && fetchMetadata_server(api.getBlogByPageTitle(Feature.getPageTitle()), v => callback(v));
}

function createMetaTagByData(data: Partial<BasicInfoData>): Feature.Metadata {

  data['featureTags'] = data?.featureTags ? data.featureTags.concat(defaultKeywords) : defaultKeywords;
        
  let keywords = '';
  if (data?.featureTags?.length) {
    keywords = Feature.joinWithAnd(data.featureTags)
  }

  if (!get(data, 'title')) {
    set(data, 'title', `${upperFirst(get(data, 'type') || 'Itinerary')} Page`);
  }

  let description = data?.snippet ? limitString(data.snippet, 200)
    : data?.autoSummary ? limitString(data.autoSummary, 200)
    : `${data.title} is a travel story on HipTraveler`;

  description = data?.featureTags?.length ? `${description}. that covers ${keywords} type of experiences` : description;

  description = data?.numDays! > 0 ? `${description} with an embedded ${data.numDays} day travel plan that includes things to do, place to stay and hip restaurants.` : description;

  return {
    ...Feature.defaultMetaTagData,
    title: data.title || '',
    url: data.pageUrl || '',
    imageUrl: data.coverImage?.imgUrl || Feature.defaultMetaTagData.imageUrl,
    keywords, description,
    callback: {
      canonicalUrl: data.canonicalUrl
    }
  };
}

function fetchMetadata_server(observable: Observable<GetBlogResponse | GetItineraryResponse>, callback: (metadata: Feature.Metadata) => void): void {
  processObservable(
    observable.pipe(map(e => e.basicInfo)),
    v => callback(v)
  );
}

function fetchMetadata(observable: Observable<Partial<BasicInfoData> | null>, callback: (metadata: Feature.Metadata) => void): void {
  processObservable(
    observable.pipe(filter(Boolean)),
    v => callback(v)
  );
}

function processObservable(observable: Observable<Partial<BasicInfoData>>, callback: (metadata: Feature.Metadata) => void): void {
  observable.pipe(
    tap((data: Partial<BasicInfoData>) => callback(createMetaTagByData(data))),
    catchError(() => {
      callback(Feature.defaultMetaTagData);
      return EMPTY;
    })
  ).subscribe();
}