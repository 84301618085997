import { Inject, Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Event, NavigationEnd, Router } from '@angular/router';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, delay, filter, map, merge, of, startWith, switchMap, takeUntil, tap } from 'rxjs';

import { BrandCampaign, Experience } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { SearchLocationData, SearchLocationService, WINDOW, currentLang } from '@hiptraveler/common';
import { DataAccessService, FiltersStateService } from './common';

@Injectable()
export class FiltersService implements OnDestroy {

  @Select(BrandState.experiences) experiences$: Observable<Partial<Experience>[]>;
  @Select(BrandState.brandCampaign) brandCampaign$: Observable<Partial<BrandCampaign> | null>;

  form: FormGroup = this.fb.group({
    'location': [ '' ],
    'query': [ '' ],
    'checkInDate': [ '' ],
    'checkOutDate': [ '' ],
  });

  filters$$ = new BehaviorSubject<boolean>(false);
  overlayState$$ = new BehaviorSubject<boolean>(false);
  overlayState$ = this.overlayState$$.asObservable().pipe(delay(0));
  subscription$ = new Subject<void>();

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(WINDOW) private window: any,
    private router: Router,
    private store: Store,
    private dataAccess: DataAccessService,
    private searchLocation: SearchLocationService,
    private filtersService: FiltersStateService,
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  get path(): string {
    return (this.window.location.pathname?.replace(`/${currentLang()}`, '') || '/')?.replace('/', '')?.split('/')[0] || 'search';
  }

  get searchPage$(): Observable<string> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map(({ url }) => {
        const adjustedUrl = !url.includes(`/${currentLang()}/`) ? `/${currentLang()}/search` : url;
        return adjustedUrl.replace(`/${currentLang()}/`, '').split('?')[0].split('/')[0];
      }),
      startWith(this.filtersService.searchPage)
    );
  }

  filterVisibilityObserver(): Observable<boolean> {
    const formValueObserver = (key: string): Observable<boolean> => {
      const value = this.form.value[key];
      return of(value).pipe(
        switchMap(() => {
          const form = this.form.get(key);
          return !form ? of(!!value) : form.valueChanges.pipe(map(e => !!e));
        }),
        startWith(value)
      );
    };
    return merge(
      this.filtersService.selectedItemKeys$.pipe(map(e => !!e.length)),
      this.filtersService.selectedTravelStyleKeys$.pipe(map(e => !!e.length)),
      formValueObserver('checkInDate'),
      formValueObserver('query'),
    ).pipe(
      delay(0),
      map(e => !!e),
      tap(e => this.filters$$.next(e)),
    );
  }

  listPanelApplyActionListener(): void {
    this.filtersService.panelFilterApplyClicked$$.pipe(
      map(() => this.form.value),
      tap(form => this.path === 'experiences' && this.dataAccess.itineraries()),
      tap(form => this.path === 'search' && this.dataAccess.forYouPage(form)),
    ).subscribe();
  }

  initializeFormState(): void {

    this.form.patchValue({
      location: this.searchLocation.data?.location || ''
    });

    this.searchLocation.searchLocation$.pipe(
      takeUntil(this.subscription$)
    ).subscribe((data: SearchLocationData) => this.form.patchValue({
      location: data?.location || ''
    }));

    this.searchLocation.searchLocation$$.pipe(
      filter(e => !e),
      takeUntil(this.subscription$)
    ).subscribe(() => this.form.patchValue({
      location: ''
    }));
  }

  openCalendar(picker: MatDateRangePicker<any>): void {
    if (this.filtersService.travelDatesCloseActionState$$.value) return;
    picker.open();
    this.showOverlay();
  }
  
  showOverlay(): void {
    this.overlayState$$.next(true);
  }

  hideOverlay(): void {
    this.overlayState$$.next(false);
  }

  clearCalendarField(): void {
    this.form.patchValue({ checkInDate: '', checkOutDate: '' });
  }

  get filterTitle$(): Observable<string> {
    return this.store.select(BrandState.brandCampaign).pipe(
      map((brandCampaign) => {
        return brandCampaign?.level === 'Island' ? 'search-page.island' : 'search-page.location';
      })
    );
  }

  get filterInputPlaceholder$(): Observable<string> {
    return this.store.select(BrandState.brandCampaign).pipe(
      map((brandCampaign) => {
        return brandCampaign?.cSubDomain?.startsWith('bahamas') ? 'search-page.select-island' : 'search-page.location';
      })
    );
  }

}
