import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, filter, firstValueFrom, map, startWith, tap } from 'rxjs';
import { concat } from 'lodash';

import { Experience } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { currentLang, SearchLocationService } from '@hiptraveler/common';
import { ListItem, amenities, cuisine } from '../list-items';
import * as ViewConstants from './view-constants.model';

@Injectable()
export class ViewService {

  private listItems$$ = new BehaviorSubject<ViewConstants.ListItems | null>(null);
  listItems$ = this.listItems$$.asObservable().pipe(filter(Boolean));

  constructor(
    private store: Store,
    private router: Router,
    private searchLocation: SearchLocationService
  ) { }

  get listItemsByPath(): ViewConstants.ListItems {
    const brandExperiences = this.store.selectSnapshot(BrandState.experiences) || [];
    const activities = brandExperiences.map((experience: Partial<Experience>) => ({
      type: experience?.category === 'activity' ? 'activities' 
        : experience?.category === 'experience' ? 'exp' : 'travelStyle',
      name: experience?.name || '',
      value: experience?.code || ''
    })) as ListItem[];
    switch (this.searchLocation.searchRoutePath) {
      case 'foodanddrink': return {
        activities: activities.concat(cuisine), data: cuisine,
        display: cuisine.slice(0, 5),
        all: concat(activities, cuisine, amenities)
      };
      case 'hotels': return {
        activities: activities.concat(amenities), data: amenities,
        display: amenities.slice(0, 5),
        all: concat(activities, cuisine, amenities)
      };
      case 'thingstodo': return {
        activities, data: activities,
        display: activities.filter(e => e.type === 'activities').slice(0, 5),
        all: concat(activities, cuisine, amenities)
      };
      default: return {
        activities, data: activities,
        display: activities.filter(e => e.type === 'activities').slice(0, 5),
        all: concat(activities, cuisine, amenities)
      };
    }
  }

  async observeListItems(): Promise<void> {

    await firstValueFrom(this.store.select(BrandState.experiences).pipe(filter(Boolean)));

    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map(() => this.listItemsByPath),
      startWith(this.listItemsByPath),
      filter(() => this.searchLocation.searchPageRoute),
      tap(() => this.listItems$$.next(this.listItemsByPath))
    ).subscribe();
  }

  get listItems(): Promise<ViewConstants.ListItems> {
    return firstValueFrom(this.listItems$);
  }

  get searchVisibility$(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => ViewConstants.search_allowedRoutes.some(e => event?.url?.split('/')?.[2]?.includes(e))),
      startWith(ViewConstants.search_allowedRoutes.some(e => this.router.url?.split('/')?.[2]?.includes(e)))
    );
  }

  get nameVisibility$(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => ViewConstants.name_allowedRoutes.some(e => event?.url?.split('/')?.[2]?.includes(e))),
      startWith(ViewConstants.name_allowedRoutes.some(e => this.router.url?.split('/')?.[2]?.includes(e)))
    );
  }

  get communityVisibility$(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => ViewConstants.community_allowedRoutes.some(e => event.url.split('/')[2]?.includes(e))),
      startWith(ViewConstants.community_allowedRoutes.some(e => this.router.url?.split('/')?.[2]?.includes(e)))
    );
  }

  get filterActionsVisibility$(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => ViewConstants.filterActions_allowedRoutes.some(e => event.url.split('/')[2]?.includes(e))),
      startWith(ViewConstants.filterActions_allowedRoutes.some(e => this.router.url?.split('/')?.[2]?.includes(e)))
    );
  }

  get travelStyleVisibility$(): Observable<boolean> {
    const route = this.router.url?.split('?')?.[0]?.split('#')?.[0];
    const value = ViewConstants.travelStyle_allowedRoutes.some(e => this.router.url?.split('/')?.[2]?.includes(e))
    const initialValue = route === `/${currentLang()}` || value;
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => ViewConstants.travelStyle_allowedRoutes.some(e => event.url?.split('/')?.[2]?.includes(e))),
      startWith(initialValue)
    );
  }

  get checkboxLabel$(): Observable<ViewConstants.CheckboxLabel> {
    const labelByPath = (route: string): ViewConstants.CheckboxLabel => {
      const path = route?.split('/')?.[2]?.split('?')?.[0]?.split('#')?.[0];
      switch (path) {
        case 'foodanddrink': return 'Cuisine';
        case 'thingstodo':   return 'Activities';
        case 'hotels':       return 'Amenities';
        default:             return 'List';
      }
    };
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => labelByPath(event.url)),
      startWith(labelByPath(this.router.url))
    );
  }

}
