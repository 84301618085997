import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Event as Evt, NavigationEnd as NEnd, Router } from '@angular/router';
import { filter, merge, Subject, takeUntil } from 'rxjs';

import { opacityFadeIn } from '@hiptraveler/animations';
import { AppListenerService, SearchLocationService } from '@hiptraveler/common';

@Component({
  selector: 'filters-overlay',
  template: `
    <p [@opacityFadeIn]>Search filter</p>
    <mat-icon svgIcon="search-page-root.filter-icon" [@opacityFadeIn]></mat-icon>
  `,
  styleUrls: ['./filters-overlay.component.scss'],
  host: { class: 'search-page-root-filters-overlay' },
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFadeIn(500) ]
})
export class FiltersOverlayComponent implements OnDestroy, AfterViewInit {

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private router: Router,
    private appListener: AppListenerService,
    private searchLocation: SearchLocationService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  ngAfterViewInit(): void {
    this.animateMapCollapseOnExpansionClose();
    this.updateComponentWidthForSearchRoutes();
  }

  @HostListener('click')
  async hostClick(): Promise<void> {
    const state = !this.appListener.mapExpansionState$$.value;
    this.appListener.mapExpansionState$$.next(state)
  }

  /**
   * Animates the collapse of the map view when it is no longer expanded.
   *
   * This function subscribes to the mapExpansionState$ observable and listens for changes.
   * When the mapExpansionState becomes false, it applies a CSS transform to scale the element
   * down to 0, providing a smooth transition effect for collapsing the map.
   *
   * The subscription is automatically managed by takeUntil, ensuring it is properly cleaned up.
   */
  private animateMapCollapseOnExpansionClose(): void {
    this.appListener.mapExpansionState$.pipe(
      takeUntil(this.subscription$)
    ).subscribe((state: boolean) => {
      if (state) return
      this.element.nativeElement.style.transform = 'scale(0)';
    });
  }

  /**
   * Subscribes to router navigation events and map expansion state changes to adjust the element's width.
   *
   * When either a NavigationEnd event occurs or the mapExpansionState changes, this function checks if either
   * `rootSearchRoute` or `rootSearchPage` is truthy. If so, it sets the element's width to 348px; otherwise, it sets
   * it to 156px. This ensures the UI adapts responsively based on navigation and map state.
   *
   * The subscription is automatically unsubscribed using takeUntil.
   */
  private updateComponentWidthForSearchRoutes(): void {
    merge(
      this.router.events.pipe( filter((event: Evt): event is NEnd => event instanceof NEnd) ),
      this.appListener.mapExpansionState$
    ).pipe( takeUntil(this.subscription$) ).subscribe(() => {
      const state = this.searchLocation.rootSearchRoute || this.searchLocation.rootSearchPage;
      this.element.nativeElement.style.width = state ? '334px' : '156px';
    });
  }

}
