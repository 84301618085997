import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { CalendarComponent } from './calendar.component';
import { MatCalendarStylesDirective } from './mat-calendar-styles.directive';

const MODULES = [
  CommonModule,
  FormsModule,
  MatDatepickerModule,
  MatMomentDateModule,
  MatNativeDateModule,
  MatButtonModule,
  MatIconModule
];

const COMPONENTS = [
  CalendarComponent
];

const DIRECTIVES = [
  MatCalendarStylesDirective
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    CalendarComponent
  ]
})
export class CalendarModule { }
