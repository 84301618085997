import { generateUUID, getWindowRef } from "@hiptraveler/common";

/** Key used to store the visitor ID in local storage. */
const VISITOR_ID_STORAGE_KEY = "_ht_vId";

/**
 * Retrieves the existing visitor ID from local storage or generates a new one if it doesn't exist.
 *
 * @returns The visitor ID string, or `null` if local storage is unavailable.
 */
export function clientVID(): string | null {
  return getStoredVisitorId() || createAndStoreVisitorId();
}

/**
 * Generates a new UUID, stores it in local storage as the visitor ID, and returns it.
 *
 * @returns The newly generated visitor ID.
 */
function createAndStoreVisitorId(): string {
  const newVisitorId = generateUUID();
  setVisitorId(newVisitorId);
  return newVisitorId;
}

/**
 * Retrieves the visitor ID from local storage.
 *
 * @returns The stored visitor ID, or `null` if it's not found or local storage is unavailable.
 */
function getStoredVisitorId(): string | null {
  return getWindowRef()?.localStorage?.getItem(VISITOR_ID_STORAGE_KEY) || null;
}

/**
 * Stores or updates the visitor ID in local storage.
 *
 * @param visitorId - The visitor ID to be stored.
 */
export function setVisitorId(visitorId?: string): void {
  getWindowRef()?.localStorage?.setItem(VISITOR_ID_STORAGE_KEY, visitorId ?? '');
}

/**
 * Removes the visitor ID from local storage.
 */
export function removeVisitorId(): void {
  getWindowRef()?.localStorage?.removeItem(VISITOR_ID_STORAGE_KEY);
}
