import { Component, Input } from '@angular/core';

import { ActivityDateDayTimeMedium } from '@hiptraveler/data-access/api';

@Component({
  selector: 'drive-duration',
  templateUrl: './drive-duration.component.html',
  styleUrls: ['./drive-duration.component.scss']
})
export class DriveDurationComponent {

  @Input() medium?: ActivityDateDayTimeMedium;
  @Input() hostIndex?: number;
  @Input() index?: number;

}
