import { Component, Inject } from '@angular/core';

import { WINDOW } from '@hiptraveler/common';

@Component({
  selector: 'powered-by-hiptraveler',
  template: `
    <div class="powered-by">
      <p><em>{{ 'powered-by.handle-text' | translate }}</em></p>
      <img [src]="hiptravelerLogoImage" alt="hiptraveler-logo" width="80" height="auto" (click)="goToHipTravelerWebsite()">
    </div>
  `,
  styles: [`
    .powered-by {
      display: flex;
    }
    .powered-by p {
      display: inline-block;
      font-size: 10px;
      font-weight: 700;
      color: var(--ht-text-color);
      margin: 0 4px 0 16px;
    }
    .powered-by img {
      cursor: pointer;
    }
  `],
  host: { class: 'powered-by-hiptraveler' }
})
export class PoweredByHiptravelerComponent {

  hiptravelerLogoImage: string = 'https://d36j46pvk6qi4f.cloudfront.net/site/main/images/hiptraveler-logo-full-default.webp';

  constructor(
    @Inject(WINDOW) private window: any
  ) { }

  goToHipTravelerWebsite(): void {
    this.window.open('https://www.hiptraveler.com/', '_blank');
  }

}
