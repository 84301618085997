import { PromiseReject, PromiseResolve } from "@hiptraveler/common";

declare const showdown: any;

export function showdownClient(): Promise<any> {
  return new Promise((resolve: PromiseResolve, reject: PromiseReject) => {

    let
      bodyElement: HTMLBodyElement | null = null,
      scriptElement: HTMLScriptElement | null = null;
  
    if (typeof window === 'object') {
      bodyElement = window.document.body as HTMLBodyElement;
      scriptElement = window.document.createElement('script') as HTMLScriptElement;
    }
  
    if (!bodyElement || !scriptElement) return;

    scriptElement.src = 'https://cdn.jsdelivr.net/npm/showdown@2.1.0/dist/showdown.min.js';
    scriptElement.async = true;

    bodyElement.appendChild(scriptElement);

    scriptElement.onload = () => {
      try {

        resolve(showdown);
        console.log('** 3rd party CDN: ShowdownJS script loaded successfully.');
      } catch (innerError) {

        reject(innerError);
        console.error('Error initializing ShowdownJS:', innerError);
      }
    };
  });
}
