import * as AuthApi from './interfaces';
import { getWindowRef } from '@hiptraveler/common';

interface AuthEncrypted {
  lr: string;
  iv: string;
}

const LOCAL_ENCRYPTION_KEY = "uSSyv124dfnzsixAUSaGdEwRxtounX8d"; //CryptoJS.lib.WordArray.random(32).toString();

export function encryptSignInPayload(payload: AuthApi.SignInDto): AuthEncrypted {

  const CryptoJS = getWindowRef()?.CryptoJS;

  if (!CryptoJS) {
    throw 'CryptoJS client not found.'
  }

  const ivHex = CryptoJS.lib.WordArray.random(16);
  const keyUtf8 = CryptoJS.enc.Utf8.parse(LOCAL_ENCRYPTION_KEY);
  const iv = ivHex.toString();
  const lr = CryptoJS.AES.encrypt(JSON.stringify(payload), keyUtf8, {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iv: ivHex,
  }).toString();

  return { lr, iv };
}
