import { animate, style, transition, trigger } from "@angular/animations";

export const ANIMATION_OFFSET: number = 15;

export const textFadeTrigger = trigger('textFadeTrigger', [
  transition('* => *', [
    style({ opacity: 0, transform: `translate(calc(-50% + {{ offset }}px), 10%)` }),
    animate('300ms ease-out', style({ opacity: 1, transform: 'translate(-50%, 10%)' })),
  ])
]);
