import { Pipe, PipeTransform } from '@angular/core';

import { ActivityDateDayTimeMedium } from '@hiptraveler/data-access/api';

@Pipe({
  name: 'travelMedium'
})
export class TravelMediumPipe implements PipeTransform {

  transform(value: string): string {

    const defaultMediums = [  'fly', 'flight', 'flight-and-taxi' ] as ActivityDateDayTimeMedium[];

    if (defaultMediums.includes(value as any)) {
      return 'flight';
    } else {
      return value;
    }
  }

}
