import { Component } from '@angular/core';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { FeatureCard } from './feature-cards';

@Component({
  selector: 'consume-card',
  template: `

    <ng-container *ngIf="index > 0 || !!activity.HotelArray">
      <ng-container *ngIf="resultContent.id | duration: activity.dayTimeActivity: 'commute' | async as duration">
        <drive-duration [medium]="duration?.icon" [index]="index" *ngIf="duration.display">{{ duration.display }}</drive-duration>
      </ng-container>
    </ng-container>
    
    <itinerary-feature-card featureCard type="consume" (positionMoved)="positionMoved.emit({ id: resultContent.id, day: activity.day, direction: $event })" (mouseenter)="onFeatureCardHover(resultContent)">

      <itinerary-activity-duration [html]="(resultContent.id | duration: activity.dayTimeActivity: 'activity' | async)?.display"  *ngIf="activityDurationState$ | async"></itinerary-activity-duration>
  
      <img itineraryImage [lazyLoad]="resultContent?.thumbnail || ''" defaultImage="assets/img/chill-time-fallback-image.webp" errorImage="assets/img/chill-time-fallback-image.webp" [alt]="''">
  
      <itinerary-title>{{ resultContent?.name || ('itinerary.activity' | translate) }}</itinerary-title>
  
      <itinerary-subtitle>{{ resultContent.location }}</itinerary-subtitle>
  
      <img itineraryReviewImage [lazyLoad]="resultContent.yelpData?.ratingImgUrl || 'https://s3.amazonaws.com/UA-Environment/brand-logos/yelp-stars/large_0.png'" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" width="63" alt="YELP dataset">
  
      <itinerary-reviews>{{ resultContent.yelpData?.rating || 0 }}</itinerary-reviews>

      <itinerary-action-button [active]="false" actionPanel *ngIf="!(itineraryOwner$ | async)">
        <itinerary-action actionPanel [activityId]="resultContent.id"></itinerary-action>
      </itinerary-action-button>

      <itinerary-button (click)="primaryClick('image')" *ngIf="itineraryOwner$ | async">{{ resultContent | imageDataButtonName | async }}</itinerary-button>
  
      <itinerary-button [pending]="pendingSecondary" [appearance]="view ? '' : 'warn'" (click)="secondaryClick()">{{ (view ? 'itinerary.button-text-read-more' : 'itinerary.button-text-remove') | translate }}</itinerary-button>
  
    </itinerary-feature-card>
  `,
  host: { class: 'feature-card' }
})
export class ConsumeCardComponent extends FeatureCard<ImageResultData> { }
