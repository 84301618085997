import { ElementRef, NgModule, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';

import { ActivityDateData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';

@Pipe({
  name: 'toItineraryDate'
})
export class ToItineraryDatePipe implements PipeTransform {

  constructor(
    private element: ElementRef<HTMLElement>,
    private store: Store
  ) { }
  
  /**
   * Transforms the activity data to format the date.
   * 
   * @param {ActivityDateData} activity The activity data to format.
   * @returns {string} The formatted date string.
   */
  transform(activity: ActivityDateData, option?: 'label'): string {

    if (!activity.hasMultiDayTour) {
      const dayStr = activity?.date?.day?.toString() || activity?.day?.toString();
      return `${activity.dateString ? '' : option === 'label' ? 'Day' : ''} ${dayStr}`.trim();
    }
    
    const actMap = this.store.selectSnapshot(ItineraryState.actDateMap)!;
    const tours = Object.values(activity.multiDayTourList || {})[0];
    
    let fromStr = '', toStr = '', finalToMonth = '', fromMonth = '';
    
    if (activity.dateString) {
      fromMonth = actMap[tours[0]].date?.month || '';
      const toMonth = actMap[tours[1]].date?.month || '';
      finalToMonth = fromMonth === toMonth ? '' : toMonth;
      fromStr = `${fromMonth} ${actMap[tours[0]].date?.day}`;
      toStr = `${finalToMonth} ${actMap[tours[1]].date?.day}`.trim();
    } else {
      fromStr = actMap[tours[0]]?.day?.toString() || '';
      toStr = actMap[tours[1]]?.day?.toString() || '';
    }
    
    if (!fromStr || !toStr) return '';
    
    const prefix = activity.dateString ? '' : 'Day';
    
    if (option === 'label') {
      return fromStr === toStr
        ? `${prefix} ${fromStr}`.trim()
        : `${prefix} ${fromStr} - ${toStr}`.trim();
    }
    
    if (finalToMonth) {
      (this.element.nativeElement.parentElement?.firstElementChild as HTMLElement).style.display = 'none';
      return fromStr === toStr
        ? `${fromStr}`.trim()
        : `${fromStr}<br>-<br>${toStr}`.trim();
    } else {
      const dateLabel = this.element.nativeElement.parentElement?.lastElementChild as HTMLElement;
      dateLabel.style.fontSize = '18px';
      dateLabel.style.lineHeight = '26px';
      fromStr = fromStr.replace(`${fromMonth} `, '');
      if (fromStr === toStr) {
        dateLabel.style.fontSize = '26px';
        return `${fromStr}`.trim();
      }
      return `${fromStr}-${toStr}`.trim();
    }
  }

}

@NgModule({
  declarations: [ ToItineraryDatePipe ],
  exports:      [ ToItineraryDatePipe ]
})
export class ToItineraryDateModule { }
