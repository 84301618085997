import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';

import { ActivityDateData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { TravelMediumService } from './travel-medium.service';
import { NavigationDialogActionService } from './navigation-dialog';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'travel-medium',
  template: `
    <div class="drive-duration--icon" [class.flight-and-ferry]="travelMedium === 'flight-and-ferry'">

      <!-- To do: Fix this weird work around... see also travel-medium.component.ts , shorten the entire code blocks -->
      <ng-container *ngIf="travelMedium === 'flight-and-ferry';else defaultIconView">
        <!-- To do: Fix this weird work around... see also travel-medium.component.ts , shorten the entire code blocks -->
        <mat-icon svgIcon="itinerary.flight-and-ferry" [@opacityFadeIn]></mat-icon>
      </ng-container>
      <ng-template #defaultIconView>
        <mat-icon [class]="travelMedium" *ngIf="travelMedium" [svgIcon]="'itinerary.' + travelMedium | travelMedium" [@opacityFadeIn]></mat-icon>
      </ng-template>

    </div>

    <ng-container *ngIf="description">
      <p (click)="openSkyscanner()" [@opacityFadeIn]>{{ description }}</p>
    </ng-container>
  `,
  styleUrls: ['./travel-medium.component.scss'],
  host: { class: 'travel-medium' },
  animations: [ opacityFadeIn() ],
  providers: [ TravelMediumService, NavigationDialogActionService ],
  encapsulation: ViewEncapsulation.None
})
export class TravelMediumComponent implements OnInit {

  @Input() activity: ActivityDateData;

  travelMedium: string;
  description: string;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private store: Store,
    private ipInfo: TravelMediumService,
    private dialog: NavigationDialogActionService
  ) { }

  ngOnInit(): void {

    const transit = this.activity.dayLocMap?.transit;

    if (!transit && this.activity.day === 1) {
      this.travelMedium = 'flight';
      this.description = this.ipInfo.transitLabelByDay;
      return;
    }

    if (!transit) {
      this.elementRef.nativeElement.style.display = 'none';
      return;
    }

    const { origin, destination, name, durationString, mode } = transit;
    this.travelMedium = mode.toLowerCase().replace(/ /g, '-');

    if (!name && !origin && !destination) {
      this.elementRef.nativeElement.style.display = 'none';
      return;
    }

    this.description = (name || `From ${origin}, to ${destination}`) + (durationString ? ` - ${durationString}.` : '');
  }

  openSkyscanner(): void {
    this.dialog.open({
      travelMedium: this.travelMedium,
      itineraryId: this.store.selectSnapshot(ItineraryState.basicInfo)?.id || '',
      day: this.activity.day
    });
  }

}
