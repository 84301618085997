import { ElementRef, Injectable } from '@angular/core';
import { combineLatest, map, Observable, pairwise, startWith } from 'rxjs';

import { EFAStateServiceService, ScreenView } from '../../shared';
import { navigationFlow, mobileNavigationFlow } from './stepper';
import { ANIMATION_OFFSET } from './stepper.animation';

@Injectable()
export class StepperService {

  constructor(
    private element: ElementRef<HTMLElement>,
    private stateService: EFAStateServiceService
  ) { }

  animationOffset: number = ANIMATION_OFFSET;

  screenViewValue$ = this.stateService.screenViewValue$$.asObservable().pipe(
    map(view => view || ScreenView.mainSelection)
  );

  direction$: Observable<string> = this.screenViewValue$.pipe(
    pairwise(),
    map(([ prev, curr ]) => {
      const order = Object.keys(navigationFlow);
      const prevIndex = order.indexOf(prev as ScreenView);
      const currIndex = order.indexOf(curr as ScreenView);
      return currIndex > prevIndex ? 'forward' : 'backward';
    }),
    startWith('forward')
  );

  navigationTextWithDirection$ = combineLatest([
    this.screenViewValue$,
    this.direction$
  ]).pipe(
    map(([view, direction]) => ({
      ...(this.stateService.isDesktop ? navigationFlow : mobileNavigationFlow)[view || ScreenView.mainSelection],
      direction
    }))
  );

  refreshStepperVisibility(screen: ScreenView = this.stateService.screenViewValue): void {
    const stepper = this.element?.nativeElement;
    const shouldHide = this.stateService?.isDesktop
      ? [ ScreenView.calendar, ScreenView.clientInfo ].includes(screen)
      : [ ScreenView.brandMainSelection ].includes(screen);
    stepper && stepper.classList.toggle('stepper-hidden', shouldHide);
  }
  
}
