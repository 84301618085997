import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ExperienceFinderComponent } from './experience-finder-ai.component';
import { BubbleComponent } from './experience-finder-bubble/experience-finder-bubble.component';
import { ExperienceFinderHoverDirective } from './experience-finder-hover.directive';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatDialogModule,
  MatProgressSpinnerModule
];

const COMPONENTS = [
  ExperienceFinderComponent,
  BubbleComponent
];

const DIRECTIVES = [
  ExperienceFinderHoverDirective
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    ExperienceFinderComponent
  ]
})
export class ExperienceFinderAIModule { }
