import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject, filter, firstValueFrom, map } from 'rxjs';

import { CSRF_KEY } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState, UserStateModel } from '@hiptraveler/data-access/user';
import { ProfileAction, ProfileState } from '@hiptraveler/data-access/profile';
import { SnackbarService } from '@hiptraveler/snackbar';
import { RegistrationFormStateService, Type } from './common';
import { TranslationService, WINDOW, currentLang, getWindowRef } from '@hiptraveler/common';
import { parseSaveAmbassadorPayload } from './registration-form.helper';

@Injectable()
export class RegistrationFormService implements OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    @Inject(WINDOW) private window: any,
    private store: Store,
    private stateService: RegistrationFormStateService,
    private snackbar: SnackbarService,
    private translate: TranslationService
  ) { }

  get taSaved$(): Observable<boolean> {
    return this.stateService.taSaved$$.asObservable();
  }

  get submitProcessing$(): Observable<boolean> {
    return this.stateService.submitProcessing$$.asObservable();
  }

  get existingTravelAgent$(): Observable<boolean> {
    return this.store.select(UserState.state).pipe(
      map((userState: UserStateModel | null) => {
        if (this.window.location.pathname === `/${currentLang()}/ambassador`) return false;
        return !!userState?.taId;
      }),
      // map(() => false)
    );
  }

  get lang(): string {
    return currentLang();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  observeAuthChanges(): void {
    this.store.select(AuthState.authenticated).pipe(filter(e => !e)).subscribe(() => {
      this.stateService.resetForm();
    });
  }

  async registerInterest(type: Type = 'travelAgent'): Promise<void> {

    this.stateService.form.markAsDirty();
    this.stateService.form.markAllAsTouched();
    const value = this.stateService.form.value;

    if (this.stateService.form.invalid) {
      this.snackbar.open({ message: 'Required fields are empty. Please fill in all the necessary details.', duration: 5000 });
      return;
    }
    
    if (value.type === 'htAgent') {
      this.snackbar.open({ message: 'Please select your profession to proceed', duration: 5000 });
      return;
    }
    
    if (value.certType === 'IATA' && !value.certId) {
      this.snackbar.open({ message: 'IATA Id is required.', duration: 5000 });
      return;
    }

    try {

      this.stateService.submitProcessing$$.next(true);
      const payload = this.stateService.form.value;
      const existingApplication = !!this.store.selectSnapshot(ProfileState.ambassador)?.id;

      await firstValueFrom(this.store.dispatch(
        type === 'travelAgent'
          ? new ProfileAction.SaveTravelAgent(payload)
          : new ProfileAction.SaveAmbassador(parseSaveAmbassadorPayload(payload))
      ));
      getWindowRef()?.sessionStorage?.removeItem(CSRF_KEY);
      
      
      if (type === 'travelAgent') {
      } else {
        const value = this.store.selectSnapshot(ProfileState.ambassador);
        this.stateService.socialForm.patchValue({
          tiktok: value?.tkConnect, twitter: value?.twConnect,
          facebook: value?.fbConnect?.handle, instagram: value?.instaConnect?.handle, youtube: value?.ytConnect?.handle
        });
        this.snackbar.open({
          message: existingApplication
            ? this.translate.getText('ambassador', 'application-saved')
            : this.translate.getText('ambassador', 'creator-house-registration-success')
        });
      }
      
    } catch(e) {

      const message = this.translate.getText('views.error.ambassador');
      this.snackbar.open({ message, duration: 5000 });
    } finally {

      this.stateService.submitProcessing$$.next(false);
      type === 'travelAgent' && this.stateService.taSaved$$.next(true);
    }
  }

}
  