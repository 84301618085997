import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';

import { AppListenerService } from '@hiptraveler/common';
import { Subject, filter, takeUntil } from 'rxjs';

@Directive({
  selector: '[overlayVariableWidth]'
})
export class OverlayVariableWidthDirective implements OnDestroy, AfterViewInit {

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private appListener: AppListenerService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  ngAfterViewInit(): void {
    this.appListener.clientWidth$.pipe(
      filter(() => !!this.element.nativeElement?.parentElement?.parentElement),
      takeUntil(this.subscription$)
      ).subscribe(() => {

        let paddingX = 96;

        const root = this.element.nativeElement.parentElement!.parentElement!;
        const innerWidth = root.clientWidth - paddingX;

        this.element.nativeElement.style.width = `${innerWidth}px`;
    });
  }

}
