import { ScreenView } from "../../shared";

export interface NavigationStep {

  prev: string;
  curr: string;
  next: string;

  prevNav: ScreenView;
  currNav: ScreenView;
  nextNav: ScreenView;

  highlight: 'prev' | 'curr' | 'next';

}

export type NavigationFlow = Record<ScreenView, NavigationStep>;

export const navigationFlow: NavigationFlow = {

  [ScreenView.myTrips]: {
    prev: 'Home', curr: 'Experiences', next: 'Style', highlight: 'prev',
    prevNav: ScreenView.mainSelection, currNav: ScreenView.experienceCarousel, nextNav: ScreenView.travelCarousel
  },

  [ScreenView.mainSelection]: {
    prev: 'Home', curr: 'Experiences', next: 'Style', highlight: 'prev',
    prevNav: ScreenView.mainSelection, currNav: ScreenView.experienceCarousel, nextNav: ScreenView.travelCarousel
  },

  [ScreenView.experienceCarousel]: {
    prev: 'Home', curr: 'Experiences', next: 'Style', highlight: 'curr',
    prevNav: ScreenView.mainSelection, currNav: ScreenView.experienceCarousel, nextNav: ScreenView.travelCarousel
  },

  [ScreenView.travelCarousel]: {
    prev: 'Experiences', curr: 'Style', next: 'Activities', highlight: 'curr',
    prevNav: ScreenView.experienceCarousel, currNav: ScreenView.travelCarousel, nextNav: ScreenView.activitiesCarousel
  },

  [ScreenView.activitiesCarousel]: {
    prev: 'Style', curr: 'Activities', next: 'Locations', highlight: 'curr',
    prevNav: ScreenView.travelCarousel, currNav: ScreenView.activitiesCarousel, nextNav: ScreenView.locationCarousel
  },

  [ScreenView.locationCarousel]: {
    prev: 'Activities', curr: 'Locations', next: 'Calender', highlight: 'curr',
    prevNav: ScreenView.activitiesCarousel, currNav: ScreenView.locationCarousel, nextNav: ScreenView.calendar
  },

  [ScreenView.calendar]: {
    prev: 'Locations', curr: 'Calender', next: 'Client info', highlight: 'curr',
    prevNav: ScreenView.locationCarousel, currNav: ScreenView.calendar, nextNav: ScreenView.clientInfo
  },

  [ScreenView.clientInfo]: {
    prev: 'Calendar', curr: 'Client info', next: 'Summary', highlight: 'curr',
    prevNav: ScreenView.calendar, currNav: ScreenView.clientInfo, nextNav: ScreenView.summary
  },

  [ScreenView.summary]: {
    prev: 'Calendar', curr: 'Client info', next: 'Summary', highlight: 'next',
    prevNav: ScreenView.calendar, currNav: ScreenView.clientInfo, nextNav: ScreenView.summary
  },

  [ScreenView.screen9]: {
    prev: 'Calendar', curr: 'Client info', next: 'Summary', highlight: 'next',
    prevNav: ScreenView.calendar, currNav: ScreenView.clientInfo, nextNav: ScreenView.summary
  },

  [ScreenView.screen10]: {
    prev: 'Calendar', curr: 'Client info', next: 'Summary', highlight: 'next',
    prevNav: ScreenView.calendar, currNav: ScreenView.clientInfo, nextNav: ScreenView.summary
  },

  [ScreenView.screen11]: {
    prev: 'Calendar', curr: 'Client info', next: 'Summary', highlight: 'next',
    prevNav: ScreenView.calendar, currNav: ScreenView.clientInfo, nextNav: ScreenView.summary
  },

  [ScreenView.screen12]: {
    prev: 'Calendar', curr: 'Client info', next: 'Summary', highlight: 'next',
    prevNav: ScreenView.calendar, currNav: ScreenView.clientInfo, nextNav: ScreenView.summary
  }

}

export const mobileNavigationFlow: NavigationFlow = {

  [ScreenView.myTrips]: {
    prev: 'Home', curr: 'Experiences', next: 'Locations', highlight: 'prev',
    prevNav: ScreenView.brandMainSelection, currNav: ScreenView.carouselGrid, nextNav: ScreenView.mobileLocationCarousel
  },

  [ScreenView.brandMainSelection]: {
    prev: 'Home', curr: 'Experiences', next: 'Locations', highlight: 'prev',
    prevNav: ScreenView.brandMainSelection, currNav: ScreenView.carouselGrid, nextNav: ScreenView.mobileLocationCarousel
  },

  [ScreenView.carouselGrid]: {
    prev: 'Home', curr: 'Experiences', next: 'Locations', highlight: 'curr',
    prevNav: ScreenView.brandMainSelection, currNav: ScreenView.carouselGrid, nextNav: ScreenView.mobileLocationCarousel
  },

  [ScreenView.mobileLocationCarousel]: {
    prev: 'Experiences', curr: 'Locations', next: 'Calender', highlight: 'curr',
    prevNav: ScreenView.carouselGrid, currNav: ScreenView.mobileLocationCarousel, nextNav: ScreenView.mobileCalender
  },

  [ScreenView.mobileCalender]: {
    prev: 'Locations', curr: 'Calender', next: 'Client info', highlight: 'curr',
    prevNav: ScreenView.mobileLocationCarousel, currNav: ScreenView.mobileCalender, nextNav: ScreenView.mobileClientInfo
  },

  [ScreenView.mobileClientInfo]: {
    prev: 'Calendar', curr: 'Client info', next: 'Summary', highlight: 'curr',
    prevNav: ScreenView.mobileCalender, currNav: ScreenView.mobileClientInfo, nextNav: ScreenView.mobileSummary
  },

  [ScreenView.mobileSummary]: {
    prev: 'Calendar', curr: 'Client info', next: 'Summary', highlight: 'next',
    prevNav: ScreenView.mobileCalender, currNav: ScreenView.mobileClientInfo, nextNav: ScreenView.mobileSummary
  },

  [ScreenView.screen7]: {
    prev: 'Calendar', curr: 'Client info', next: 'Summary', highlight: 'next',
    prevNav: ScreenView.mobileCalender, currNav: ScreenView.mobileClientInfo, nextNav: ScreenView.mobileSummary
  },

  [ScreenView.screen8]: {
    prev: 'Calendar', curr: 'Client info', next: 'Summary', highlight: 'next',
    prevNav: ScreenView.mobileCalender, currNav: ScreenView.mobileClientInfo, nextNav: ScreenView.mobileSummary
  },

  [ScreenView.screen9]: {
    prev: 'Calendar', curr: 'Client info', next: 'Summary', highlight: 'next',
    prevNav: ScreenView.mobileCalender, currNav: ScreenView.mobileClientInfo, nextNav: ScreenView.mobileSummary
  },

  [ScreenView.screen10]: {
    prev: 'Calendar', curr: 'Client info', next: 'Summary', highlight: 'next',
    prevNav: ScreenView.mobileCalender, currNav: ScreenView.mobileClientInfo, nextNav: ScreenView.mobileSummary
  },

  [ScreenView.screen11]: {
    prev: 'Calendar', curr: 'Client info', next: 'Summary', highlight: 'next',
    prevNav: ScreenView.mobileCalender, currNav: ScreenView.mobileClientInfo, nextNav: ScreenView.mobileSummary
  },

  [ScreenView.screen12]: {
    prev: 'Calendar', curr: 'Client info', next: 'Summary', highlight: 'next',
    prevNav: ScreenView.mobileCalender, currNav: ScreenView.mobileClientInfo, nextNav: ScreenView.mobileSummary
  }

}
