import { StateContext } from "@ngxs/store";
import { catchError, map, Observable, of, tap } from "rxjs";
import { get, merge } from 'lodash';

import { BookApiService, GetImageInformationResponse, ImageResultData, TripActivityDataMap } from "@hiptraveler/data-access/api";
import { GetImageInformation } from "../itinerary";
import { ItineraryStateModel } from "..";

interface Param {
  ctx: StateContext<ItineraryStateModel>;
  action: GetImageInformation;
  response: GetImageInformationResponse;
  bookApi: BookApiService;
}

export function patchGetImageInformation({ ctx, response, action, bookApi }: Param): Observable<TripActivityDataMap | null> {

  const taDataMapFromResponse = get(response, 'data.content.taDataMap', null);

  const baseImageInformation: ImageResultData = merge(
    {},
    get(response, 'data.content', {}) as ImageResultData,
    { tourExpire: !!get(response, 'data.tourExpire', false) }
  );

  const updateContext = (imageInformation: ImageResultData) => ctx.patchState({ imageInformation });

  const imageReq$ = of(taDataMapFromResponse).pipe(
    tap(() => updateContext(baseImageInformation))
  );

  const tripAdvisorData$ = bookApi.getTripAdvisorData(action.data).pipe(
    map(res => get(res, 'data', null)),
    catchError(() => of(null)),
    tap(taDataMap => {
      if (taDataMap?.error) {
        taDataMap = null;
      }
      const updatedImageInformation: ImageResultData = merge({}, baseImageInformation, { taDataMap });
      updateContext(updatedImageInformation);
    })
  );

  return taDataMapFromResponse ? imageReq$ : tripAdvisorData$;
}
