import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Subject } from 'rxjs';

import { ApiService, getWindowRef, openedWindowPopUp } from '@hiptraveler/common';
import { SocialMediaType } from '@hiptraveler/features/registration-form';

export type SocialType = 'fb' | 'tw' | 'insta' | 'tk';
export type StateValue = Partial<Record<SocialType, string>>;

export const CSRF_KEY: string = '_ht_social_link_values';
const MOBILE_SCREEN: number = 900;

@Injectable({
  providedIn: 'root'
})
export class SocialApiPopupService {

  stateValue$$ = new BehaviorSubject<string | null>(null);
  stateValue$ = this.stateValue$$.asObservable().pipe(
    filter(Boolean),
    map(e => e.split('::') as [ SocialMediaType, string ])
  );

  poolingSuccess$ = new Subject<void>();
  timer$ = new Subject<void>();

  constructor(
    private apiService: ApiService
  ) { }

  openPopupWindow(social: SocialType): void {
    social === 'tk' && this.setupPopupWindowByUrl('tiktok', '/trip-planner/tk/oauth');
    social === 'tw' && this.setupPopupWindowByUrl('twitter', '/trip-planner/tw/oauth');
    social === 'fb' && this.setupPopupWindowByUrl('facebook', '/trip-planner/fb/business/auth');
    social === 'insta' && this.setupPopupWindowByUrl('instagram', '/trip-planner/insta/auth');
  }

  private setupPopupWindowByUrl(social: SocialMediaType, url: string): void {
    const _window = getWindowRef();
    if (!_window) return;

    const csrfState = this.getStateBySocial(social);
    url = this.apiService.hosted ? this.apiService.parseUrl(url) : url;
    url += `?state=${csrfState}`;

    const mobileScreen = _window.document.body.clientWidth <= MOBILE_SCREEN;
    const target = mobileScreen ? '_break' : 'popupWindow';

    _window[openedWindowPopUp] = _window.open(url, target, this.windowFeatures);

    this.timer$.next();
    this.poolingSuccess$.next();
    this.stateValue$$.next(`${social}::${csrfState}`);
  }

  private get windowFeatures(): string {
    const _window = getWindowRef();
    if (!_window) return '';
    
    const mobileScreen = _window.document.body.clientWidth <= MOBILE_SCREEN;
    if (mobileScreen) return '';

    const { width, height } = {width: 600, height: 700 };
    const { left, top } = {
      left: (_window.screen.width - width) / 2,
      top: (_window.screen.height - height) / 2
    };
    return `width=${width},height=${height},scrollbars=yes,left=${left},top=${top}`;
  }

  private getStateBySocial(social: SocialMediaType): string {
    const _window = getWindowRef();
    const csrfState = _window.CryptoJS.lib.WordArray.random(32).toString() as string;
    const socialLinks = JSON.parse(_window.sessionStorage.getItem(CSRF_KEY) || '{}');

    if (!_window) return csrfState;

    if (socialLinks[social]) {
      return socialLinks[social];
    } else {
      socialLinks[social] = csrfState;
      _window.sessionStorage.setItem(CSRF_KEY, JSON.stringify(socialLinks));
      return socialLinks[social];
    }
  }

}
