import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Store } from '@ngxs/store';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { BehaviorSubject, filter, map, take } from 'rxjs';

import { ImageSvgExternalService } from '@hiptraveler/data-access/external';
import { AppListenerService, appVersion, appVersionKey, appVersionString, Environment, ENVIRONMENT, getWindowRef, setWindowRef } from '@hiptraveler/common';
import { BrandState } from '@hiptraveler/data-access/brand';

@Injectable()
export class AppService {

  #cachePending$$ = new BehaviorSubject<boolean>(false);
  cachePending$ = this.#cachePending$$.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(ENVIRONMENT) private environment: Environment,
    @Optional() @Inject(REQUEST) private request: Request,
    private store: Store,
    private appListener: AppListenerService,
    private imageSvgExternal: ImageSvgExternalService
  ) { }

  setup(): void {
    this.serverSetup();
    this.browserSetup();
    this.environmentSetup();
  }

  private serverSetup(): void {

    if (isPlatformServer(this.platformId) && this.appListener.serverRequest) {

      this.cacheAssets();
      this.preloadSearchPageBannerOnServer();

      const hostname = this.request.get('X-Forwarded-Host') || this.request.get('Host');
      setWindowRef({ location: { hostname, pathname: this.request.path } });
    }
  }

  private browserSetup(): void {

    if (isPlatformBrowser(this.platformId)) {
      
      setWindowRef(window);
      getWindowRef()[appVersionKey] = appVersion;
      this.cacheAssets();
      this.preloadSearchPageBannerOnServer();

      const consoleVersionDisplay = window.location.hostname.includes('dev.hiptraveler.com')
       || window.location.hostname.includes('test.hiptraveler.com')
       || window.location.hostname.includes('yourbrand.hiptraveler.com');

      consoleVersionDisplay && console.log(`\n
        \t${appVersionString}
      \n\n`);
    }
  }

  private environmentSetup(): void {

    if (this.environment.production) {
      console.log = function (_: string) { }
      console.warn = function (_: string) { }
    }
  }

  private cacheAssets(): void {
    this.#cachePending$$.next(true);
    this.imageSvgExternal.registerIcons(() => this.#cachePending$$.next(false));
  }

  private preloadSearchPageBannerOnServer(): void {
    this.store.select(BrandState.brandCampaign).pipe(
      map(e => e?.cCoverImg?.cover),
      filter(Boolean),
      take(1)
    ).subscribe((coverImage: string) => {
      const link = this.document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = coverImage;
      this.document.head.appendChild(link);
    });
  }

}
