import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { compact } from 'lodash';

import { ToolbarOption, TranslationService } from '@hiptraveler/common';
import { ProfileStateService } from '../common';

@Pipe({
  name: 'selection'
})
export class SelectionPipe implements PipeTransform {

  constructor(
    private stateService: ProfileStateService,
    private i18n: TranslationService
  ) { }

  transform(value: ToolbarOption[]): Observable<ToolbarOption[]> {
    return this.stateService.selfProfile$.pipe(
      switchMap(selfProfile => this.stateService.travelAgent$.pipe(
        map(travelAgent => {
          return selfProfile ? value.concat(compact([
            { name: 'settings', url: 'profile/_PROFILE/settings' },
            travelAgent ? { name: 'my-uploads-option', url: 'profile/_PROFILE/uploads' } : null
          ])) : value;
        }),
        map(value => value.map(this.parseSelection.bind(this)))
      )
    ));
  }

  private parseSelection(selection: ToolbarOption): ToolbarOption {
    return { name: this.i18n.getText('profile', selection.name), url: this.parseSelectionUrl(selection) };
  }

  private parseSelectionUrl(selection: ToolbarOption): string {
    return selection.url!.replace('_PROFILE', this.stateService.userId);
  }

}
