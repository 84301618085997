import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';

import { AuthAction } from '@hiptraveler/data-access/auth';
import { ProfileAction, ProfileState } from '@hiptraveler/data-access/profile';
import { PromptDialogActionService } from '@hiptraveler/dialogs/prompt-dialog';
import { ProfileStateFormService, ProfileStateService } from '../../../common';
import { UserSettingsService } from '../user-settings.service';
import { currentLang } from '@hiptraveler/common';

@Injectable()
export class SettingsFormService implements OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store,
    private promptDialog: PromptDialogActionService,
    private stateService: ProfileStateService,
    private stateFormService: ProfileStateFormService,
    private userSettings: UserSettingsService
  ) {
    this.stateService.saveClicked$$.asObservable()
      .pipe(takeUntil(this.subscription$))
      .subscribe(this.submitListener.bind(this));
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  get invalidPassword(): boolean {
    return this.stateFormService.passwordAssistanceForm.get('password')?.errors?.['pattern'];
  }

  get passwordMismatch(): boolean {
    return this.stateFormService.passwordAssistanceForm.get('confirm_password')?.errors?.['passwordMismatch'];
  }

  deleteAccount(): void {

    const yesExecutable = async () => {
      this.promptDialog.instance.close();
      const userId = this.store.selectSnapshot(ProfileState.profileDetails)?.profileId;
      if (!userId) return;
      await firstValueFrom(this.promptDialog.instance.afterClosed()!);
      try {
        this.userSettings.formProcessing$$.next(true);
        await firstValueFrom(this.store.dispatch(new ProfileAction.DeleteAccountProfileById({ userId })));
        await firstValueFrom(this.store.dispatch(new AuthAction.SignOut));
        this.userSettings.formProcessing$$.next(false);
        this.router.navigate([ '/', currentLang() ]);
      } finally { }
    };

    const noExecutable = () => {
      this.promptDialog.instance.close();
    };

    this.promptDialog.open({
      title: 'profile.delete-acc',
      message: 'profile.delete-account-message',
      buttons: [
        { name: 'views.label.yes', theme: 'action', progressSpinner: true, executable: yesExecutable },
        { name: 'views.label.no', theme: 'secondary', executable: noExecutable }
      ]
    })
  }

  async submitListener(): Promise<void> {
    const value = this.stateFormService.passwordAssistanceForm.value;
    if (this.stateFormService.passwordAssistanceForm.invalid) return;
    try {
      this.userSettings.formProcessing$$.next(true);
      await firstValueFrom(this.store.dispatch(new ProfileAction.UpdatePassword(value)));
      this.userSettings.formProcessing$$.next(false);
      this.stateService.profileTabSelection$$.next('about');
      this.stateService.profileSettingsView$$.next(false);
    } finally {
      this.stateFormService.passwordAssistanceForm.reset();
    }
  }

}
