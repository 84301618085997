import { map, switchMap, tap } from 'rxjs';
import { filter, includes } from 'lodash';

import { EFAStateServiceService, CarouselFilterType, CarouselScreenValue, FormValue, ScreenView, CarouselService } from "..";

export class CarouselView {

  constructor(
    private stateService: EFAStateServiceService,
    private carousel: CarouselService
  ) { }

  initialize(screen: CarouselScreenValue, type: CarouselFilterType): void {
    
    /** To do (expfinder-ai): Do not uncomment or remove */
    // this.completions.authInitialize();
    // this.chatAI.observe(screen, type, this.carousel);

    this.carouselItemSelectionObserver(screen, type);
  }

  private carouselItemSelectionObserver(screen: CarouselScreenValue, type: CarouselFilterType): void {
    this.stateService.formValue$.pipe(
      map((form: FormValue) => form.carouselData[screen]),
      switchMap((values: string[]) => this.carousel.getDataByFilter(type).pipe(
        tap((data: any) => {

          const isLocationScreenMobile = this.stateService.isMobile
            && screen === ScreenView.locationCarousel
            && this.stateService.screenViewValue$$.value === 'screen3';

          if (this.stateService.screenViewValue$$.value === screen || isLocationScreenMobile) {

            screen = isLocationScreenMobile ? ScreenView.locationCarousel : screen;

            const summaryItems = this.stateService.summaryItems$$.value?.filter(e => e?.['carouselData'] !== screen) || [];

            const response = 
              filter(data, (obj: any) => includes(values, obj.code))
              .map((e: any) => ({ output: e.name, carouselData: screen }))
       
            this.stateService.summaryItems$$.next([ ...summaryItems, ...response ]);
          }
        })
      ))
    ).subscribe();
  }

}
