import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';

import { ItineraryAction } from '@hiptraveler/data-access/itinerary';
import { PromptDialogActionService } from '@hiptraveler/dialogs/prompt-dialog';
import { ComposeBlogFormComponent } from './../compose-blog-form/compose-blog-form.component';
import { AppListenerService } from '@hiptraveler/common';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'app-new-blog',
  template: `
    <ng-container *ngIf="appListener.languageLoadState$ | async">
      <compose-blog-form navbarAction dispatchObserver [@opacityFadeIn]></compose-blog-form>
    </ng-container>
  `,
  styles: [`
    body {
      background-color: var(--ht-app-background);
    }
  `],
  viewProviders: [ PromptDialogActionService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFadeIn() ],
})
export class NewBlogComponent implements OnInit {

  @ViewChild(ComposeBlogFormComponent) composeForm: ComposeBlogFormComponent;

  constructor(
    private store: Store,
    public appListener: AppListenerService
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new ItineraryAction.PartialResetItineraryState([ 'basicInfo', 'actDateMap' ]));
  }

}
