import { animate, query, stagger, style, transition, trigger } from "@angular/animations"

export const chipListAnimation = trigger('chipListAnimation', [
  transition(':enter', []), // no animation on component init
  transition('* => *', [
    query(':enter', [
      style({ opacity: 0, transform: 'translateY(-5px)' }),
      stagger(100, [
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ], { optional: true })
  ])
]);
