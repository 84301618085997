import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, map, Observable, shareReplay } from 'rxjs';

@Injectable()
export class CDNReaderService {

  dataCache$: Observable<any>;

  constructor(
    private http: HttpClient
  ) { }

  getCdnData<T = any>(assetKey: AssetKey): Observable<T> {
    if (!this.dataCache$) {
      this.dataCache$ = this.http.get(getAssetUrl(assetKey)).pipe(
        shareReplay(1)
      );
    }
    return this.dataCache$;
  }

  getMultipleCdnData<T = any>(...assetKeys: AssetKey[]): Observable<T> {
    if (!this.dataCache$) {
      const requests = assetKeys
        .map(getAssetUrl.bind(this))
        .map(assetKey => this.http.get(assetKey).pipe(shareReplay(1)));
  
      this.dataCache$ = combineLatest(requests).pipe(
        map(responses => responses.reduce((acc: any, response, index) => {
          acc[`response${index + 1}`] = response;
          return acc;
        }, {} as Record<string, any>))
      );
    }
    return this.dataCache$;
  }

}

type AssetKey =
  'base64.ambassadorHighlight'
  | 'base64.appCommon'
  | 'base64.experienceFinderAiImages'
  | 'base64.experienceFinderAssets'
  | 'base64.experienceFinderDefault'
  | 'base64.footer'
  | 'base64.travelOptionsBackground'
  | 'base64.tripPlanner'
  | 'svg.collection';

const CDN_DOMAIN = 'https://d36j46pvk6qi4f.cloudfront.net/site/main/static';

const ASSETS = {
  base64: {
    // ambassadorHighlight: `${CDN_DOMAIN}/ambassador-highlight-image.json`,
    // appCommon: `${CDN_DOMAIN}/app-common-image.json`,
    // experienceFinderAssets: `${CDN_DOMAIN}/experience-finder-asset-images.json`,
    // experienceFinderDefault: `${CDN_DOMAIN}/experience-finder-default-image.json`,
    // footer: `${CDN_DOMAIN}/footer-image.json`,
    // travelOptionsBackground: `${CDN_DOMAIN}/travel-options-bg.json`,
    // tripPlanner: `${CDN_DOMAIN}/trip-planner-image.json`
    ambassadorHighlight: `assets/base64-collection/ambassador-highlight-image.json`,
    appCommon: `assets/base64-collection/app-common-image.json`,
    experienceFinderAiImages: `assets/base64-collection/experience-finder-ai-images.json`,
    experienceFinderAssets: `assets/base64-collection/experience-finder-asset-images.json`,
    experienceFinderDefault: `assets/base64-collection/experience-finder-default-image.json`,
    footer: `assets/base64-collection/footer-image.json`,
    travelOptionsBackground: `assets/base64-collection/travel-options-bg.json`,
    tripPlanner: `assets/base64-collection/trip-planner-image.json`
  },
  svg: {
    // collection: `${CDN_DOMAIN}/icon-svg-collection.json`
    collection: `assets/icon-svg-collection.json`
  }
};

function getAssetUrl(assetKey: AssetKey): string {
  const [ key, value ] = assetKey.split('.');
  return (ASSETS as any)[key][value];
}
