import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { DriveDurationComponent } from './drive-duration.component';
import { DriveDurationActionDirective } from './drive-duration-action.directive';

const MODULES = [
  CommonModule,
  MatIconModule
];

const COMPONENTS = [
  DriveDurationComponent
];

const DIRECTIVES = [
  DriveDurationActionDirective
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    DriveDurationComponent
  ]
})
export class DriveDurationModule { }
