import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { TravelMediumComponent } from './travel-medium.component';
import { TravelMediumPipe } from './travel-medium.pipe';

const MODULES = [
  CommonModule,
  MatIconModule
];

const COMPONENTS = [
  TravelMediumComponent
];

const PIPES = [
  TravelMediumPipe
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    TravelMediumComponent
  ]
})
export class TravelMediumModule { }
