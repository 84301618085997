import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { uniq } from 'lodash';

import { ListItem } from '../list-items';
import { AppListenerService, filterQueryStringKey, getWindowRef, LOCATION, queryStringToObject, SearchLocationService, SearchRoute } from '@hiptraveler/common';
import { ViewService } from './view.service';

@Injectable()
export class FiltersStateService {

  panelFilterApplyClicked$$ = new Subject<void>();
  panelFilterClearClicked$$ = new Subject<void>();

  travelDatesCloseActionState$$ = new BehaviorSubject<boolean>(false);
  
  get searchPage(): SearchRoute | 'search' {
    const { rootSearchPage, rootSearchRoute, searchRoutePath } = this.searchLocation;
    return (rootSearchPage || rootSearchRoute) ? 'search' : (searchRoutePath || 'search');
  }

  selectedItems$$ = new BehaviorSubject<ListItem[]>([]);
  selectedItemKeys$ = this.selectedItems$$.asObservable().pipe(map(e => uniq(e.map(x => x?.value).filter(Boolean))));
  get selectedItems(): ListItem[] { return this.selectedItems$$.value }
  updateSelectedItems(listItems: ListItem[]): void {
    this.selectedItems$$.next([ ...this.selectedItems$$.value, ...listItems ]);
  }
  
  selectedTravelStyles$$ = new BehaviorSubject<ListItem[]>([]);
  selectedTravelStyleKeys$ = this.selectedTravelStyles$$.asObservable().pipe(map(e => uniq(e.map(x => x?.value).filter(Boolean))));
  get selectedTravelStyles(): ListItem[] { return this.selectedTravelStyles$$.value }
  updateSelectedTravelStyles(listItems: ListItem[]): void {
    this.selectedTravelStyles$$.next([ ...this.selectedTravelStyles$$.value, ...listItems ]);
  }

  constructor(
    @Inject(LOCATION) private location2: any,
    private location: Location,
    private appListener: AppListenerService,
    private searchLocation: SearchLocationService,
    private view: ViewService
  ) {
    this.appListener.globalSignalListener('exp-finder-query-listener').subscribe(async () => {
      const query = queryStringToObject(this.location2.search) as any;
      const listItems = await this.view.listItems;

      const experiences = (decodeURIComponent(query.experiences)?.split(',') || [])?.map((item) => {
        return listItems.activities.find(e => e.value === item) as ListItem;
      }).filter(Boolean);
      const travelStyle = (decodeURIComponent(query.travelStyle)?.split(',') || [])?.map((item) => {
        return listItems.activities.find(e => e.value === item) as ListItem;
      }).filter(Boolean);
      const activities = (decodeURIComponent(query.activities)?.split(',') || [])?.map((item) => {
        return listItems.activities.find(e => e.value === item) as ListItem;
      }).filter(Boolean);

      this.updateSelectedTravelStyles([ ...experiences, ...travelStyle ]);
      this.updateSelectedItems(activities);
    });
  }

  clearFilters(): void {
    delete getWindowRef()[filterQueryStringKey];
    this.location.replaceState(getWindowRef()?.location?.pathname);
    this.selectedItems$$.next([]);
    this.selectedTravelStyles$$.next([]);
  }

}
