import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, of, Subject, switchMap, takeUntil, throttleTime } from 'rxjs';

import { Experience } from '@hiptraveler/data-access/api';
import { AppListenerService, getWindowRef, isWidget } from '@hiptraveler/common';
import { CarouselHighlight, FormCarouselData, FormValue, PANEL_HEIGHT, ScreenView, ScreenViewValue, SummaryItems } from '.';
import { syncRemovedSummaryItems, newFormValue } from './summary-items';

export const FORM_VALUE: FormValue = {
  dateRange:    { start: new Date, end: new Date },
  carouselData: { [ScreenView.experienceCarousel]: [], [ScreenView.travelCarousel]: [],
                  [ScreenView.activitiesCarousel]: [], [ScreenView.locationCarousel]: [] },
  clientInfo:   { adults: 0, children: 0, infants: 0 }
};

@Injectable()
export class EFAStateServiceService {

  scrollToScreen$$  = new Subject<number>();
  scrollToScreen$  = this.scrollToScreen$$.asObservable();

  scrollToView$$  = new Subject<'next' | 'previous'>();
  scrollToView$  = this.scrollToView$$.asObservable();

  scrolled$$  = new BehaviorSubject<boolean>(true);
  scrolled$  = this.scrolled$$.asObservable().pipe(
    distinctUntilChanged()
  );
  
  inputPending$$ = new BehaviorSubject<boolean>(false);
  inputPending$ = this.inputPending$$.asObservable();
  
  myTripsViewState$$ = new BehaviorSubject<boolean>(false);
  myTripsViewState$ = this.myTripsViewState$$.asObservable();
  
  carouselHighlight$$ = new BehaviorSubject<CarouselHighlight>('Experiences');
  carouselHighlight$ = this.carouselHighlight$$.asObservable().pipe(
    throttleTime(350)
  );
  
  get myTripsViewState(): boolean {
    return this.myTripsViewState$$.value;
  }

  get inputPending(): boolean {
    return this.inputPending$$.value;
  }
    
  panelCloseTrigger$$ = new Subject<void>();
  panelCloseTrigger$ = this.panelCloseTrigger$$.asObservable();

  brandHeaderTypeState$$ = new Subject<boolean>();
  pauseBrandInfoUXState$$ = new Subject<boolean>();
  formSubmit$ = new Subject<void>();
  formSubmitCompleted$ = new Subject<void>();

  subscription$ = new Subject<void>();

  // ScreenViewValue

  get view(): ScreenViewValue {
    const desktop = getWindowRef().innerWidth > 650;
    return {
      [ScreenView.myTrips]:             0,
      [ScreenView.mainSelection]:       0,
      [ScreenView.experienceCarousel]:  desktop ? (PANEL_HEIGHT*1) : (getWindowRef().innerHeight*1 - 58),
      [ScreenView.travelCarousel]:      desktop ? (PANEL_HEIGHT*2) : (getWindowRef().innerHeight*2 - 58*2),
      [ScreenView.activitiesCarousel]:  desktop ? (PANEL_HEIGHT*3) : (getWindowRef().innerHeight*3 - 58*3),
      [ScreenView.locationCarousel]:    desktop ? (PANEL_HEIGHT*4) : (getWindowRef().innerHeight*4 - 58*4),
      [ScreenView.calendar]:            desktop ? (PANEL_HEIGHT*5) : (getWindowRef().innerHeight*5 - 58*5),
      [ScreenView.clientInfo]:          desktop ? (PANEL_HEIGHT*6) : (getWindowRef().innerHeight*6 - 58*6),
      [ScreenView.summary]:             desktop ? (PANEL_HEIGHT*7) : (getWindowRef().innerHeight*7 - 58*7),
      [ScreenView.screen9]:             desktop ? (PANEL_HEIGHT*8) : (getWindowRef().innerHeight*8 - 58*8),
      [ScreenView.screen10]:            desktop ? (PANEL_HEIGHT*9) : (getWindowRef().innerHeight*9 - 58*9),
      [ScreenView.screen11]:            desktop ? (PANEL_HEIGHT*10) : (getWindowRef().innerHeight*10 - 58*1),
      [ScreenView.screen12]:            desktop ? (PANEL_HEIGHT*11) : (getWindowRef().innerHeight*11 - 58*1)
    };
  }

  // screenViewValue

  inputMessage$$ = new BehaviorSubject<string>('');
  inputMessage$ = this.inputMessage$$.asObservable().pipe( filter(Boolean), distinctUntilChanged() );

  // summaryTags

  summaryItems$$ = new BehaviorSubject<SummaryItems>(null);
  summaryItems$ = this.summaryItems$$.asObservable().pipe(
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
  );
  summaryItemsPending$ = this.summaryItems$$.asObservable().pipe(map(e => e === null));

  // screenViewValue

  screenViewValue$$ = new BehaviorSubject<ScreenView | null>(null);
  screenViewValue$ = this.screenViewValue$$.asObservable().pipe(filter(Boolean));
  
  get screenViewValue(): ScreenView {
    return this.screenViewValue$$.value || ScreenView.mainSelection;
  }
  
  // Aspect ratio

  isMobile$ = this.appListener.clientWidth$.pipe( map(e => e <= (isWidget() ? 650 : 700)) );
  isDesktop$ = this.isMobile$.pipe( map(e => !e) );

  get isMobile(): boolean {
    return getWindowRef().innerWidth <= (isWidget() ? 650 : 700);
  }
  get isDesktop(): boolean {
    return !this.isMobile;
  }

  // locations
  
  locations$$ = new BehaviorSubject<Partial<Experience>[] | undefined>(undefined);
  locations$ = this.locations$$.asObservable();

  appendLocation(location: Partial<Experience>): void {
    this.locations$$.next([ location, ...(this.locations$$.value || []) ]);
  }

  // formValue

  formValue$$ = new BehaviorSubject<FormValue>(FORM_VALUE);
  formValue$ = this.formValue$$.asObservable().pipe(
    map(e => JSON.stringify(e)), distinctUntilChanged(), map(e => JSON.parse(e) as FormValue)
  );
  carouselData$ = this.formValue$$.asObservable().pipe(map(e => e.carouselData));

  get formValue(): FormValue {
    return this.formValue$$.value;
  }

  patchFormValue(form: Partial<FormValue> | null): void {
    const value = { ...this.formValue$$.value };
    this.formValue$$.next({ ...value,  ...(form || {}) });
  }

  patchCarouselItems(carouselData: Partial<FormCarouselData>): void {
    const formValue = { ...this.formValue$$.value };
    const form = {
      ...formValue, carouselData: { ...formValue.carouselData, ...carouselData }
    };
    this.formValue$$.next({ ...formValue, ...form });
  }
  
  constructor(
    private appListener: AppListenerService
  ) { }

  getInputMessageByScreen(screen: ScreenView): Observable<string> {
    return this.inputMessage$.pipe(
      switchMap((inputMessage: string) => of(this.screenViewValue$$.value).pipe(
        filter(e => e === screen),
        map(() => inputMessage)
      )),
      takeUntil(this.subscription$)
    );
  }

  removeSummaryItem(index: number): void {

    const data = this.summaryItems$$.value;
    if (!data) return;
    const targetItem = data[index];
    const formValue = newFormValue(targetItem, this.formValue);
    this.patchFormValue(formValue);

    syncRemovedSummaryItems({
      data, index, formValue: this.formValue
    }, this.patchFormValue.bind(this))

    const summaryItems = [ ...data ];
    summaryItems.splice(index, 1);
    this.summaryItems$$.next(summaryItems);
  }

}
