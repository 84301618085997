import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import * as ItineraryApi from './interfaces';
import { handleErrorAndThrow, RequestByIdDto } from '@hiptraveler/data-access/api';
import { brandCampaignId, clientVID, currentLang, isFavorites } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class ItineraryApiService {

  constructor(
    private http: HttpClient
  ) { }

  getItineraryRoute({ itineraryId, day }: ItineraryApi.GetItineraryRouteDto): Observable<ItineraryApi.GetItineraryRouteResponse> {
    return this.http.get<ItineraryApi.GetItineraryRouteResponse>(`/trip-planner/itinerary/route/${itineraryId}/${day}`).pipe(
      tap(handleErrorAndThrow)
    );
  }

  getBlogByPageTitle(pageTitle: string): Observable<ItineraryApi.GetBlogResponse> {
    return this.http.get<ItineraryApi.GetBlogResponse>(`/trip-planner/readBlog/${currentLang()}/${pageTitle}`).pipe(
      tap(handleErrorAndThrow)
    );
  }

  getItineraryByPageTitle(pageTitle: string): Observable<ItineraryApi.GetItineraryResponse> {
    return this.http.get<ItineraryApi.GetItineraryResponse>(`/trip-planner/itinerary/${brandCampaignId()}/${pageTitle}/itineraryDetail/${currentLang()}`).pipe(
      tap(handleErrorAndThrow)
    );
  }

  getItineraryTripByIdAndCount(id: string, count: number): Observable<ItineraryApi.GetItineraryTripByIdAndCountResponse> {
    return this.http.get<ItineraryApi.GetItineraryTripByIdAndCountResponse>(`/trip-planner/getTripDetails/${brandCampaignId()}/${id}/${currentLang()}/${count}`, {
      params: { preformatted: 'full' }
    }).pipe(
      tap(handleErrorAndThrow)
    );
  }

  getBlogTripByIdAndCount(id: string, count: number): Observable<ItineraryApi.GetItineraryTripByIdAndCountResponse> {
    return this.http.get<ItineraryApi.GetItineraryTripByIdAndCountResponse>(`/trip-planner/blog/tripdetails/${brandCampaignId()}/${currentLang()}/${id}/${count}`).pipe(
      tap(handleErrorAndThrow)
    );
  }

  getTravelAgentList(id: string): Observable<ItineraryApi.GetTravelAgentListResponse> {
    return this.http.get<ItineraryApi.GetTravelAgentListResponse>(`/trip-planner/getTaListbyIti/${brandCampaignId()}/${id}/${currentLang()}`).pipe(
      tap(handleErrorAndThrow)
    );
  }

  addItineraryDay(payload: ItineraryApi.AddItineraryDayDto): Observable<ItineraryApi.AddItineraryDayResponse> {
    return this.http.post<ItineraryApi.AddItineraryDayResponse>(`/trip-planner/blog/addItinerary`, payload).pipe(
      tap(handleErrorAndThrow),
    );
  }

  updateItineraryActivity(payload: ItineraryApi.UpdateItineraryActivityDto): Observable<ItineraryApi.UpdateItineraryActivityResponse> {
    payload['vId'] = clientVID();
    return this.http.post<ItineraryApi.UpdateItineraryActivityResponse>(`/trip-planner/blog/updateItinerary`, payload).pipe(
      tap(handleErrorAndThrow),
    );
  }

  addItinerary(payload: ItineraryApi.AddItineraryDto): Observable<ItineraryApi.AddItineraryResponse> {
    const path = isFavorites() ? 'itinerary/favorites' : 'addItinerary';
    return this.http.post<ItineraryApi.AddItineraryResponse>(`/trip-planner/${path}`, payload).pipe(
      tap(handleErrorAndThrow)
    );
  }

  uploadItineraryBlog<T>(payload: T, param: ItineraryApi.ItineraryParam): Observable<ItineraryApi.UploadItineraryBlogResponse> {
    return this.http.post<ItineraryApi.UploadItineraryBlogResponse>(`/trip-planner/uploadBlog/${param}/${brandCampaignId()}`, payload).pipe(
      tap(handleErrorAndThrow)
    );
  }

  addReservation(payload: ItineraryApi.AddReservationDto): Observable<ItineraryApi.AddReservationResponse> {
    return this.http.post<ItineraryApi.AddReservationResponse>(`/trip-planner/addReservation`, payload).pipe(
      tap(handleErrorAndThrow)
    );
  }

  deleteReservation({ id }: RequestByIdDto): Observable<ItineraryApi.DeleteReservationResponse> {
    return this.http.post<ItineraryApi.DeleteReservationResponse>(`/trip-planner/itinerary/delete/${brandCampaignId()}/reservation/${id}`, {}, { params: { vId: `${clientVID()}` } }).pipe(
      tap(handleErrorAndThrow)
    );
  }

  getChecklistReservations({ id }: RequestByIdDto): Observable<ItineraryApi.GetChecklistReservationsResponse> {
    return this.http.get<ItineraryApi.GetChecklistReservationsResponse>(`/trip-planner/getCheckList/${id}`).pipe(
      tap(handleErrorAndThrow)
    );
  }

  saveItineraryTripBlog({ id }: ItineraryApi.SaveItineraryTripBlogDto): Observable<ItineraryApi.SaveItineraryTripBlogResponse> {
    return this.http.post<ItineraryApi.SaveItineraryTripBlogResponse>(`/trip-planner/saveBlog/${brandCampaignId()}/${id}`, {}, { params: { vId: `${clientVID()}` } }).pipe(
      tap(handleErrorAndThrow)
    );
  }

}
