import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { BottomNavbarComponent } from './bottom-navbar.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  TranslateModule,
  LazyLoadImageModule
];

const COMPONENTS = [
  BottomNavbarComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    BottomNavbarComponent
  ]
})
export class BottomNavbarModule { }
