import { Component, ViewEncapsulation } from '@angular/core';

import { EFAStateServiceService, ScreenView } from '../../../shared';
import { DATA_SELECTIONS, DataSelection, DataSelectionKey } from './data-selection';

@Component({
  selector: 'summary-data-selection',
  template: `
    <div
      matRipple
      class="data-selection"
      [ngStyle]="{ display: data.display }"
      (click)="navigate(data.key)"
      *ngFor="let data of dataSelections;">
      <p class="data-selection--head">{{ data.head }} <mat-icon style="margin-left: 8px;">{{ data.icon }}</mat-icon></p>
      <p class="data-selection--info">{{ data.info }}</p>
    </div>
  `,
  styleUrls: ['./data-selection.component.scss'],
  host: { class: 'summary--data-selection' },
  encapsulation: ViewEncapsulation.None
})
export class DataSelectionComponent {

  dataSelections: DataSelection[] = DATA_SELECTIONS;

  constructor(
    private stateService: EFAStateServiceService
  ) { }

  navigate(dataKey: DataSelectionKey): void {
    const scrollTop = this.stateService.view;
    this.stateService.scrollToScreen$$.next({
      travel_style: scrollTop[ScreenView.travelCarousel],
      activities: scrollTop[ScreenView.activitiesCarousel],
      location: this.stateService.isMobile ? scrollTop.screen3 : scrollTop[ScreenView.locationCarousel],
      calendar: this.stateService.isMobile ? scrollTop.screen4 : scrollTop[ScreenView.calendar],
      client_info: this.stateService.isMobile ? scrollTop.screen5 : scrollTop[ScreenView.clientInfo]
    }[dataKey]);
  }

}
