import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { ActivityDateData, ItineraryParam } from '@hiptraveler/data-access/api';
import { SearchPageControlStateService } from '@hiptraveler/common';
import { ItineraryStateService } from '../common';
import { ActivityDayListService } from './activity-day-list.service';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { ComposePrivateNoteDialogActionService } from '@hiptraveler/dialogs/compose-private-note-dialog';
import { PromptDialogActionService } from '@hiptraveler/dialogs/prompt-dialog';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'activity-day-list',
  templateUrl: './activity-day-list.component.html',
  styleUrls: ['./activity-day-list.component.scss'],
  host: { class: 'feature-itinerary-activity-day-list' },
  viewProviders: [ AuthDialogActionService, ActivityDayListService, ComposePrivateNoteDialogActionService, PromptDialogActionService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFadeIn(500) ]
})
export class ActivityDayListComponent implements OnInit, OnDestroy {

  @Input() view: string;
  @Input() type: ItineraryParam = 'itinerary';

  actDate: ActivityDateData[] | null;

  constructor(
    public service: ActivityDayListService,
    public searchPageControl: SearchPageControlStateService,
    public itineraryState: ItineraryStateService
  ) { }

  ngOnInit(): void {
    this.service.itineraryType = this.type;

    this.service.actDate$.subscribe((value: ActivityDateData[] | null) => {
      this.actDate = value;
    });

    this.service.globalSignalObserver();
    setTimeout(() => this.service.activityDayListUiObserver(), 500);
  }

  ngOnDestroy(): void {
    this.service.resetAuthHandle();
  }

}
