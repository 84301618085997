import { AfterViewInit, Component, ComponentRef, ElementRef, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';

import { ChatCompletionsService } from '@hiptraveler/core/openai';
import { ExperienceFinderAi } from '../features';
import { CarouselService, EFAStateServiceService } from '../shared';
import { opacityFade } from '@hiptraveler/animations';
import { Environment, ENVIRONMENT, getWindowRef, isIframe, isWidget } from '@hiptraveler/common';

@Component({
  selector: 'app-experience-finder-ai',
  templateUrl: './experience-finder-ai.component.html',
  styleUrls: ['./experience-finder-ai.component.scss', './experience-finder-ai-components.scss'],
  host: { class: 'experience-finder-ai mat-elevation-z2' },
  providers: [ ChatCompletionsService, EFAStateServiceService, CarouselService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade() ]
})
export class ExperienceFinderAiComponent extends ExperienceFinderAi implements OnDestroy, AfterViewInit {

  ref: ComponentRef<ExperienceFinderAiComponent> | null;
  action: string;
  desktop: boolean;

  constructor(
    @Inject(ENVIRONMENT) private env: Environment,
    private element: ElementRef<HTMLElement>,
    public stateService: EFAStateServiceService
  ) {
    super();
  }

  get widget(): boolean {
    return (isWidget() && isIframe()) || (!isIframe() && this.env.build === 'Staging') || this.env.local;
  }

  ngOnDestroy(): void {
    this.stateService.subscription$.next();
  }
  
  ngAfterViewInit(): void {
    this.initializeComponents();
    this.desktop && this.element.nativeElement.classList.add('desktop');

    console.log('@@@ action', this.action);

    this.stateService.panelCloseTrigger$.subscribe(() => {
      if (!this.ref) return;

      getWindowRef()?.top?.postMessage({ message: 'ht_exp_finder_modal_close' }, '*');
      this.ref.destroy();
      this.ref = null;
    });
  }

}
