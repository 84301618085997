import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { ImageSvgExternalService } from '@hiptraveler/data-access/external';
import { AgentDetailsService } from './agent-details.service';
import { SocialMediaType, Type } from '../common';
import { SearchLocationData } from '@hiptraveler/common';

@Component({
  selector: 'agent-details',
  templateUrl: './agent-details.component.html',
  styleUrls: ['./agent-details.component.scss', './agent-details-responsiveness.scss'],
  viewProviders: [ AgentDetailsService ],
  encapsulation: ViewEncapsulation.None
})
export class AgentDetailsComponent implements OnInit{

  @Output() socialClicked = new EventEmitter<SocialMediaType>();
  
  @Input() type: Type;

  constructor(
    public service: AgentDetailsService,
    private imageSvg: ImageSvgExternalService
  ) { }

  ngOnInit(): void {
    this.service.formType = this.type;
    this.service.setInitialTypeData()
  }

  get profilePlaceholder(): string {
    return this.imageSvg.svgCollection?.['registration-form-agent-details']?.['profile-svg'] || '';
  }

  async locationChanges(locationData: SearchLocationData): Promise<void> {
    this.service.form.patchValue({ location: locationData?.location });
  }

}
