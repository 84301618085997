import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { NavigationDialogComponent as Component } from './navigation-dialog.component';

export const NAVIGATION_DIALOG_ID = 'itinerary-navigation-dialog-overlay-wrapper-id';

export interface TravelDataInput {
  travelMedium: string;
  itineraryId: string;
  day: number;
}

@Injectable()
export class NavigationDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(value: TravelDataInput): MatDialogRef<Component> | void {
    const dialog = this.dialog.getDialogById(NAVIGATION_DIALOG_ID);
    if (dialog) return dialog.close();
    return this.dialog.open<Component, TravelDataInput>(Component, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: NAVIGATION_DIALOG_ID,
      panelClass: NAVIGATION_DIALOG_ID.replace('-id', ''),
      // backdropClass: NAVIGATION_DIALOG_ID.replace('-id', '-backdrop'),
      width: '700px',
      maxWidth: 'auto',
      minWidth: 0,
      minHeight: 0,
      maxHeight: '100vh',
      data: value,
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });
  }

}
