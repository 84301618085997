import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map } from 'rxjs';

import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { ActivityDate } from '@hiptraveler/data-access/api';

@Pipe({
  name: 'validateId'
})
export class ValidateIdPipe implements PipeTransform {

  constructor(
    private store: Store
  ) { }

  /**
   * Validates the ID and returns an Observable with the result.
   * 
   * @param id The ID to validate.
   * @param day The day to check for the ID.
   * @returns An Observable that emits a boolean indicating whether the ID is valid.
   */
  transform(id?: string, day?: number): Observable<boolean> {
    return this.store.select(ItineraryState.actDateMap).pipe(
      map((activityDate: ActivityDate | null) => [
        ...activityDate?.[day || 0]?.HotelArray || [],
        ...activityDate?.[day || 0]?.ImgArray || []
      ].map(e => e.id).includes(id || ''))
    );
  }

}
