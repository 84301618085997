import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { SearchPageRootRoutingModule } from './search-page-root-routing.module';
import { SearchBannerActionsModule } from './search-banner-actions/search-banner-actions.module';
import { HomepageBannerModule } from './homepage-banner/homepage-banner.module';
import { FiltersModule } from './filters';
import { FiltersOverlayModule } from './filters-overlay/filters-overlay.module';
import { MapModule } from './map/map.module';
import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { SearchPageRootComponent } from './search-page-root.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const LIBRARIES = [
  SearchPageRootRoutingModule,
  SearchBannerActionsModule,
  HomepageBannerModule,
  FiltersModule,
  FiltersOverlayModule,
  MapModule,
  ButtonsModule
];

const COMPONENTS = [
  SearchPageRootComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
})
export class SearchPageRootModule { }
