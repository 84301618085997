import { Directive, Inject, NgModule, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Store } from '@ngxs/store';

import { UserState } from '@hiptraveler/data-access/user';
import { ChatCompletionsService } from '@hiptraveler/core/openai';
import { CarouselService, EFAStateServiceService, FORM_VALUE, getRequestPayload } from '../shared';
import { FormSubmitObserverService } from './form-submit-observer.service';
import { ScreenMainSelectionService } from './AI-chat-impl';

@Directive({
  selector: '[hostObserver]',
  providers: [ ChatCompletionsService, FormSubmitObserverService, ScreenMainSelectionService ]
})
export class HostObserverDirective implements OnInit, OnDestroy {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store,
    private stateService: EFAStateServiceService,
    private completions: ChatCompletionsService,
    private chatAI: ScreenMainSelectionService,
    private carousel: CarouselService,
    private formSubmitObserver: FormSubmitObserverService
  ) { }

  ngOnInit(): void {

    this.toggleOverScrollBehavior('add');
    this.stateService.summaryItems$$.next([]);

    this.completions.authInitialize();
    this.chatAI.observe();
    this.formSubmitObserver.observe();
    
    // Development Test Cases
    this.testObserver();
    this.devLoggers();
  }

  ngOnDestroy(): void {
    this.toggleOverScrollBehavior('remove');
    this.stateService.screenViewValue$$.next(null);
    this.stateService.formValue$$.next(FORM_VALUE);
  }

  private testObserver(): void {
    this.stateService.formValue$.subscribe((formValue) => {

      const payload = getRequestPayload({
        formValue,
        brandState: this.carousel.brandState,
        authenticated: this.store.selectSnapshot(UserState.authenticated)
      });

      console.log('@@@ formValue', JSON.stringify(payload, null, 2));
    });
  }

  // Development Test Cases

  private devLoggers(): void {

    this.stateService.formValue$.subscribe((res) => {
      console.log('@@@ state :: formValue', res);
    });

    this.stateService.screenViewValue$.subscribe((res) => {
      console.log('@@@ state :: screenViewValue', res);
    });

    this.stateService.summaryItems$.subscribe((res) => {
      console.log('@@@ state :: summaryItems', res);
    });
  }

  toggleOverScrollBehavior(option: 'add' | 'remove'): void {
    this.document.body.classList[option]('no-scroll');
  }

}

@NgModule({
  declarations: [ HostObserverDirective ],
  exports:      [ HostObserverDirective ]
})
export class HostObserverDirectiveModule { }
