
<button mat-icon-button (click)="updateCalendarDisplay('subtract')" [disabled]="disablePrevButton">
  <mat-icon>chevron_left</mat-icon>
</button>

<div class="calendar--actions">

  <button mat-icon-button (click)="updateCalendarDisplay('subtract')" [disabled]="disablePrevButton">
    <mat-icon>chevron_left</mat-icon>
  </button>

  <button mat-icon-button (click)="updateCalendarDisplay('add')">
    <mat-icon>chevron_right</mat-icon>
  </button>

</div>

<mat-calendar class="calendar--1" matCalendarStyles [minDate]="minDate" [selected]="selectedDateRange$$.asObservable() | async" (selectedChange)="onSelectedChange($event)" #calendar1></mat-calendar>

<mat-calendar class="calendar--2" matCalendarStyles [selected]="selectedDateRange$$.asObservable() | async" (selectedChange)="onSelectedChange($event)" #calendar2></mat-calendar>

<button mat-icon-button (click)="updateCalendarDisplay('add')">
  <mat-icon>chevron_right</mat-icon>
</button>
