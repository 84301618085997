import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { SocialMediaEventDirective } from './social-media-event.directive';
import { TranslateModule } from '@ngx-translate/core';

import { SocialMediaLinkComponent } from './social-media-link.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatRippleModule,
  MatInputModule,
  MatFormFieldModule,
  MatIconModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const COMPONENTS = [
  SocialMediaLinkComponent
];

const DIRECTIVES = [
  SocialMediaEventDirective
];


@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    SocialMediaLinkComponent
  ]
})
export class SocialMediaLinkModule { }
