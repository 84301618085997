import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { promiseDelay } from '@hiptraveler/common';
import { Subject, takeUntil } from 'rxjs';

import { EFAStateServiceService, ScreenView } from '../../shared';
import { CalendarService } from './calendar.service';

@Directive({
  selector: '[matCalendarStyles]'
})
export class MatCalendarStylesDirective implements AfterViewInit {

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private stateService: EFAStateServiceService,
    private service: CalendarService
  ) { }

  ngAfterViewInit(): void {
    this.stateService.screenViewValue$.subscribe((screen: ScreenView) => {
      if (screen === ScreenView.calendar) {
        this.initialize();
      } else {
        this.subscription$.next();
      }
    });
  }
  
  initialize(): void {

    this.updateCalendarMonthTitles();
  
    this.service.calendarMonths$.asObservable().pipe(
      takeUntil(this.subscription$)
    ).subscribe(async ({ left, right }) => {
      this.setCalendarMonth('.calendar--1', left);
      await promiseDelay(1);
      this.setCalendarMonth('.calendar--2', right);
    });
  }

  async updateCalendarMonthTitles(): Promise<void> {
    this.capitalizeCalendarMonth('.calendar--1');
    await promiseDelay(1);
    this.capitalizeCalendarMonth('.calendar--2');
  }

  private setCalendarMonth(calendarSelector: string, value: string): void {

    const spanEl = this.element.nativeElement.querySelector(
      `${calendarSelector} .mat-calendar-period-button .mat-button-wrapper > span`
    );
  
    if (spanEl?.textContent) {
      spanEl.textContent = value
    }
  }
  
  private capitalizeCalendarMonth(calendarSelector: string): void {

    const spanEl = this.element.nativeElement.querySelector(
      `${calendarSelector} .mat-calendar-period-button .mat-button-wrapper > span`
    );
  
    if (spanEl?.textContent) {
      spanEl.textContent = this.capitalizeFirstLetter(spanEl.textContent.toLowerCase());
    }
  }
  
  private capitalizeFirstLetter(text: string): string {
    return text.replace(/^\w/, c => c.toUpperCase());
  }

}
