import { Directive, HostListener, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { Store } from '@ngxs/store';

import { BrandState } from '@hiptraveler/data-access/brand';
import { UserState } from '@hiptraveler/data-access/user';
import { SnackbarService } from '@hiptraveler/snackbar';
import { currentLang, Environment, ENVIRONMENT, getWindowRef, isBrandIframeWidget, isIframe, isWidget } from '@hiptraveler/common';

@Directive({
  selector: '[selectionNavigation]'
})
export class SelectionNavigationDirective {

  @Input() selection?: 'open' | 'copy-link' | 'share' | 'delete';
  @Input() itinerary: string;

  constructor(
    @Inject(ENVIRONMENT) private env: Environment,
    private router: Router,
    private clipboard: Clipboard,
    private store: Store,
    private snackbar: SnackbarService
  ) { }

  @HostListener('click')
  hostClick(): void {
    switch (this.selection) {
      case 'copy-link': return this.openLink();
      case 'share':     return this.share();
      case 'delete':    return this.delete();
      default:                 this.open();
    }
  }

  get itineraryPageUrl(): string {
    return this.store.selectSnapshot(UserState.itineraries)?.find(e => e.id === this.itinerary)?.pageTitle || '-';
  }

  open(): void {
    if (isWidget() && isIframe()) {
      getWindowRef().location.href = `${this.env.baseUrl}/${currentLang()}/itinerary/${this.itineraryPageUrl}`;
    } else if (isBrandIframeWidget()) {
      const iframeLink = this.store.selectSnapshot(BrandState.brandCampaign)?.iframeLink;
      getWindowRef().location.href = `${iframeLink}#/${currentLang()}/itinerary/${this.itineraryPageUrl}`;
    } else {
      this.router.navigate([ currentLang(), 'itinerary', this.itineraryPageUrl ]);
    }
  }

  openLink(): void {
    let link = ''
    if (isWidget() && isIframe()) {
      link = `${this.env.baseUrl}/${currentLang()}/itinerary/${this.itineraryPageUrl}`;
    } else if (isBrandIframeWidget()) {
      const iframeLink = this.store.selectSnapshot(BrandState.brandCampaign)?.iframeLink;
      link = `${iframeLink}#/${currentLang()}/itinerary/${this.itineraryPageUrl}`;
    } else {
      link = `${currentLang()}/itinerary/${this.itineraryPageUrl}`;
    }
    this.clipboard.copy(link);
    this.snackbar.open({ message: 'Link copied to clipboard!', duration: 5000 });
  }

  share(): void {
    if (isWidget() && isIframe()) {
    } else if (isBrandIframeWidget()) {
    } else {
    }
  }

  delete(): void {
    if (isWidget() && isIframe()) {
    } else if (isBrandIframeWidget()) {
    } else {
    }
  }


}
