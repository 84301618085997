import { Component, ElementRef, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { InputControlService } from './input-control.service';
import { EFAStateServiceService, ScreenView } from '../../shared';
import { SnackbarService } from '@hiptraveler/snackbar';
import { opacityFade } from '@hiptraveler/animations';
import { chipListAnimation } from './input-control.animations';

@Component({
  selector: 'input-control',
  templateUrl: './input-control.component.html',
  styleUrls: ['./styles/input-control.component.scss'],
  host: { class: 'experience-finder-ai--host-input-control disabled' },
  viewProviders: [ InputControlService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade(150), chipListAnimation ]
})
export class InputControlComponent implements AfterViewInit {

  @ViewChild('inputField') inputField: ElementRef<HTMLInputElement>;

  contentInput: string = '';
  typingTimer: any;
  allowPreviewNavigation: boolean = false;

  constructor(
    private snackbar: SnackbarService,
    public service: InputControlService,
    public stateService: EFAStateServiceService
  ) { }

  ngAfterViewInit(): void {
    this.service.observeRequest();
    this.service.screenChangesObserver();
    this.allowPreviewNavigation = false;
    setTimeout(() => (this.allowPreviewNavigation = true), 1000);
  }

  onInput(): void {
    this.stateService.pauseBrandInfoUXState$$.next(false);
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => this.stateService.pauseBrandInfoUXState$$.next(true), 1200);
  }

  sendMessage(): void {

    if (!this.contentInput || this.stateService.inputPending$$.value) return;

    this.stateService.scrollToView$$.next('next');
    this.stateService.inputMessage$$.next(this.contentInput);
    this.resetInputState();
  }

  submitMessage(): void {

    if (this.stateService.inputPending$$.value) return;

    if (!this.stateService.formValue.carouselData[ScreenView.locationCarousel].length) {
      this.snackbar.open({ message: 'Location is required. Please select a location before submitting.', duration: 5000 });
      return;
    }

    this.stateService.formSubmit$.next();
    this.stateService.formSubmitCompleted$.asObservable().subscribe(() => {
      this.resetInputState();
      this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
    });
  }

  async nextScreen(): Promise<void> {
    const isMobile = await firstValueFrom(this.stateService.isMobile$);
    const screenViewValue = this.stateService.screenViewValue$$.value || ScreenView.mainSelection;
    if ([ ScreenView.mainSelection, ScreenView.summary ].includes(screenViewValue) || isMobile) return;
    this.stateService.scrollToView$$.next('next');
  }

  resetInputState(): void {
    this.contentInput = '';
    this.inputField.nativeElement.value = this.contentInput;
    this.stateService.inputMessage$$.next(this.contentInput);
  }

}
