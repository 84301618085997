import { Component, AfterViewInit, ViewEncapsulation, ElementRef, OnDestroy } from '@angular/core';
import { filter, map, Observable, Subject, takeUntil, tap } from 'rxjs';

import { EFAStateServiceService, ScreenView } from '../../shared';

@Component({
  selector: 'brand-header',
  template: `
    <brand-logo-pulse size="90" [paused]="!(pulsing$ | async)" (click)="navigateToMainMenu()"></brand-logo-pulse>
    <div id="brand-header-highlight" class="brand-header--highlight" textTyped></div>
    <div class="brand-header--background"></div>
    <button mat-icon-button class="host-close-panel" (click)="stateService.panelCloseTrigger$$.next()">
      <mat-icon>close</mat-icon>
    </button>
  `,
  styleUrls: ['./brand-header.component.scss'],
  host: { class: 'experience-finder-ai--host-brand-header' },
  encapsulation: ViewEncapsulation.None
})
export class BrandHeaderComponent implements AfterViewInit, OnDestroy {

  brandLogoState: boolean = false;
  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    public stateService: EFAStateServiceService
  ) { }

  get pulsing$(): Observable<boolean> {
    return this.stateService.inputPending$.pipe(
      map((pending: boolean) => {
        return pending || this.brandLogoState;
      })
    );
  }

  ngAfterViewInit(): void {
    this.brandLogoStateObserver();
    this.screenViewChangesObserver();
    this.stateService.isMobile
      && this.updateBrandStylesState();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  brandLogoStateObserver(): void {
    
    this.stateService.pauseBrandInfoUXState$$.asObservable().subscribe((state: boolean) => {
      this.brandLogoState = !state;
    });
    
    setTimeout(() => {
      const brandHeaderHighlight = this.element.nativeElement.querySelector('.ui-brand-logo-pulse');
      brandHeaderHighlight?.classList.add('brand-logo-active');
      this.stateService.brandHeaderTypeState$$.next(true);
    });
  }

  screenViewChangesObserver(): void {
    this.stateService.screenViewValue$.pipe(
      filter(() => this.stateService.isMobile),
      tap(screen => this.updateBrandStylesState(screen)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  updateBrandStylesState(screen: ScreenView = this.stateService.screenViewValue): void {
    const brandHeaderHighlight = this.element.nativeElement.querySelector('.ui-brand-logo-pulse');
    const brandHeaderHighlightHost = brandHeaderHighlight?.parentElement as HTMLElement;

    if (screen === ScreenView.brandMainSelection) {
      brandHeaderHighlightHost.classList.add('hide');
    } else {
      brandHeaderHighlightHost.classList.remove('hide');
    }
  }

  navigateToMainMenu(): void {
    if (this.stateService.screenViewValue === ScreenView.mainSelection) return;

    const screenValue = this.stateService.view[ScreenView.mainSelection];
    this.stateService.scrollToScreen$$.next(screenValue);
  }

}
