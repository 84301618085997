import { compact, isEqual, keyBy, map, mapValues, merge, omitBy, uniqWith } from 'lodash';

import { ActivityDate, ActivityDateData } from "@hiptraveler/data-access/api";

export function parseActDate(actDateMap?: ActivityDate | null): ActivityDateData[] | null {

  if (!actDateMap) return null;

  // Remove itineraries that are marked as multi-day tours.
  const singleDayItineraries = omitBy(actDateMap, { hasMultiDayTour: true });

  // Extract unique multi-day tour lists and convert each tour's activities to just its first and last elements.
  const multiDayTourEdges = map(
    uniqWith(
      compact(
        Object.values(actDateMap).flatMap((itinerary: any) => itinerary.multiDayTourList)
      ),
      isEqual
    ),
    (tour: any) => mapValues(tour, (activities: number[]) => [activities[0], activities[activities.length - 1]])
  );

  // For each multi-day tour edge, retrieve the itinerary corresponding to the first activity day.
  const multiDayItinerariesByStartDay = keyBy(
    map(multiDayTourEdges, (edge: number[][]) => actDateMap[Object.values(edge)[0][0]]) as any,
    'day'
  );

  // Merge the single-day itineraries with those multi-day itineraries.
  const mergedItineraries = merge({}, singleDayItineraries, multiDayItinerariesByStartDay);
  
  // Sort the merged itineraries by day (numerically) and output as an array.
  const sortedItineraryArray = Object.keys(mergedItineraries)
    .sort((a, b) => Number(a) - Number(b))
    .map(key => mergedItineraries[key]);

  // For itineraries with multi-day tours, replace multiDayTourList with the corresponding tour edge.
  const finalItineraryArray = sortedItineraryArray.map((itinerary: any) => {
    const matchingEdge = multiDayTourEdges.find((edge: any) => (Object.values(edge)[0] as any)[0] === itinerary.day);
    return matchingEdge ? { ...itinerary, multiDayTourList: matchingEdge } : itinerary;
  });

  console.log('@@@ actDateMap', actDateMap);
  console.log('@@@ singleDayItineraries', singleDayItineraries);
  console.log('@@@ multiDayTourEdges', multiDayTourEdges);
  console.log('@@@ multiDayItinerariesByStartDay', multiDayItinerariesByStartDay);
  console.log('@@@ mergedItineraries', mergedItineraries);
  console.log('@@@ sortedItineraryArray', sortedItineraryArray);
  console.log('@@@ finalItineraryArray', finalItineraryArray);

  return finalItineraryArray;
}
