import { Directive, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Location } from '@angular/common';

import { AppListenerService, currentLang, getLocationData, SUPPORTED_LANG } from '@hiptraveler/common';

@Directive({
  selector: '[appI18nSetup]'
})
export class I18nSetupDirective implements OnInit {

  constructor(
    private location: Location,
    private router: Router,
    private translateService: TranslateService,
    private appListener: AppListenerService
  ) { }

  ngOnInit(): void {
    this.initializeLanguage();
    this.handleLanguageChange();
  }

  /**
   * Initializes the language for the application.
   *
   * It sets the default language to English, chooses the active language based on the URL
   * (if a supported language is present) or defaults to 'en', and navigates to a proper URL
   * if necessary.
   */
  private initializeLanguage(): void {
    const urlContainsSupportedLang = SUPPORTED_LANG.includes(currentLang());
    const activeLanguage = urlContainsSupportedLang ? currentLang() : 'en';

    this.translateService.setDefaultLang('en');
    this.translateService.use(activeLanguage);

    if (!urlContainsSupportedLang) {
      const { pathname } = getLocationData();
      this.router.navigate([`/${activeLanguage}${pathname}`]);
    }
  }

  /**
   * Handles language change events from the translation service.
   *
   * When the language changes, it updates the URL with the new language segment and notifies
   * the application that the language file has loaded.
   *
   * @param event - The language change event.
   */
  private handleLanguageChange(): void {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      const newLanguage = event.lang;
      if (currentLang() !== newLanguage) {
        const { pathname, search } = getLocationData();
        const updatedPathname = pathname.replace(`/${currentLang()}/`, `/${newLanguage}/`);
        this.location.replaceState(`${updatedPathname}${search}`);
      }
      this.appListener.languageLoadState$$.next(true);
    });
  }
}
