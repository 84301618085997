import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

import { CarouselFilterType, EFAStateServiceService } from '../../../shared';

@Directive({
  selector: '[selectedFilter]'
})
export class SelectedFilterDirective implements AfterViewInit {

  @Input() selectedFilter: CarouselFilterType;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private stateService: EFAStateServiceService
  ) { }

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {

    const itemSelectionScroll = this.element.querySelectorAll('.poi-select-carousel--item-selection').item(0) as HTMLElement;

    itemSelectionScroll.addEventListener('scroll', () => {
      this.stateService.carouselHighlight$$.next(
        this.selectedFilter === 'experience' ? 'Experiences'
        : this.selectedFilter === 'travelStyle' ? 'Style'
        : this.selectedFilter === 'activity' ? 'Activities' : 'Experiences'
      );
    });
  }

}
