import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, firstValueFrom, map, takeUntil, tap } from 'rxjs';

import { SnackbarService } from '@hiptraveler/snackbar';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserAction, UserState } from '@hiptraveler/data-access/user';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { AppListenerService, SearchPageControlStateService, clientVID, pendingAuthProcessKey } from '@hiptraveler/common';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';

@Injectable()
export class UserProfileService implements OnDestroy {

  #processing$$ = new BehaviorSubject<boolean>(false);
  processing$ = this.#processing$$.asObservable();

  #buttonVisibility$$ = new BehaviorSubject<boolean>(true);
  buttonVisibility$ = this.#buttonVisibility$$.asObservable();

  subscription$ = new Subject<void>();  
  authHandle$ = new Subject<void>();

  constructor(
    private store: Store,
    private appListener: AppListenerService,
    private authDialog: AuthDialogActionService,
    private searchPageControl: SearchPageControlStateService,
    private snackbar: SnackbarService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
    this.resetAuthHandle();
  }

  get visibility(): boolean {
    return !this.store.selectSnapshot(UserState.authenticated)
      && !!this.store.selectSnapshot(ItineraryState.basicInfo)
      && !!this.searchPageControl.activityDate$$.value;
  }

  /**
   * Returns "true" if the authenticated user is already following the author.
   * 
   * @returns {Observable<boolean>}
   */
  get following$(): Observable<boolean> {
    return this.store.select(UserState.following).pipe(
      map((following: string[] | null) => following || []),
      map((following: string[]) => {
        const authorId = this.store.selectSnapshot(ItineraryState.basicInfo)?.author?.authorProfId || '';
        return following.includes(authorId);
      })
    )
  }

  /**
   * Button visibility observer function observing changes in authentication and user states 
   * to adjust the button's user experience (UX) state.
   */
  buttonVisibilityObserver(): void {

    this.store.select(AuthState.authenticated).pipe(
      tap((state: boolean) => this.#buttonVisibility$$.next(!state)),
      takeUntil(this.subscription$)
    ).subscribe();

    this.store.select(UserState.authenticated).pipe(
      tap((state: boolean) => state && this.#buttonVisibility$$.next(true)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Follow profile by itinerary basic info data
   */
  async followProfile(): Promise<void> {

    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'userProfileFollowProfile';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(this.followProfile.bind(this));
      return;
    }

    const following = this.store.selectSnapshot(UserState.following) || [];
    const basicInfo = this.store.selectSnapshot(ItineraryState.basicInfo);
    const author = basicInfo?.author?.authorName || basicInfo?.title || basicInfo?.id || '-';
    const authorId = basicInfo?.author?.authorProfId || '';

    if (this.#processing$$.value || !basicInfo) {
      this.snackbar.open({ message: 'Something went wrong. If the issue persists, reach out to support@hiptraveler.com for assistance.', duration: 5000 });
      return;
    }

    if (following.includes(authorId)) {
      this.snackbar.open({ message: `You are already following ${author}`, duration: 5000  });
      return;
    }

    try {
      this.#processing$$.next(true);
      await firstValueFrom(this.store.dispatch(new UserAction.FollowProfileByUserId({
        followUserId: authorId,
        vId: clientVID()
      })));
    } finally {
      this.#processing$$.next(false);
    }
  }

  private resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

}
