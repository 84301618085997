import { Directive, ElementRef, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, delay, filter, take, takeUntil, tap } from 'rxjs';

import { currentLang, getPathname } from '@hiptraveler/common';
import { BrandState } from '@hiptraveler/data-access/brand';

@Directive({
  selector: '[optionHighlight]'
})
export class OptionHighlightDirective implements OnDestroy, AfterViewInit {

  subscription$ = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    private element: ElementRef<HTMLDivElement>,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store
  ) { }
  
  ngAfterViewInit(): void {
    this.updateActiveNavigationItems();
    this.routeNavigationEndObserver();
    this.fragmentChangesObserver();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  routeNavigationEndObserver(): void {

    this.highlightRootPage();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      delay(0),
      tap(this.updateActiveNavigationItems.bind(this)),
      // takeUntil(this.subscription$)
    ).subscribe();
  }

  highlightRootPage(): void {

    this.updateActiveNavigationItems({ url: this.router.url });

    if (this.router.url === `/${currentLang()}`) {
      const element = this.element.nativeElement.children.item(0);
      element?.classList.add('active');
    }
  }

  /* Router attribute observers */

  updateActiveNavigationItems(event?: any): void {
    const hasFragment = !!this.route.snapshot.fragment;
    const hasComponentClass = this.element.nativeElement.className.includes('component');
  
    if (hasFragment || hasComponentClass) return;
  
    this.store.select(BrandState.brandCampaign).pipe(delay(50), take(1)).subscribe(() => {
      [ ...Array.from(this.element.nativeElement.children) as HTMLDivElement[] ].forEach((child: HTMLDivElement) => {
        if (event?.url === `/${currentLang()}` || event?.url?.startsWith(`/${currentLang()}?`)) {
          event.url = `/${currentLang()}/search`;
        }
        const urlFragment = getPathname(event?.url)?.replace(`/${currentLang()}/`, '');
        const isActive = urlFragment?.split('/')?.[0] === child.getAttribute('data-url');
        this.renderer.removeClass(child, 'active');
        isActive && this.renderer.addClass(child, 'active');
      });
    });
  }

  /* Fragment observers */

  fragmentChangesObserver(): void {
    this.route.fragment.pipe(
      delay(0),
      tap(this.addRemoveActiveClassByFragment.bind(this)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  addRemoveActiveClassByFragment(fragment: string | null): void {

    if (!this.element.nativeElement.classList.contains('scroll-to-view')) return;

    const children = Array.from(this.element.nativeElement.children);
    children.forEach((element) => {
      this.renderer.removeClass(element, 'active');
    });

    if (!fragment) {
      setTimeout(() => this.renderer.addClass(children[0], 'active'), 100);
      return;
    }

    const activeElement = children.find(e => e.getAttribute('fragment') === fragment);
    activeElement && this.renderer.addClass(activeElement, 'active')
  }

}
