import { Component, Inject, Input, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';

import { CarouselFilterType, CarouselService, EFAStateServiceService } from '../../shared';
import { getScreen } from './get-screen';

@Component({
  selector: 'poi-select-carousel',
  templateUrl: './poi-select-carousel.component.html',
  styleUrls: ['./poi-select-carousel.component.scss', './poi-select-carousel-responsiveness.scss'],
  host: { class: 'experience-finder-ai--component-poi-select-carousel' },
  viewProviders: [ CarouselService ],
  encapsulation: ViewEncapsulation.None
})
export class PoiSelectCarouselComponent {

  @Input() selectedFilter: CarouselFilterType;
  @Input() mobile?: string;
  @Input() singleSelect?: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    public stateService: EFAStateServiceService
  ) { }

  get selectedContainerId(): string {
    const filterSuffix = this.selectedFilter ? `-${this.selectedFilter}` : '';
    return `item-selection${filterSuffix}`;
  }

  addValueToSelection(value: string): void {
    const screen = getScreen(this.selectedFilter);
    const selectedFilters = this.stateService.formValue.carouselData[screen];
    const updatedFilters = selectedFilters.includes(value)
      ? selectedFilters.filter(x => x !== value)
      : [ ...selectedFilters, value ];
    this.stateService.patchCarouselItems({ [screen]: this.singleSelect === '' ? [ value ] : updatedFilters });
  }

  navigateToLeft(): void {
    if (isPlatformServer(this.platformId)) return;
    const element = this.document.getElementById(`${this.selectedContainerId}`);
    if (!element) return;
    const OFFSET = 528;
    element.scrollTo({
      left: element.scrollLeft - OFFSET, behavior: 'smooth',
    });
  }
  
  navigateToRight(): void {
    if (isPlatformServer(this.platformId)) return;
    const element = this.document.getElementById(`${this.selectedContainerId}`);
    if (!element) return;
    const OFFSET = 528;
    const multiplier = element.scrollLeft/OFFSET;
    element.scrollTo({
      left: OFFSET * (multiplier+1), behavior: 'smooth',
    });
  }

}
