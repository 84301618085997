import { Directive, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs';

import { ActivityInputOverlayActionService } from './activity-input-overlay-action.service';
import { FiltersStateService, ViewService } from '../../common';
import { ApplyActionDirective, ClearActionDirective } from '../../utils';

@Directive({
  selector: '[activityInputOverlay]',
  providers: [ ActivityInputOverlayActionService, ApplyActionDirective, ClearActionDirective ]
})
export class ActivityInputOverlayDirective {

  constructor(
    private filtersService: FiltersStateService,
    private viewService: ViewService,
    private overlayService: ActivityInputOverlayActionService,
    private applyAction: ApplyActionDirective,
    private clearACtion: ClearActionDirective
  ) { }

  @HostListener('click')
  async hostClicked(): Promise<void> {
    const listItems = await this.viewService.listItems;
    const ref = this.overlayService.open(listItems.data, this.filtersService);

    if (!(ref instanceof MatDialogRef)) return;

    ref.afterClosed().pipe(filter(Boolean)).subscribe((response: number) => {
      response === 1 && this.applyAction.hostClicked();
      response === 2 && this.clearACtion.hostClicked();
    });      
  }

}
