export * from './view-constants.model';
export * from './view.service';
export * from './data-access.service';
export * from './filters-state.service';
export * from './filters-state-listener.service';
export * from './filter-clear.service';

export * from '../form-styles.directive';
export * from '../form-location.directive';

import { ViewService } from './view.service';
import { DataAccessService } from './data-access.service';
import { FiltersStateService } from './filters-state.service';
import { FiltersStateListenerService } from './filters-state-listener.service';
import { FilterClearService } from './filter-clear.service';
import { AdventuresDataService, HotelsDataService, FoodsDataService, ItinerariesDataService, ProfileListService } from './../../data-services';
import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';

export const FiltersProviders = [
  ViewService,
  DataAccessService,
  FiltersStateService,
  FiltersStateListenerService,
  FilterClearService
];

export const DataAccessProviders = [
  AdventuresDataService,
  HotelsDataService,
  FoodsDataService,
  ItinerariesDataService,
  ProfileListService,
  SearchResultDialogActionService
];
