import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { catchError, EMPTY, tap } from 'rxjs';

import { FlightRoute, ItineraryApiService } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { TravelDataInput } from './navigation-dialog-action.service';
import { getWindowRef } from '@hiptraveler/common';

@Injectable()
export class NavigationDialogService {

  constructor(
    private store: Store,
    private itineraryApi: ItineraryApiService
  ) { }

  get originIpLookupFirstDate(): boolean {
    return !this.store.selectSnapshot(ItineraryState.actDateMap)?.[1]?.dayLocMap?.transit
  }

  requestTravelRoute({ day, itineraryId }: TravelDataInput, callback: (route: FlightRoute | null) => void): void {
    itineraryId && this.itineraryApi.getItineraryRoute({ day, itineraryId }).pipe(
      tap(callback.bind(this)),
      catchError(() => {
        callback(null)
        return EMPTY;
      })
    ).subscribe();
  }

  openTransitGoogleMapDirections(data: TravelDataInput): void {

    const activities = this.store.selectSnapshot(ItineraryState.actDateMap);
    const originIndex = data.day - 1;
    const origin = activities?.[data.day - 1]?.dayLocMap;
    const destination = activities?.[data.day]?.dayLocMap;

    if (!activities || originIndex < 1 || !origin || !destination) {
      return;
    }

    const { latitude: from_lat, longitude: from_lng } = origin;
    const { latitude: to_lat, longitude: to_lng } = destination;

    const googleMapsUrl = `https://www.google.com/maps/dir/${from_lat},${from_lng}/${to_lat},${to_lng}`;
    getWindowRef()?.open(googleMapsUrl, '_break');
  }

}
