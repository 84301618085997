import { chain, cloneDeep, compact, concat, difference, flatMap, get } from 'lodash';
import { DivIcon } from 'leaflet';

import { ActivityDateData } from "@hiptraveler/data-access/api";
import { SearchResultData } from "@hiptraveler/common";
import { getTypeByItinerary, MarkerIconRecord } from '../utils';

export function iconByType(itinerary: any, day: number, markerIcon: MarkerIconRecord): DivIcon {

  const resultType = getTypeByItinerary(itinerary);

  if ([ 'hotel', 'adventure', 'food' ].includes(resultType)) {
    return (markerIcon as any)[`${resultType}MarkerIcon_colored${day}`];
  }
  
  return (markerIcon as any)[`adventureMarkerIcon_colored${day}`];
}

export function filterValidSearchResults(results: SearchResultData[]): SearchResultData[] {
  return results
    .filter(result => result?.location?.coordinates)
    .filter(result => result.location.coordinates?.[0] !== 0 && result.location.coordinates?.[1] !== 0);
}

export function getUnaddedSearchResults(results: SearchResultData[], activities: ActivityDateData[]) {
  const poiIds = compact(flatMap(activities, e => [...(e?.HotelArray ?? []), ...(e?.ImgArray ?? [])].map(e => e.id)));
  const filteredIds = difference(compact(results.map(e => e.id)), poiIds);
  const resultsMap = new Map(results.map(item => [item.id, item]));
  const filteredResults = filteredIds.map(id => resultsMap.get(id)).filter(Boolean) as SearchResultData[];
  
  return {
    hotels: filteredResults.filter(r => r.type === 'hotel'),
    adventures: filteredResults.filter(r => r.type === 'adventure'),
    foods: filteredResults.filter(r => r.type === 'food')
  };
}

export function extractValidMapCoordinates(results: SearchResultData[], activities: ActivityDateData[]): number[][] {

  const validSearchResults = chain(cloneDeep(results))
    .filter(item => get(item, 'location.coordinates.length', 0) === 2)
    .map('location.coordinates')
    .value();

  const validActivityData = chain(cloneDeep(activities))
    .flatMap(activity => [...(activity?.HotelArray ?? []), ...(activity?.ImgArray ?? [])])
    .filter(item => !item?.name?.toLowerCase().includes('chill'))
    .map(item => item?.loc?.coordinates)
    .value();

  return cloneDeep(concat(validSearchResults, validActivityData).map(e => e.reverse()));
}
