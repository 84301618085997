import { Component, ViewEncapsulation } from '@angular/core';

import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { opacityFadeIn } from '@hiptraveler/animations';
import { CarouselService, EFAStateServiceService } from '../../shared';

@Component({
  selector: 'main-selection',
  templateUrl: './main-selection.component.html',
  styleUrls: ['./main-selection.component.scss', './main-selection-responsiveness.scss'],
  host: { class: 'experience-finder-ai--host-main-selection experience-finder-ai--host-element' },
  viewProviders: [ AuthDialogActionService, CarouselService ],
  animations: [ opacityFadeIn(2000) ],
  encapsulation: ViewEncapsulation.None
})
export class MainSelectionComponent {

  constructor(
    public stateService: EFAStateServiceService
  ) { }

}
