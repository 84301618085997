import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ActivityDateDayTimeData, ActivityDateDayTimeMedium } from '@hiptraveler/data-access/api';
import { TranslationService } from '@hiptraveler/common';

type Duration = {
  display: string;
  medium?: string;
  icon?: ActivityDateDayTimeMedium;
} | null;

/**
 * Converts a time string in 24-hour format to 12-hour format with AM/PM.
 *
 * @param timeString - The time string in 24-hour format (e.g., "14:30").
 * @returns The time string in 12-hour format (e.g., "2:30 PM").
 */
function convertTo12HourFormat(timeString: string): string {
  const [hour, minute] = timeString.split(":");
  let period = "am";
  let formattedHour = parseInt(hour, 10);
  if (formattedHour >= 12) {
    period = "pm";
    formattedHour = formattedHour === 12 ? formattedHour : formattedHour - 12;
  }
  return `${formattedHour}:${minute}`;
}

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  value$$ = new BehaviorSubject<Duration | null>(null);

  constructor(
    private translate: TranslateService,
    private translation: TranslationService
  ) { }

  /**
   * Transforms the duration data based on the activity type.
   *
   * @param id - The ID of the activity.
   * @param dayTimeActivity - An array of ActivityDateDayTimeData objects.
   * @param type - The type of activity ('commute', 'restaurant', or 'activity').
   * @returns A Duration object with the formatted display string and medium (optional).
   */
  transform(id: string | undefined, dayTimeActivity: ActivityDateDayTimeData[], type: 'commute' | 'restaurant' | 'activity'): Observable<Duration | null> {

    if (!id || !dayTimeActivity) return this.value$$.asObservable();

    let activity = dayTimeActivity.find(
      (activity: ActivityDateDayTimeData) => activity.objId === id && activity.activityType === type
    );

    if (activity?.activityType === 'commute' && activity?.viatorTour) {
      const selection = activity?.hotelPickup ? 'hotel-pickup-available' : 'viator-meeting-point';
      this.value$$.next({
        display: this.translation.getText('itinerary', selection),
        icon: selection
      });
      this.translate.onLangChange.asObservable().subscribe((event: LangChangeEvent) => {
        this.value$$.next({
          display: event.translations?.['itinerary']?.[selection],
          icon: selection
        });
      });
      return this.value$$.asObservable();
    }
    
    if (activity?.activityType === 'commute') {
      const commuteText = this.translation.getText('itinerary', activity?.medium || 'drive');
      this.value$$.next({
        display: activity?.duration_string ? `${commuteText} ${activity?.duration_string}` : '',
        medium: commuteText.toLocaleLowerCase()!,
        icon: activity?.medium || 'drive'
      });
      this.translate.onLangChange.asObservable().subscribe((event: LangChangeEvent) => {
        const commuteText = event.translations?.['itinerary']?.[activity?.medium || ''] || 'drive';
        this.value$$.next({
          display: activity?.duration_string ? `${commuteText} ${activity?.duration_string}` : '',
          medium: commuteText.toLocaleLowerCase()!,
          icon: activity?.medium || 'drive'
        });
      });
      return this.value$$.asObservable();
    }

    if (activity?.activityType === 'activity' && activity?.startTime && activity?.endTime) {
      this.value$$.next({
        display: `<span>${convertTo12HourFormat(activity?.startTime)}</span><br><span>${convertTo12HourFormat(activity?.endTime)}</span>`
      });
      return this.value$$.asObservable();
    }

    this.value$$.next({
      display: activity?.duration_string || ''
    });

    return this.value$$.asObservable();
  }

}

@NgModule({
  declarations: [ DurationPipe ],
  exports:      [ DurationPipe ]
})
export class DurationPipeModule { }
