import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

import { SnackbarConfig } from './snackbar.interface';
import { buildSnackbarConfig, translateMessage } from './snackbar-fn';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private readonly snackbar: MatSnackBar
  ) { }

  /**
   * Opens a snackbar with the provided configuration.
   * If a delay is specified, the snackbar is opened after the delay using setTimeout.
   *
   * @param config - Snackbar configuration options (e.g., message, duration, delay).
   * @param action - Optional action label (defaults to "X").
   * @returns A reference to the opened snackbar or a NodeJS.Timeout if delay is used.
   */
  open(config: SnackbarConfig, action?: string): MatSnackBarRef<TextOnlySnackBar> | NodeJS.Timeout {

    const openFn = () => this.snackbar.open(
      translateMessage(config.message),
      action ?? 'X',
      buildSnackbarConfig(config)
    );

    return config.delay ? setTimeout(openFn, config.delay) : openFn();
  }

  /**
   * Dismisses any currently displayed snackbar.
   */
  dismiss(): void {
    this.snackbar.dismiss();
  }

}
