import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { isPlatformServer, Location } from '@angular/common';
import { delay, filter, tap } from 'rxjs';

import { AppListenerService, currentLang, Environment, ENVIRONMENT, getLocationData, getWindowRef, removeHashes, SUPPORTED_LANG, SupportedLanguage } from '@hiptraveler/common';

@Injectable()
export class NavigationObserverService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(ENVIRONMENT) private environment: Environment,
    private router: Router,
    private location: Location,
    private appListener: AppListenerService
  ) {}

  observe(): void {
    this.redirectIfUnsupportedHomepage();
    this.redirectIfInsecure();
    this.initializeNavigationFlag();
    this.cleanHashInUrl();
    this.scrollToTopWhenNoFragment();
  }

  /**
   * Monitors navigation events to detect invalid or unsupported homepage URLs.
   * If a user lands on a root-level path that isn't recognized as a supported language,
   * this method automatically redirects them to the current language homepage.
   */
  private redirectIfUnsupportedHomepage(): void {
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      tap((navigationEndEvent: NavigationEnd) => {
        const isSupportedLanguage = SUPPORTED_LANG.includes(
          navigationEndEvent.url.slice(1) as SupportedLanguage
        );

        if (navigationEndEvent.url.split('/').length === 2 && !isSupportedLanguage) {
          this.router.navigateByUrl(`/${currentLang()}`);
        }
      })
    ).subscribe();
  }

  /**
   * Redirects to the secure (HTTPS) version if the current protocol is HTTP
   * and the environment is not local.
   */
  private redirectIfInsecure(): void {
    const windowRef = getWindowRef();
    if (windowRef && windowRef.location.protocol === 'http:' && !this.environment.local) {
      const secureUrl =
        `//${this.environment.build !== 'Production' ? 'dev.' : ''}hiptraveler.com` +
        windowRef.location.pathname +
        windowRef.location.search +
        windowRef.location.hash;
      windowRef.location.replace(secureUrl);
    }
  }

  /**
   * Sets a global flag indicating that an imperative navigation was triggered.
   */
  private initializeNavigationFlag(): void {
    this.router.events.pipe(
      filter((event: Event): event is NavigationStart => event instanceof NavigationStart),
      filter((event: NavigationStart) => event.navigationTrigger === 'imperative'),
      tap(() => this.appListener.navigated$$.next(true))
    ).subscribe();
  }

  /**
   * Cleans the URL by removing any trailing hash symbols and updates the router and location states.
   */
  private cleanHashInUrl(): void {
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      filter(event => event.url.endsWith('#')),
      tap(() => {
        const { pathname, locationString } = getLocationData();
        const cleanPath = removeHashes(pathname);
        const cleanUrl = removeHashes(locationString);
        this.router.navigateByUrl(cleanPath);
        this.location.replaceState(cleanUrl);
      })
    ).subscribe();
  }

  /**
   * Scrolls the window to the top after navigation completes, if no URL fragment is present.
   */
  private scrollToTopWhenNoFragment(): void {
    if (isPlatformServer(this.platformId)) return;
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      filter(navEnd => !navEnd.url.includes('#')),
      delay(300),
      tap(() => getWindowRef()?.scrollTo({ top: 0 }))
    ).subscribe();
  }

}
