import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SummaryTagsModule } from './summary-tags/summary-tags.module';

import { InputControlComponent } from './input-control.component';
import { AnimateSendButtonDirective } from './animate-send-button.directive';
import { DynamicHeightDirective } from './dynamic-height.directive';
import { InputControlStyleDynamicDirective } from './input-control-style-dynamic.directive';

const MODULES = [
  CommonModule,
  FormsModule,
  MatRippleModule,
  MatIconModule,
  MatProgressSpinnerModule
];

const LIBRARIES = [
  SummaryTagsModule
];

const COMPONENTS = [
  InputControlComponent
];

const DIRECTIVES = [
  AnimateSendButtonDirective,
  DynamicHeightDirective,
  InputControlStyleDynamicDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    InputControlComponent
  ]
})
export class InputControlModule { }
