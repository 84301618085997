import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { SkeletonUiComponent } from './skeleton-ui.component';

const MODULES = [
  CommonModule,
  MatDividerModule,
  MatIconModule,
  MatButtonModule,
  LazyLoadImageModule,
  NgxSkeletonLoaderModule
];

const COMPONENTS = [
  SkeletonUiComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    SkeletonUiComponent
  ]
})
export class SkeletonUiModule { }
