import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { StepperComponent } from './stepper.component';

const MODULES = [
  CommonModule,
  MatIconModule
];

const COMPONENTS = [
  StepperComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    StepperComponent
  ]
})
export class StepperModule { }
