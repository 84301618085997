import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarouselGridComponent } from './carousel-grid.component';
import { SelectedFilterDirective } from './selected-filter.directive';

import { PoiSelectCarouselModule } from '../../../components/poi-select-carousel';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  PoiSelectCarouselModule
];

const COMPONENTS = [
  CarouselGridComponent
];

const DIRECTIVES = [
  SelectedFilterDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    CarouselGridComponent
  ]
})
export class CarouselGridModule { }
