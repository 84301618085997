<div class="social-handles mat-elevation-z2" *ngIf="service.response$ | async as response">

  <h2>Creator House: Social Media Stats</h2>
  
  <div class="social-handles--container">

    <div class="social-handles--row social-handles--row-header">
      <p style="border-top-left-radius: 4.5px;">Social Channels</p>
      <p>Likes</p>
      <p>Followers</p>
      <p>Handle</p>
      <p style="border-top-right-radius: 4.5px;">Link</p>
    </div>

    <div class="social-handles--row" *ngFor="let response of response.channelMetrics?.socialChannels;let isLast = last">

      <p
        class="social-handles--item-main"
        [ngStyle]="{ 'border-bottom-left-radius' : isLast ? '4.5px' : 0 }">
        {{ response.channelName }}
      </p>

      <p
        class="social-handles--item-main-mobile"
        [ngStyle]="{ 'border-bottom-left-radius' : isLast ? '4.5px' : 0 }">
        {{ response.channelName }}
        <br>
        <span
          class="social-handles--item-visit-profile"
          (click)="service.visitProfileByLink(response.link)">
          Visit profile
        </span>
      </p>

      <p>{{ response.likeCount }}</p>

      <p>{{ response.followerCount }}</p>

      <p class="social-handles--item-handle">{{ response.handle }}</p>

      <p
        class="social-handles--item-visit-profile"
        [ngStyle]="{ 'border-bottom-right-radius' : isLast ? '4.5px' : 0 }"
        (click)="service.visitProfileByLink(response.link)">
        Visit profile
      </p>

    </div>

  </div>

</div>
