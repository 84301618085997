import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { FeatureCardsModule } from './feature-cards';
import { ActivityDayModule } from './activity-day';
import { TravelMediumModule } from './travel-medium/travel-medium.module';
import { ActivityDayHeaderModule } from './activity-day-header';
import { ActivityContentModule } from './activity-content/activity-content.module';
import { MediaCardModule } from './media-card/media-card.module';
import { DurationPipeModule } from './feature-cards/duration.pipe';

import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';

import { ActivityDayListComponent } from './activity-day-list.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  TranslateModule
];

const LIBRARIES = [
  FeatureCardsModule,
  ActivityDayModule,
  TravelMediumModule,
  ActivityDayHeaderModule,
  ActivityContentModule,
  MediaCardModule,
  DurationPipeModule
];

const PROVIDERS = [
  SearchResultDialogActionService
];

const COMPONENTS = [
  ActivityDayListComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  providers: [
    PROVIDERS
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ActivityDayListComponent
  ]
})
export class ActivityDayListModule { }
