import { Component, Input, AfterViewInit, ViewEncapsulation, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

import { ActivityDateData } from '@hiptraveler/data-access/api';
import { ActivityDayHeaderService } from './activity-day-header.service';
import { ActivityDayListService } from '../activity-day-list.service';
import { SearchLocationData, getPlaceResultByLocationData } from '@hiptraveler/common';
import { opacityFade } from '@hiptraveler/animations';
import { SnackbarService } from '@hiptraveler/snackbar';
import { SearchParam } from '../../common';

@Component({
  selector: 'activity-day-header',
  templateUrl: './activity-day-header.component.html',
  styleUrls: ['./activity-day-header.component.scss', './activity-day-header-responsiveness.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ ActivityDayHeaderService ],
  animations: [ opacityFade() ]
})
export class ActivityDayHeaderComponent implements AfterViewInit {

  @Input() scrollId: string;
  @Input() activity: ActivityDateData;
  @Input() index: number;
  @Input() view: string;

  @Output() navigateClicked = new EventEmitter<SearchParam>();

  @ViewChild('placesAutocomplete') placesAutocomplete: ElementRef<HTMLInputElement>;

  gmpaResults: google.maps.places.PlaceResult;

  constructor(
    private element: ElementRef<HTMLDivElement>,
    private activityDayList: ActivityDayListService,
    public service: ActivityDayHeaderService,
    private snackbar: SnackbarService
  ) { }

  ngAfterViewInit(): void {
    setTimeout(() => this.service.observeElementPosition(this.scrollId, this.element), 1000);
  }

  /**
   * Navigates to the search page with the specified activity data.
   * 
   * @param activity Activity data for the selected day.
   */
  async navigateToSearch(activity: ActivityDateData): Promise<void> {
    try {
      await this.activityDayList.navigateToSearchPage(activity, 'search');
    } catch (error) {
      this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
    }
  }

  locationChanges(value: SearchLocationData): void {
    const location = getPlaceResultByLocationData(value);
    this.placesAutocomplete.nativeElement.value = location?.formatted_address || '';
    this.service.updateNewLocation(location, this.activity?.day || (this.index+1));
  }

  navigateToHeaderSelection(searchParam: SearchParam): void {

    if (!this.activity.dayLocMap && !this.gmpaResults) {
      this.service.snackbar.open({ message: 'Please enter a location first!' });
      return;
    }

    this.navigateClicked.emit(searchParam);
  }

}
