import { LatLngExpression } from "leaflet";
import { get, merge, toNumber } from "lodash";

import { HotelResultData, ImageResultData, SearchAddress } from "@hiptraveler/data-access/api";
import { SearchResultData } from "@hiptraveler/common";

/**
 * Represents a composite search result that includes both detailed search result data 
 * and its corresponding geographic location.
 */
interface SearchResultWithLocation {
  /** The search result details extracted from the itinerary. */
  data: SearchResultData;
  /** The geographic coordinates as a Leaflet LatLngExpression. */
  location: LatLngExpression | any;
}

/**
 * Maps an itinerary object (of type ImageResultData or HotelResultData) to a composite search result,
 * combining both search result data and geographic location.
 *
 * The function extracts latitude and longitude values from the itinerary to form a geographic coordinate.
 * It also constructs a SearchResultData object by mapping relevant itinerary properties.
 *
 * @param itinerary - The itinerary object containing image or hotel result data.
 * @returns An object of type SearchResultWithLocation that includes the mapped search result data and location.
 */
export function mapItineraryToSearchResult(itinerary?: ImageResultData | HotelResultData): SearchResultWithLocation {

  const location: LatLngExpression = [
    toNumber(get(itinerary, 'latitude', 0)),
    toNumber(get(itinerary, 'longitude', 0))
  ];

  return {
    location, data: {
      id: get(itinerary, 'id', 'unknown'),
      title: get(itinerary, 'name', ''),
      image: get(itinerary, 'imgUrl', '') as string,
      address: get(itinerary, 'address', '') as SearchAddress,
      location: merge({}, get(itinerary, 'loc', {}), { coordinates: location }),
      locationName: get(itinerary, 'location', '')
    }
  };
}
