import { AfterViewInit, Directive, ElementRef } from '@angular/core';

import { MyTripsService } from './my-trips.service';

@Directive({
  selector: '[layoutObserver]'
})
export class LayoutObserverDirective implements AfterViewInit {

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private service: MyTripsService
  ) { }
  
  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {

    const resizeObserver = new ResizeObserver((entries) => {

      const hostElement = entries[0].target as HTMLElement;
      const target = hostElement.children.item(0) as HTMLElement;
      const myTripsDropdown = hostElement.getElementsByClassName('my-trips--dropdown').item(0);

      if (target.style.display === 'none') {
        this.service.createNewTripButtonState$$.next(true);
      }
      if (myTripsDropdown) {
        this.service.createNewTripButtonState$$.next(false);
      }
    });

    this.element && resizeObserver.observe(this.element);
  }

}
