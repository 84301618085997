import { getWindowRef, PromiseReject, PromiseResolve } from "@hiptraveler/common";

export function googleTagManagerClient(): Promise<void> {
  return new Promise((resolve: PromiseResolve, reject: PromiseReject) => {
    const windowRef = getWindowRef();

    if (!windowRef) {
      reject(new Error('Window object is not available.'));
      return;
    }

    windowRef.dataLayer = windowRef.dataLayer || [];
    windowRef.gtag = function gtag() {
      windowRef.dataLayer.push(arguments);
    };

    const scriptElement = windowRef.document.createElement('script') as HTMLScriptElement;
    scriptElement.src = 'https://www.googletagmanager.com/gtag/js?id=G-12TFYDMTH3';
    scriptElement.defer = true;

    windowRef.document.body.appendChild(scriptElement);

    scriptElement.onload = () => {
      try {
        windowRef.gtag('js', new Date());
        windowRef.gtag('config', 'G-12TFYDMTH3');

        resolve(null);
        console.log('** 3rd party CDN: Google Tag Manager script loaded successfully.');
      } catch (error) {
        reject(error);
        console.error('Error initializing Google Tag Manager:', error);
      }
    };

    scriptElement.onerror = (error) => {
      reject(new Error('Failed to load Google Tag Manager script.'));
      console.error('Google Tag Manager script loading failed:', error);
    };
  });
}
