import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { FiltersService } from '../../filters.service';
import { FiltersStateService } from '../../common';
import { ListItem, ListItemType } from '../../list-items';

@Component({
  selector: 'list-panel',
  templateUrl: './list-panel.component.html',
  styleUrls: ['./list-panel.component.scss'],
  host: { class: 'list-panel' },
  encapsulation: ViewEncapsulation.None
})
export class ListPanelComponent implements OnInit, OnDestroy {

  @Input() data: ListItem[];
  @Input() header: string | null;
  @Input() travelStyle: string;

  type?: ListItemType;

  constructor(
    public service: FiltersService,
    public filtersService: FiltersStateService
  ) { }

  ngOnInit(): void {
    if (this.travelStyle === '') (this.type = 'exp')
    this.service.overlayState$$.next(true);
  }

  ngOnDestroy(): void {
    this.service.overlayState$$.next(false);
  }

  get highlight(): boolean {
    return this.travelStyle === '' && ![ 'amenities', 'activities' ].includes(this.type!);
  }

  toggleSelection(value: string): void {

    if (this.travelStyle === '' && [ 'amenities', 'activities' ].includes(this.type!))
      return this.toggleHighlightSelection(value);

    let selected = this.filtersService[this.travelStyle === '' ? 'selectedTravelStyles$$' : 'selectedItems$$'].value.map(e => e?.value).filter(Boolean);
    selected.includes(value)
      ? selected = selected.filter(e => e !== value)
      : selected.push(value);
    this.filtersService[this.travelStyle === '' ? 'selectedTravelStyles$$' : 'selectedItems$$'].next(
      this.data.filter((listItem: ListItem) => selected.includes(listItem.value))
    );
  }

  toggleHighlightSelection(value: string): void {

    let selected = this.filtersService.selectedItems$$.value.map(e => e.value);
    selected.includes(value)
      ? selected = selected.filter(e => e !== value)
      : selected.push(value);

    const results = this.data.filter((listItem: ListItem) => selected.includes(listItem.value))
    this.filtersService.selectedItems$$.next(results);
  }

  applyFilters(): void {
    this.filtersService.panelFilterApplyClicked$$.next();
  }
  
  clearFilters(): void {
    this.filtersService.clearFilters();
    this.filtersService.panelFilterClearClicked$$.next();
  }

}
