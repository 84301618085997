import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';

import { RegistrationFormService } from './registration-form.service';
import { ActionButtonComponent } from '@hiptraveler/ui/buttons';
import { AppListenerService } from '@hiptraveler/common';
import { SocialMediaType, Type } from './common';

@Component({
  selector: 'registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss', './registration-form-responsiveness.scss'],
  host: { class: 'registration-form-registration-form' },
  viewProviders: [ RegistrationFormService ],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationFormComponent implements AfterContentInit {

  @ViewChild('submitButton') submitButton: ActionButtonComponent;
  
  @Output() socialClicked = new EventEmitter<SocialMediaType>();

  @Input() pageView: string;
  @Input() type: Type = 'travelAgent';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public service: RegistrationFormService,
    public appListener: AppListenerService
  ) { }

  ngAfterContentInit(): void {
    this.changeDetectorRef.detectChanges();
    this.service.observeAuthChanges();
  }

}
