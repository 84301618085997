import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ItineraryActionPanelComponent } from './itinerary-action-panel.component';
import { ActionPanelStylesDirective, ValidateStatusPipe, ValidateIdPipe, DateStringPipe, AddedToCurrentDayPipe, AddedToAllDaysPipe } from './common';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  TranslateModule
];

const COMPONENTS = [
  ItineraryActionPanelComponent
];

const DIRECTIVES = [
  ActionPanelStylesDirective
];

const PIPES = [
  ValidateStatusPipe,
  ValidateIdPipe,
  DateStringPipe,
  AddedToCurrentDayPipe,
  AddedToAllDaysPipe
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES,
    PIPES
  ],
  exports: [
    ValidateStatusPipe,
    ItineraryActionPanelComponent
  ]
})
export class ItineraryActionPanelModule { }
