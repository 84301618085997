import { ScreenView } from "./model";

export const PANEL_BORDER_SIZE: number = 5;

export const PANEL_HEIGHT: number = 445 + PANEL_BORDER_SIZE*2;

export const SCREENS = [
  ScreenView.mainSelection,
  ScreenView.experienceCarousel, ScreenView.travelCarousel,
  ScreenView.activitiesCarousel, ScreenView.locationCarousel,
  ScreenView.calendar, ScreenView.clientInfo, ScreenView.summary
] as ScreenView[];
