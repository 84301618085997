import { Injectable } from '@angular/core';
import { firstValueFrom, tap } from 'rxjs';
import { filter, includes } from 'lodash';

import { CarouselService, EFAStateServiceService, FormValue, ScreenView } from '../../../shared';

@Injectable()
export class CarouselGridService {

  constructor(
    private stateService: EFAStateServiceService,
    private carousel: CarouselService
  ) { }

  observeFormChanges(): void {
    this.stateService.formValue$.pipe(
      tap(async (form: FormValue) => {

        if (this.stateService.screenViewValue$$.value !== ScreenView.carouselGrid) return;

        const { screen2, screen3, screen4 } = form.carouselData;

        const summaryItems = this.stateService.summaryItems$$.value?.filter(
          e => ![ ScreenView.experienceCarousel, ScreenView.travelCarousel, ScreenView.activitiesCarousel ].includes(e?.['carouselData'])
        ) || [];

        const experiences: any = await firstValueFrom(this.carousel.getDataByFilter('experience'));
        const travelStyle: any = await firstValueFrom(this.carousel.getDataByFilter('travelStyle'));
        const activities: any = await firstValueFrom(this.carousel.getDataByFilter('activity'));

        const response1 = 
          filter(experiences, (obj: any) => includes(screen2, obj.code))
          .map((e: any) => ({ output: e.name, carouselData: ScreenView.experienceCarousel }))

        const response2 = 
          filter(travelStyle, (obj: any) => includes(screen3, obj.code))
          .map((e: any) => ({ output: e.name, carouselData: ScreenView.travelCarousel }))

        const response3 = 
          filter(activities, (obj: any) => includes(screen4, obj.code))
          .map((e: any) => ({ output: e.name, carouselData: ScreenView.activitiesCarousel }))

        this.stateService.summaryItems$$.next(
          summaryItems.concat([ ...response1, ...response2, ...response3 ])
        );
      })
    ).subscribe();
  }

}
