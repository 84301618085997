import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { SkeletonUiModule } from './skeleton-ui/skeleton-ui.module';

import { MyTripsComponent } from './my-trips.component';
import { SelectionNavigationDirective } from './selection-navigation.directive';
import { LayoutObserverDirective } from './layout-observer.directive';
import { FilterTripsPipe } from './filter-trips.pipe';

const MODULES = [
  CommonModule,
  ClipboardModule,
  MatRippleModule,
  FormsModule,
  MatIconModule,
  MatButtonModule,
  MatDividerModule,
  MatMenuModule,
  LazyLoadImageModule,
  NgxSkeletonLoaderModule
];

const LIBRARIES = [
  SkeletonUiModule
];

const COMPONENTS = [
  MyTripsComponent
];

const DIRECTIVES = [
  SelectionNavigationDirective,
  LayoutObserverDirective
];

const PIPES = [
  FilterTripsPipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES,
    PIPES
  ],
  exports: [
    MyTripsComponent
  ]
})
export class MyTripsModule { }
