import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { CarouselService, CarouselView, EFAStateServiceService, ScreenView } from '../../shared';

@Component({
  selector: 'carousel-activities',
  template: `<poi-select-carousel selectedFilter="activity"></poi-select-carousel>`,
  host: { class: 'experience-finder-ai--host-carousel-activities experience-finder-ai--host-element experience-finder-ai--host-carousel' },
  viewProviders: [ CarouselService ],
  encapsulation: ViewEncapsulation.None
})
export class CarouselActivitiesComponent extends CarouselView implements OnInit {

  constructor(a: EFAStateServiceService, b: CarouselService) {
    super(a, b);
  }

  ngOnInit(): void {
    this.initialize(ScreenView.activitiesCarousel, 'activity');
  }

}
