import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayColorBorder'
})
export class DayColorBorderPipe implements PipeTransform {

  transform(value?: string | null): string {
    return `2px solid ${value ? value : '#00000073'}`;
  }

}
