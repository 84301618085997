import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';

import { MyTripsService } from './my-trips.service';
import { CDNReaderService } from '@hiptraveler/common';
import { EFAStateServiceService } from '../../shared';
import { opacityFade } from '@hiptraveler/animations';

const TRIP_ICON: string = '';

@Component({
  selector: 'my-trips',
  templateUrl: './my-trips.component.html',
  styleUrls: ['./my-trips.component.scss', './my-trips-responsiveness.scss'],
  host: { class: 'experience-finder-ai--host-my-trips experience-finder-ai--host-element' },
  viewProviders: [ MyTripsService, CDNReaderService ],
  animations: [ opacityFade() ],
  encapsulation: ViewEncapsulation.None,
})
export class MyTripsComponent implements OnDestroy {

  tripIcon: string = TRIP_ICON;
  myTripTitleControl: string;

  constructor(
    private stateService: EFAStateServiceService,
    public service: MyTripsService
  ) { }

  ngOnDestroy(): void {
    this.service.createNewTripButtonState$$.next(false);
  }

  navigateToDefaultView(): void {
    this.stateService.myTripsViewState$$.next(false);
  }

}
