import { Store } from "@ngxs/store";
import { chain, isEqual, some } from "lodash";
import { Marker } from "leaflet";

import { SearchState } from "@hiptraveler/data-access/search";
import { ItineraryState } from "@hiptraveler/data-access/itinerary";
import { DialogType } from "@hiptraveler/dialogs/search-result-dialog";
import { GeoJSONProperties, getWindowRef, globalStateManagementStoreKey } from "@hiptraveler/common";
import { HotelResultData, ImageResultData } from "@hiptraveler/data-access/api";
import { extractActivityCoordinates } from "./utils";

/**
 * Finds a search result point-of-interest (POI) by its ID.
 *
 * This method first attempts to locate the POI in the main search state arrays for adventures, foods, and hotels.
 * If not found, it falls back to searching through the itinerary state data for unadded POIs.
 * The first valid match is returned along with its corresponding dialog type.
 *
 * @param id - The unique identifier of the search result POI.
 * @returns An object containing the found POI and its dialog type.
 */
export function findSearchResultById(id: string): { result: any; type: DialogType } {
  const store: Store = getWindowRef()[globalStateManagementStoreKey];

  const searchAdventures = store.selectSnapshot(SearchState.adventures);
  const searchFoods = store.selectSnapshot(SearchState.foods);
  const searchHotels = store.selectSnapshot(SearchState.hotels);

  const itineraryArr = store.selectSnapshot(ItineraryState.actDateArr) || [];

  const itineraryAdventures = chain(itineraryArr)
    .map(e => e.ImgArray?.filter(x => !x.imgCategory.includes('Food')))
    .flatten()
    .value() as ImageResultData[] | undefined;
  const itineraryFoods = chain(itineraryArr)
    .map(e => e.ImgArray?.filter(x => x.imgCategory.includes('Food')))
    .flatten()
    .value() as ImageResultData[] | undefined;
  const itineraryHotels = chain(itineraryArr)
    .map(e => e.HotelArray)
    .flatten()
    .value() as HotelResultData[] | undefined;

  const adventure = searchAdventures
    ? searchAdventures.find(e => e.id === id)
    : itineraryAdventures?.find(e => e?.id === id);
  const food = searchFoods
    ? searchFoods.find(e => e.id === id)
    : itineraryFoods?.find(e => e?.id === id);
  const hotel = searchHotels
    ? searchHotels.find(e => e.id === id)
    : itineraryHotels?.find(e => e?.id === id);

  const result = [ adventure, hotel, food ].find(Boolean);
  const type: DialogType = adventure ? 'adventure' : hotel ? 'hotel' : food ? 'food' : 'adventure';

  return { result, type };
}

/**
 * Computes the z-index for a marker based on its coordinates.
 *
 * Checks if the marker's coordinates exist within the activity coordinates.
 * If a match is found, returns 1000 (bringing the marker to the foreground);
 * otherwise, returns 0.
 *
 * @param marker - The Leaflet marker with GeoJSON properties.
 * @returns The computed z-index value.
 */
export function computeMarkerZIndexBasedOnActivityCoordinates(marker: Marker<GeoJSONProperties>): number {
  const markerCoords = marker.feature?.geometry?.coordinates;
  const activityCoords = extractActivityCoordinates();
  const exists = some(activityCoords, coords => isEqual(coords, markerCoords));
  return exists ? 1000 : 0;
}
