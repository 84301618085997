import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, filter, firstValueFrom, map, takeUntil, tap } from 'rxjs';

import { AuthAction } from '@hiptraveler/data-access/auth';
import { UserState, UserStateModel } from '@hiptraveler/data-access/user';
import { ProfileStateService, SettingsView } from '../../common';

@Injectable()
export class UserSettingsService {

  subscription$ = new Subject<void>();
  formProcessing$$ = new BehaviorSubject<boolean>(false);

  signOutProcessing$$ = new BehaviorSubject<boolean>(false);
  signOutProcessing$ = this.signOutProcessing$$.asObservable();

  constructor(
    private store: Store,
    public stateService: ProfileStateService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  get travelAgentUser$(): Observable<boolean> {
    return this.store.select(UserState.state).pipe(
      map((state: UserStateModel | null) => (state?.userType === 'htAgent'))
    );
  }

  notAuthenticatedListener(): void {
    this.store.select(UserState.id).pipe(
      filter(e => !e),
      tap(() => {
        this.stateService.settingsView$$.next('profile')
        this.stateService.profileTabSelection$$.next('about');
        this.stateService.profileSettingsView$$.next(false);
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  changeView(view: SettingsView): void {
    this.stateService.settingsView$$.next(view);
  }
  
  async signOut(): Promise<void> {
    this.signOutProcessing$$.next(true);
    try { await firstValueFrom(this.store.dispatch(new AuthAction.SignOut)) }
    catch (e) { }
    this.signOutProcessing$$.next(false);
  }

}
