
<button mat-icon-button class="host-close-panel" (click)="stateService.panelCloseTrigger$$.next()">
  <mat-icon>close</mat-icon>
</button>

<ng-container *ngIf="stateService.isMobile$ | async">
  <welcome-view textTypedId="main-selection"></welcome-view>
</ng-container>

<div class="main-selection--options">
  <div class="main-selection--option" matRipple optionAction="explore">
    <div class="main-selection--option-backdrop"></div>
    <p>Plan a Trip</p> 
  </div>
  <div class="main-selection--option" matRipple optionAction="explore">
    <div class="main-selection--option-backdrop"></div>
    <p>Explore</p> 
  </div>
  <div class="main-selection--option" matRipple optionAction="my-trips">
    <div class="main-selection--option-backdrop"></div>
    <p>My Trips</p> 
  </div>
  <div class="main-selection--option" matRipple optionAction="my-profile">
    <div class="main-selection--option-backdrop"></div>
    <p>Create Blog</p> 
  </div>
</div>
