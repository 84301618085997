import { Directive, ElementRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { delay, distinctUntilChanged, filter, map, merge, switchMap, take, takeUntil, tap } from 'rxjs';

import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { LeafletMap, LeafletMapControlStateService } from '@hiptraveler/features/leaflet-map';
import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { ItineraryDateViewStateService } from './itinerary-date-view-state.service';
import { AppListenerService, currentLang, getWindowRef, SearchResultData } from '@hiptraveler/common';
import { brandStateLoaded, extractActivityCoordinates, mapData } from '../utils/leaflet-map.util';
import { markerIconBuilder } from '../utils';
import { findSearchResultById } from '../leaflet-map-fn';

@Directive({
  selector: '[leafletMap]',
  providers: [ SearchResultDialogActionService, ItineraryDateViewStateService ]
})
export class ItineraryDateViewMapDirective extends LeafletMap implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store,
    private appListener: AppListenerService,
    private searchResultDialog: SearchResultDialogActionService,
    private stateService: ItineraryDateViewStateService,
    a: Router, b: ElementRef<HTMLElement>, c: LeafletMapControlStateService
  ) {
    super(a, b, c);
  }

  async ngOnInit(): Promise<void> {

    if (isPlatformServer(this.platformId)) return;

    await brandStateLoaded();

    this.appListener.mapVisibilityState$.pipe(takeUntil(this.subscription$)).subscribe(() => {
      this.setupMap();
    });
  }

  setupMap(): void {

    this.setMap();

    this.observePopupPane(this.element);

    this.initializeMapViewFromBasicInfo();
    this.setMapBoundsUsingActivitiesCoordinates();

    this.setPoiMarkersWithActivityDate();
    this.displayHoveredPoiMarkerOnMap();
    this.openPopupForSelectedMarker();
  }

  /**
   * Initializes the map view using basic itinerary info.
   */
  private initializeMapViewFromBasicInfo(): void {
    this.store.select(ItineraryState.basicInfo).pipe(
      tap(basicInfo => {
        const latitude = basicInfo?.locationList?.[0]?.latitude;
        const longitude = basicInfo?.locationList?.[0]?.longitude;
        const hasActivities = this.store.selectSnapshot(ItineraryState.actDateArr)?.length

        if (!latitude || !longitude || hasActivities) return;
        this.clearAllLayers();
        this.updateMapView([ latitude, longitude ], 7);
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Updates the map bounds based on activity coordinates.
   */
  private setMapBoundsUsingActivitiesCoordinates(): void {
    this.store.select(ItineraryState.actDateArr).pipe(
      filter(Boolean),
      map(extractActivityCoordinates.bind(this)),
      tap(this.setMapBoundsByCoordinates.bind(this)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Refreshes the itinerary markers on the map with colors specific to each activity date.
   *
   * This method sets up colored marker icons based on each activity's dayColor and applies them
   * to the markers (adventure, hotel, and food). It subscribes to itinerary activity updates and
   * marker deletions, ensuring the map reflects the latest state.
   */
  private setPoiMarkersWithActivityDate(): void {

    const initializeColoredMarkers = (v: any) => (v || []).map((e: any) => e.dayColor)
    .forEach((theme: string, index: number) => {
      [ 'adventure', 'hotel', 'food' ].forEach((type: any) => {
        const { field, icon } = markerIconBuilder({ theme, type, day: index + 1 });
        (this.markerIcon as any)[field] = this.Leaflet.divIcon(icon);
      });
    });

    const applyColoredMarkersByActivityDate = (v: any) => v
    ?.forEach((data: any) => {
          
      const markerIcon: any = this.markerIcon;
      const hotels = data.HotelArray?.filter(Boolean) || [];
      const adventures = data.ImgArray?.filter(Boolean)?.filter((e: any) => !e.imgCategory.includes('Food')) || [];
      const foods = data.ImgArray?.filter(Boolean)?.filter((e: any) => e.imgCategory.includes('Food')) || [];

      hotels.length && this.setGeoJSON(mapData(hotels), markerIcon[`hotelMarkerIcon_colored${data.day}`]);
      adventures.length && this.setGeoJSON(mapData(adventures), markerIcon[`adventureMarkerIcon_colored${data.day}`]);
      foods.length && this.setGeoJSON(mapData(foods), markerIcon[`foodMarkerIcon_colored${data.day}`]);
    });

    const markerUpdate = () => this.store.select(ItineraryState.actDateArr).pipe(
      distinctUntilChanged((a, b) => a?.length === b?.length),
      filter(() => {
        const pathname = getWindowRef()?.location?.pathname;
        return new RegExp(`/${currentLang()}/(itinerary|travel-story|compose)/`).test(pathname)
      }),
      tap(initializeColoredMarkers.bind(this)),
      tap(applyColoredMarkersByActivityDate.bind(this)),
      takeUntil(this.subscription$)
    );

    this.stateService.deletedMarkerObserver(async () => {
      this.removeAllMarkers();
      markerUpdate().pipe(take(1)).subscribe();
    });

    merge(
      this.store.select(ItineraryState.actDateMap).pipe(filter(Boolean)),
      this.leafletInitialized$.asObservable()
    ).pipe(
      switchMap(() => markerUpdate()),
      takeUntil(this.subscription$)
    ).subscribe();
  }
  
  /**
   * Displays a marker on the map for the currently hovered POI.
   *
   * Resets the active search result marker, waits 300ms for a stable hover state,
   * and if the POI has valid coordinates, updates the map view accordingly.
   */
  private displayHoveredPoiMarkerOnMap(): void {
    this.leafletControl.activeSearchResultData$$.next(null);
    this.leafletControl.activeSearchResultData$.pipe(
      delay(300),
      tap((data: SearchResultData) => {
        if (![...data.location.coordinates!].filter(Boolean).length) return;
        const location: any = data?.location?.coordinates ?? [];
        this.updateMapView(location);
        this.highlightAndOpenMarkerPopup(data?.id);
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Opens a popup dialog for the currently selected marker.
   *
   * Subscribes to the selected marker ID, retrieves its corresponding search result data,
   * and opens the dialog with the appropriate result and type.
   */
  private openPopupForSelectedMarker(): void {
    this.selectedMarker$.pipe(
      map((id: string) => findSearchResultById(id)),
      tap(({ result, type }) => result && this.searchResultDialog.open(result, type)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

}
