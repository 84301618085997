<div class="skyscanner-view">

  <img [src]="travelImage$ | async" [alt]="activeWidget">

  <div class="skyscanner-view--selection">
    <p (click)="activeWidget = 'FlightSearchWidget'" [class.active]="activeWidget === 'FlightSearchWidget'" translate="skyscanner.flights"></p>
    <p>|</p>
    <p (click)="activeWidget = 'CarHireWidget'" [class.active]="activeWidget === 'CarHireWidget'" translate="skyscanner.rental-cars"></p>
  </div>

  <ng-container *ngIf="activeWidget === 'FlightSearchWidget'">
    <div class="skyscanner-view--content" skyscannerWidget="FlightSearchWidget" [data]="data" [ngClass]="{ active: activeWidget === 'FlightSearchWidget' }" [@opacityFadeIn]></div>
  </ng-container>
  <ng-container *ngIf="activeWidget === 'CarHireWidget'">
    <div class="skyscanner-view--content" skyscannerWidget="CarHireWidget" [data]="data" [ngClass]="{ active: activeWidget === 'CarHireWidget' }" [@opacityFadeIn]></div>
  </ng-container>

</div>
