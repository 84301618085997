import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { FiltersOverlayComponent } from './filters-overlay.component';

const MODULES = [
  CommonModule,
  MatIconModule
];

const COMPONENTS = [
  FiltersOverlayComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    FiltersOverlayComponent
  ]
})
export class FiltersOverlayModule { }
