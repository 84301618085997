import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { FlightRoute, Airport } from '@hiptraveler/data-access/api';
import { TravelDataInput } from './navigation-dialog-action.service';
import { promiseDelay, SkyscannerWidgetType } from '@hiptraveler/common';
import { opacityFadeIn } from '@hiptraveler/animations';
import { NavigationDialogService } from './navigation-dialog.service';

const EMPTY_COORD: Airport = { latitude: '0', longitude: '0', iata: 'ZZZ', location: '' };

@Component({
  selector: 'app-itinerary-navigation-dialog',
  template: `
    <ng-container *ngIf="travelData;else processing">
      <dialog-close-button [@opacityFadeIn]></dialog-close-button>
      <skyscanner-view [data]="travelData" [activeWidget]="activeWidget" [@opacityFadeIn]></skyscanner-view>
    </ng-container>
    <ng-template #processing>
      <mat-progress-spinner theme="white" mode="indeterminate" [@opacityFadeIn]></mat-progress-spinner>
    </ng-template>
  `,
  styleUrls: ['./navigation-dialog.component.scss'],
  animations: [ opacityFadeIn() ],
  viewProviders: [ NavigationDialogService ],
  encapsulation: ViewEncapsulation.None
})
export class NavigationDialogComponent implements OnInit {

  travelData: FlightRoute;
  activeWidget: SkyscannerWidgetType;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: TravelDataInput,
    private dialog: MatDialog,
    private service: NavigationDialogService
  ) { }

  ngOnInit(): void {
    this.initializeWidgetConfigs();
  }

  async initializeWidgetConfigs(): Promise<void> {
    if ([ 'flight', 'fly' ].includes(this.data.travelMedium)) {
      this.service.requestTravelRoute(this.data, (route: FlightRoute | null) => {

        if (!route) { // Condition when the 'route' response is not available
          this.data.day !== 1
            ? this.dialog.closeAll() // If the activity is not on day 1, close the dialog.
            : (this.travelData = { // Otherwise, set the widget data to use the IP for origin and leave destination empty.
                origin: EMPTY_COORD, destination: EMPTY_COORD,
                ipOrigin: this.service.originIpLookupFirstDate
              });
          return;
        }
        
        if (this.data.day === 1) { // Condition when the 'route' response is present and the activity is on day 1.
          route.origin = EMPTY_COORD;
          route.ipOrigin = this.service.originIpLookupFirstDate;
        }
        
        (['origin', 'destination'] as const).forEach(key => // Fallback if the origin or destination coordinates are missing.
          route[key] = (route[key]?.latitude && route[key]?.longitude) ? route[key] : EMPTY_COORD
        );
        
        this.travelData = route;
      });
      this.activeWidget = 'FlightSearchWidget';
    }
    if (this.data.travelMedium === 'flight-and-ferry') {
      await promiseDelay(350);
      this.activeWidget = 'FlightSearchWidget';
      this.dialog.closeAll();
    }
    if (this.data.travelMedium === 'drive') {
      await promiseDelay(350);
      this.service.openTransitGoogleMapDirections(this.data);
      this.activeWidget = 'CarHireWidget';
      this.dialog.closeAll();
    }
  }

}
