import { Pipe, PipeTransform } from '@angular/core';

import { FiltersStateService } from '../common';
import { ListItem } from '../list-items';
import { Observable, delay, map } from 'rxjs';
import { filterQueryStringKey, getWindowRef, queryStringToObject } from '@hiptraveler/common';

@Pipe({
  name: 'checked'
})
export class CheckedPipe implements PipeTransform {

  constructor(
    public filtersService: FiltersStateService
  ) { }

  transform(value: ListItem): Observable<boolean> {
    return this.filtersService.selectedItems$$.asObservable().pipe(
      delay(0),
      map(() => {
        const filterQuery = queryStringToObject(getWindowRef()[filterQueryStringKey]);
        return [ 'activities', 'amenities', 'cuisine', 'experiences', 'travelStyle' ]
          .flatMap(key => filterQuery?.[key]?.split(',') || [])
          .includes(value.value);
      })
    );
  }

}
