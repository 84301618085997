import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { BasicInfoData, ItineraryData } from '@hiptraveler/data-access/api';
import { ProfileStateService } from '../../../common';
import { SnackbarService } from '@hiptraveler/snackbar';
import { currentLang } from '@hiptraveler/common';

@Directive({
  selector: '[navigateToBlog]'
})
export class NavigateToBlogDirective {

  @Input() itinerary: Partial<BasicInfoData>;

  #invalidHover: boolean;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private router: Router,
    private stateService: ProfileStateService,
    private snackbar: SnackbarService
  ) { }

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  @HostListener('mousemove', [ '$event' ]) mouseMove(event: MouseEvent): void {
    this.#invalidHover =
      (event.target as HTMLElement).classList.contains('feature-card--favorite-button')
      || !!(event.target as HTMLElement).attributes.getNamedItem('svgIcon')
      || (event.target as HTMLElement).tagName.toLowerCase() === 'svg'
      || (event.target as HTMLElement).tagName.toLowerCase() === 'path';
  }

  @HostListener('click') click(): void {
    if (this.#invalidHover) return;
    this.navigateToTrip(this.itinerary);
  }

  private async navigateToTrip({ pageTitle, published }: Partial<BasicInfoData>): Promise<void> {

    const selfProfile = await firstValueFrom(this.stateService.selfProfile$);

    console.log('@@@ selfProfile', selfProfile);

    if (!published && !selfProfile) {
      this.snackbar.open({ message: 'Sorry, this itinerary is private and cannot be viewed.', duration: 5000 });
      return;
    }

    pageTitle && this.router.navigateByUrl(`/${currentLang()}/travel-story/${pageTitle}`);
  }

}
