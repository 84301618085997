import { getWindowRef, PromiseReject, PromiseResolve } from "@hiptraveler/common";

export function facebookClient(): Promise<void> {
  return new Promise((resolve: PromiseResolve, reject: PromiseReject) => {

    const windowRef = getWindowRef();

    if (!windowRef) {
      reject(new Error('Window object is not available.'));
      return;
    }

    if (windowRef.FB) {
      resolve(windowRef.FB);
      return;
    }

    windowRef.fbAsyncInit = function () {
      try {
        windowRef.FB.init({
          appId: '1779459539145435', 
          cookie: true,
          xfbml: true,
          version: 'v17.0',
        });
        windowRef.FB.AppEvents.logPageView();
        console.log('** 3rd party CDN: Facebook SDK ready.');
        resolve(windowRef.FB); 
      } catch (error) {
        console.error('Error during FB SDK initialization:', error);
        reject(error); 
      }
    };

    const scriptElement = document.createElement('script');
    scriptElement.id = 'facebook-jssdk';
    scriptElement.src = 'https://connect.facebook.net/en_US/sdk.js';
    scriptElement.defer = true;

    scriptElement.onload = () => {
      console.log('** 3rd party CDN: Facebook SDK script loaded successfully.');
    };

    scriptElement.onerror = (error) => {
      console.error('Error loading Facebook SDK script:', error);
      reject(new Error('Facebook SDK script failed to load.'));
    };

    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode?.insertBefore(scriptElement, firstScript);
  });
}
