import { ListItem } from "../list-items";

export const search_allowedRoutes = [ 'thingstodo', 'hotels', 'foodanddrink' ];
export const name_allowedRoutes = [ 'thingstodo', 'hotels', 'foodanddrink', 'community' ];
export const community_allowedRoutes = [ 'community' ];
export const travelStyle_allowedRoutes = [ 'experiences', 'search', 'thingstodo', 'hotels', 'foodanddrink' ];
export const filterActions_allowedRoutes = [ 'thingstodo', 'hotels', 'foodanddrink' ];

export type CheckboxLabel = 'Cuisine' | 'Activities' | 'Amenities' | 'List';

export interface ListItems {
  display: ListItem[];
  data: ListItem[];
  activities: ListItem[];
  all: ListItem[];
}
