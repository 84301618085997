import { Directive, ElementRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { cloneDeep, xorBy } from 'lodash';
import { takeUntil, tap, delay, filter, map, distinctUntilChanged, take, merge } from 'rxjs';

import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { LeafletMapControlStateService } from '@hiptraveler/features/leaflet-map';
import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { NewItineraryObserverService } from './new-itinerary-observer.service';
import { SearchPageMap } from './search-page-map';
import { extractValidMapCoordinates } from './search-page-map-fn';
import { brandStateLoaded, extractActivityCoordinates, extractActivityCoordinatesByDay, mapData } from '../utils';
import { ActivityDate, AppListenerService, promiseDelay, SearchLocationService, SearchPageControlStateService, SearchResultData, siteNavigationCloseActionKey } from '@hiptraveler/common';
import { findSearchResultById } from '../leaflet-map-fn';

@Directive({
  selector: '[leafletMap]',
  providers: [ NewItineraryObserverService ]
})
export class SearchPageMapDirective extends SearchPageMap implements OnInit {

  preventHighlightMapView: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private appListener: AppListenerService,
    private searchResultDialog: SearchResultDialogActionService,
    a: Router, b: ElementRef<HTMLElement>, c: LeafletMapControlStateService,
    d: Store, e: SearchLocationService, f: SearchPageControlStateService,
    g: NewItineraryObserverService
  ) {
    super(a, b, c, d, e, f, g);
  }

  async ngOnInit(): Promise<void> {

    if (isPlatformServer(this.platformId)) return;

    this.appListener.mapExpansionState$.pipe(takeUntil(this.subscription$)).subscribe(async () => {
      await promiseDelay(10);
      this.mapView?.invalidateSize();
    });

    await brandStateLoaded();

    this.appListener.mapVisibilityState$.pipe(takeUntil(this.subscription$)).subscribe(() => {
      this.setupMap();
    });
  }

  setupMap(): void {

    this.setMap();

    this.observePopupPane(this.element);

    this.subscribeToMapCenterUpdates();
    this.handleEmptySearchLocationState();

    this.setPoiMarkersWithActivityDate();

    this.displayHoveredPoiMarkerOnMap();
    this.openPopupForSelectedMarker();
    this.resetMapOnNavigationChange();
    this.observeItineraryRemoval();
  }

  /**
   * Subscribes to both query-based and autocomplete location updates to re-center the map view.
   *
   * Initializes observers that listen for location data updates (from search queries and autocomplete),
   * clearing map layers and updating the view's center based on new coordinates with a preset zoom level.
   */
  private subscribeToMapCenterUpdates(): void {
    this.searchLocationDataObserver();
    this.searchLocationChangesObserver();
  }

  /**
   * Observes the search location state and resets the map view when no location is found.
   *
   * When the search location state is falsy, the map view is reset to the default position ([0,0])
   * with a zoom level of 2. Additionally, it sets a flag to prevent the map view from being highlighted.
   */
  private handleEmptySearchLocationState(): void {
    this.searchLocation.searchLocationState$.pipe(
      filter(state => !state),
      tap(() => {
        this.updateMapView([ 0, 0 ], 2);
        this.preventHighlightMapView = true;
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  private setPoiMarkersWithActivityDate(): void {

    const activityDate = this.searchPageControl.activityDate;
    if (!activityDate?.actDateMap) return this.renderAndDisplaySearchResultMarkers();

    const activity = activityDate.actDateMap[activityDate.day || 1];
    const hasActivityPoi = !!activity?.HotelArray?.length || !!activity?.ImgArray?.length;
    hasActivityPoi ? this.showMarkersByActivityDateData() : this.renderAndDisplaySearchResultMarkers();
  }

  private showMarkersByActivityDateData(): void {
    
    const applyAllActivityDateMarkers = () => {

      this.store.selectSnapshot(ItineraryState.actDateArr)?.forEach((activity, i) => {
        const day = i + 1,
          hotels = activity?.HotelArray ?? [],
          adventures = (activity?.ImgArray ?? []).filter(({ imgCategory }) => !imgCategory?.includes('Food')),
          foods = (activity?.ImgArray ?? []).filter(({ imgCategory }) => imgCategory?.includes('Food')),
          markerIcons = (icon: string) => (this.markerIcon as any)[`${icon}MarkerIcon_colored${day}`];

        const zIndexOffset = 1000;
        hotels.length && this.setGeoJSON(mapData(hotels), markerIcons('hotel'), zIndexOffset);
        adventures.length && this.setGeoJSON(mapData(adventures), markerIcons('adventure'), zIndexOffset);
        foods.length && this.setGeoJSON(mapData(foods), markerIcons('food'), zIndexOffset);
      });
    }
    
    const highlightMostRecentPoiLocation = (results: SearchResultData[]) => {
      if (this.preventHighlightMapView) return;
      this.searchPageControl.activityDate$.pipe(filter(Boolean),take(1)).subscribe((activityDate: ActivityDate) => {
  
        const highlightLocation = [
          ...activityDate?.HotelArray || [],
          ...(activityDate?.ImgArray || []).filter(e => e.imgCategory !== 'Chill time')
        ]?.map(e => [ +e?.latitude || 0, +e?.longitude || 0 ])?.slice(-1)?.[0];
  
        if (highlightLocation?.[0]) { // Has last itinerary in activity date

          const resultPositions = !!activityDate?.itineraryId 
            ? extractActivityCoordinatesByDay(activityDate.day)
            : extractValidMapCoordinates(results, []);

          this.setMapBoundsByCoordinates(resultPositions, () => {
            const replaceItineraryObj = this.searchPageControl.replaceItineraryActivity;
            replaceItineraryObj
              ? this.setReplaceItineraryPosition()
              : this.updateMapView(highlightLocation, this.mapView?.getZoom());
          });
        } else { // No last itinerary in activity date

          const combinedPositions = this.searchPageControl.replaceChillTimeActivity
            ? extractActivityCoordinates()
            : extractValidMapCoordinates(results, activityDate.actDate)

          this.setMapBoundsByCoordinates(combinedPositions, () => {
            this.setReplaceItineraryPosition()
          });
        }
      });
    }

    this.leafletControl.allSearchResultsData$.pipe( /* Search results marker setup observer */
      delay(350 + 1500),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      map((results: SearchResultData[]) => xorBy(results, this.mapMarkers.map(e => e.feature?.properties), 'id')),
      tap(this.initializeItineraryColoredMarkers.bind(this)),
      tap(this.renderMarkersFromSearchResults.bind(this)),
      tap(applyAllActivityDateMarkers.bind(this)),
      delay(500),
      tap(highlightMostRecentPoiLocation.bind(this)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Renders and displays map markers based on search results.
   *
   * Subscribes to the search results observable, renders the corresponding markers,
   * extracts their positions, and sets the map bounds (if the results are not of type 'profile').
   * Finally, it initializes the itinerary marker colors.
   */
  private renderAndDisplaySearchResultMarkers(): void {
    this.leafletControl.allSearchResultsData$.pipe( /* Search results marker setup observer */
      delay(350),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      tap((results: SearchResultData[]) => {
        const symmetricalDifference = xorBy(results, this.mapMarkers.map(e => e.feature?.properties), 'id');
        const searchResultData = this.renderMarkersFromSearchResults(symmetricalDifference);
        const positions = cloneDeep(searchResultData).map(e => e?.location?.coordinates?.reverse() || []);
        if (results[0]?.type !== 'profile') {
          this.setMapBoundsByCoordinates(positions);
          const activityDate = this.searchPageControl.activityDate;
          activityDate?.itineraryId || this.enforceMinimumZoomAfterBounds();
        }
        this.initializeItineraryColoredMarkers();
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Displays a marker on the map for the currently hovered POI.
   *
   * Resets the active search result marker, waits 300ms for a stable hover state,
   * and if the POI has valid coordinates, updates the map view accordingly.
   */
  private displayHoveredPoiMarkerOnMap(): void {
    this.leafletControl.activeSearchResultData$$.next(null);
    this.leafletControl.activeSearchResultData$.pipe(
      delay(300),
      tap((data: SearchResultData) => {
        if (![...data.location.coordinates!].filter(Boolean).length) return;
        const location: any = data?.location?.coordinates ?? [];
        this.updateMapView(location);
        this.highlightAndOpenMarkerPopup(data?.id);
        setTimeout(() => this.updateMapView(location), 500); // To do: Resolve this temporary map fixes
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Opens a popup dialog for the currently selected marker.
   *
   * Subscribes to the selected marker ID, retrieves its corresponding search result data,
   * and opens the dialog with the appropriate result and type.
   */
  private openPopupForSelectedMarker(): void {
    this.selectedMarker$.pipe(
      map((id: string) => findSearchResultById(id)),
      tap(({ result, type }) => this.searchResultDialog.open(result, type)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Resets the map state when a navigation or search location change occurs.
   *
   * Clears all layers and markers, and if no search location is set, updates the map view
   * to the default position.
   */
  private resetMapOnNavigationChange(): void {
    merge(
      this.searchLocation.searchLocation$$.asObservable(),
      this.router.events.pipe(filter(e => e instanceof NavigationStart))
    ).pipe(
      tap(() => {
        this.clearAllLayers();
        this.newItineraryObserver.removeAllAddedMarkers(); 
        this.searchLocation.data?.locId || this.updateMapView([ 0, 0 ], 2);
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  private observeItineraryRemoval(): void {
    this.appListener.globalSignalListener(siteNavigationCloseActionKey).pipe(
      takeUntil(this.subscription$)
    ).subscribe(() => {
      this.removeAllMarkers();
      this.newItineraryObserver.removeAllAddedMarkers(); 
      this.searchLocation.data?.locId || this.updateMapView([ 0, 0 ], 2);
      this.renderAndDisplaySearchResultMarkers();
    });
  }

}
