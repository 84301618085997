import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, Inject, PLATFORM_ID } from '@angular/core';
import { auditTime, debounceTime, distinctUntilChanged, filter, firstValueFrom, takeUntil, tap } from 'rxjs';
import Typed from 'typed.js';

import { EFAStateServiceService, ScreenView } from '../../shared';
import { getWindowRef } from '@hiptraveler/common';

@Directive({
  selector: '[textTyped]'
})
export class TextTypedDirective implements AfterViewInit {

  typed?: Typed;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private stateService: EFAStateServiceService
  ) { }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;

    this.typedTextSetup();
    this.brandHeaderTypeState();

    this.stateService.myTripsViewState$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      tap((state: boolean) => {
        this.typedTextSetup(state ? ScreenView.myTrips : this.stateService.screenViewValue);
      }),
      takeUntil(this.stateService.subscription$)
    ).subscribe();

    this.stateService.scrolled$.pipe(
      filter(Boolean),
      tap(() => {
        const screen = this.stateService.screenViewValue;
        this.typedTextSetup(screen);
      }),
      takeUntil(this.stateService.subscription$)
    ).subscribe();
  }

  async typedTextSetup(screen: ScreenView = ScreenView.mainSelection): Promise<void> {

    screen = this.stateService.myTripsViewState ? ScreenView.myTrips : screen;;
    const summaryScreenState = await firstValueFrom(this.stateService.summaryItems$);

    const value = {
      summaryScreenState: !!summaryScreenState?.length
    };
    
    this.typed = new Typed('#brand-header-highlight', {
      strings: [ getHighlightTextByScreen(screen, value) ],
      typeSpeed: 15,
      showCursor: false,
      startDelay: 0,
      fadeOut: true,
      fadeOutDelay: 0
    });
  }

  brandHeaderTypeState(): void {
    this.stateService.brandHeaderTypeState$$.asObservable().pipe(
      auditTime(1000)
    ).subscribe((state: boolean) => {
      if (state) {
        this.typed?.start();
      } else {
        this.typed?.destroy();
      }
    });
  }

}

function getHighlightTextByScreen(screen: ScreenView, value: { summaryScreenState: boolean }): string {

  const isMobile = getWindowRef()?.innerWidth <= 600;

  const locationCarousel = 'Where are you headed, Traveler?';
  const calendar = 'When would you like to hit the road?';
  const clientInfo = 'How many travelers are you taking?';
  const summary = value.summaryScreenState
    ? 'Looks like an amazing trip! Here\'s a summary of your preferences:'
    : 'Start planning your amazing trip!';

  if (screen === ScreenView.myTrips) {
    return 'My Trips';
  }
  if (screen === ScreenView.experienceCarousel) {
    return isMobile
      ? 'What are your favorite types of travel experiences?'
      : 'Whats your Experience Style?';
  }
  if (screen === ScreenView.travelCarousel) {
    return isMobile ? locationCarousel : 'Whats your Travel Style?';
  }
  if (screen === ScreenView.activitiesCarousel) {
    return isMobile ? calendar : 'What are your favorite Activities?';
  }
  if (screen === ScreenView.locationCarousel) {
    return isMobile ? clientInfo : locationCarousel;
  }
  if (screen === ScreenView.calendar) {
    return isMobile ? 'Here\'s a summary of your preferences:' : calendar;
  }
  if (screen === ScreenView.clientInfo) {
    return clientInfo;
  }
  if (screen === ScreenView.summary) {
    return summary;
  }

  return isMobile ? 'Let\'s plan your perfect trip!' : 'How can I help you?';
}
