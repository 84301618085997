import { getWindowRef } from "./window.util";

export function queryStringToObject(queryString: string): Record<string, string> {
  const params = new URLSearchParams(queryString);
  const query: Record<string, string> = {};
  for (const [key, value] of params.entries()) {
    query[key] = value;
  }
  return query;
}

export function objectToQueryString(query: Record<string, string>): string {
  const params = new URLSearchParams();
  for (const key in query) {
    query.hasOwnProperty(key) && params.append(key, query[key]);
  }
  return params.toString();
}

export function urlHash(windowObject?: any): string {
  return windowObject.location.hash || getWindowRef()?.location?.hash || '';
}

export function getPathname(url: string): string {
  return url?.split('?')?.[0]?.split('#')?.[0] || '/';
}

export function getDecodedValue(value: any): string {
  return value ? decodeURIComponent(value) : '';
}

export function removeHashes(url?: string): string {
  return url?.replace(/#+/g, '') || '';
}
