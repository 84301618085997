import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { combineLatest, filter, firstValueFrom, map, tap } from 'rxjs';

import { ActivityApiService } from '@hiptraveler/data-access/api';
import { AuthAction, AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { brandCampaignId, clientVID, currentLang, getWindowRef, HT_FROM_WIDGET_SIGNOUT, iframeWindowFixedTop, isBrandIframeWidget, isIframe, isWidget, isWidgetReturn, removeVisitorId, setFavoritesValue, setWidgetReturnValue, WidgetStateService } from '@hiptraveler/common';

export interface WindowCommunication {
  section: 'favs_interests' | 'favs' | 'menu' | 'user-menu' | 'login' | 'interests' | 'tripPlanner';
  message: string;
}

@Injectable()
export class WidgetObserverService implements OnDestroy {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private location: Location,
    private store: Store,
    private actions$: Actions,
    private activityApi: ActivityApiService,
    private widgetState: WidgetStateService,
    private authDialog: AuthDialogActionService,
    private stateService: ExperienceFinderStateService
  ) { }

  ngOnDestroy(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    getWindowRef()?.removeEventListener('message', this.deepLinkCommunication.bind(this));
  }

  async observe(): Promise<void> {

    if (!isIframe()) return;

    this.signOutObserver();
    this.iframeActionObserver();
    this.authenticationObserver();
    this.widgetActionsObserver();
    this.parentWindowNavbarStateObserver();

    await firstValueFrom(this.widgetState.brandIframeWidget$.pipe(filter(Boolean)));

    console.log('@@@ ', 'iframe-widget detected.');

    this.navigationChangesObserver();
  }

  private signOutObserver(): void {

    this.actions$.pipe(
      ofActionCompleted(AuthAction.SignOut, AuthAction.SignOutNoAuth),
      filter(e => e.result.successful && !e.result.error && !e.result.canceled),
      tap(() => {

        getWindowRef()?.localStorage?.setItem(HT_FROM_WIDGET_SIGNOUT, '1');
        
        getWindowRef()?.top?.postMessage({
          vId: clientVID(),
          message: 'ht_logout_success'
        }, '*');

        const fromWidgetSignout = getWindowRef()?.localStorage?.getItem(HT_FROM_WIDGET_SIGNOUT);
        fromWidgetSignout || removeVisitorId();
      })
    ).subscribe()
  }

  private iframeActionObserver(): void {
    getWindowRef()?.addEventListener('message', this.deepLinkCommunication.bind(this));
  }
  
  private widgetActionsObserver(): void {
  
    getWindowRef()?.addEventListener('message', (event: MessageEvent<{ message: string }>) => {

      const message = event.data?.message;

      if (!isBrandIframeWidget() || !isIframe() || !message || message === 'ht_exp_finder_popup') return;

      const profileId = this.store.selectSnapshot(UserState.profileId);
      profileId && message === 'ht_pr_vw'
        ? this.router.navigate([ `/${currentLang()}/profile/${profileId}/stories` ])
        : this.authDialog.open();
    });
  }

  private parentWindowNavbarStateObserver(): void {
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => {

        const currentLangPaths = [ '/', `/${currentLang()}`, `/${currentLang()}/` ];
        const isRootRoute = currentLangPaths.includes(event.url) || currentLangPaths.includes(event.urlAfterRedirects);
    
        iframeWindowFixedTop(!isRootRoute);
      })
    ).subscribe();
  }

  private authenticationObserver(): void {
    combineLatest([
      this.store.select(AuthState.accessToken),
      this.store.select(UserState.authenticated)
    ]).pipe(
      filter(([ accessToken, authenticated ]) => !!accessToken && authenticated),
      tap(([ accessToken ]) => {

        getWindowRef()?.top?.postMessage({
          id: accessToken,
          vId: clientVID(),
          action: isWidgetReturn() ? 'tripplanner' : 'noaction',
          message: 'ht_success'
        }, '*');

      })
    ).subscribe();
  }

  private navigationChangesObserver(): void {

    const pushNewMessage = (url: string) => {
      getWindowRef()?.top?.postMessage({
        message: 'ht_sync_url',
        hash: url
      }, '*');
    };

    this.location.onUrlChange(pushNewMessage.bind(this));

    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map(e => e.url)
    ).subscribe(pushNewMessage.bind(this));
  }

  private deepLinkCommunication(event: MessageEvent<WindowCommunication>): void {

    if (typeof event.data !== "object" || event.data.message !== "ht_exp_finder_popup" || !event.data.section) return;

    const { message, section } = event.data;

    setWidgetReturnValue(false);
    this.widgetPopUpPostAnalytics();

    if (section == "favs_interests" || section == "favs") {
      setFavoritesValue(true);
    } else {
      setFavoritesValue(false);
    }
    
    if (section == "menu") {
      setWidgetReturnValue(true);
      // this.stateService.dialogView$$.next('main-menu'); // un logged-in user menu. default view
    }
    
    if (section == "user-menu") {
      setWidgetReturnValue(false);
      // this.stateService.dialogView$$.next('main-menu'); // logged-in user menu
    }
    
    if (section == "login") {
      setWidgetReturnValue(false);
      // this.stateService.dialogView$$.next('auth-menu');
    }
    
    if (section == "interests" || section == "favs_interests") {
      setWidgetReturnValue(true);
      // this.stateService.dialogView$$.next('travel-style'); // experience finder carousal screen
    }
    
    if (section == "tripPlanner" || section == "favs") {
      setWidgetReturnValue(true);
      // this.stateService.dialogView$$.next('plan-trip'); // new trip screen
    }
  }

  private widgetPopUpPostAnalytics(): void {
    
    if (!isWidget()) return;

    this.activityApi.setUserAction({
      objType: 'widget',
      actionType: 'widget',
      vId: clientVID() || undefined,
      cId: brandCampaignId(),
      source: getWindowRef()?.location?.href
    }).subscribe()
  }

}
