import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, delay, map, switchMap, takeUntil } from 'rxjs';
import { get, sum } from 'lodash';

import { FiltersStateService } from '../../common';
import { filterQueryStringKey, getWindowRef, queryStringToObject } from '@hiptraveler/common';

@Component({
  selector: 'list-button',
  template: `
    <ng-container *ngIf="travelStyle === '';else defaultUI">
      <input customInputBorder type="text" autocomplete="off" name="travel-style" [placeholder]="('search-page.travel-style' | translate) + ' ' + text" readonly>
    </ng-container>
    <ng-template #defaultUI>
      <p><em>{{ 'search-page.show-all' | translate }}</em></p>
    </ng-template>
  `,
  styleUrls: ['./list-button.component.scss']
})
export class ListButtonComponent implements OnInit, OnDestroy {

  @Input() travelStyle: string;

  subscription$ = new Subject<void>();
  text: string;

  constructor(
    public filtersService: FiltersStateService
  ) { }

  ngOnInit(): void {
    this.filtersService.selectedTravelStyleKeys$.pipe(
      switchMap(() => this.filtersService.selectedItemKeys$.pipe(
        delay(0),
        map(() => {
          const filterQuery = queryStringToObject(getWindowRef()[filterQueryStringKey]);
          const keys = ['activities', 'amenities', 'cuisine', 'experiences', 'travelStyle'];
          const count = sum(keys.map(key => get(filterQuery, key) ? get(filterQuery, key).split(',').length : 0));

          this.text = count ? `(${count})` : '';
        }),
      )),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

}
