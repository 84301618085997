import { AfterViewInit, Directive, ElementRef, NgModule } from '@angular/core';
import { debounceTime, first, fromEvent, map, switchMap, takeUntil, tap, throttleTime } from 'rxjs';

import { EFAStateServiceService, SCREENS, ScreenView } from '../shared';

@Directive({
  selector: '[hostScroll]'
})
export class HostScrollDirective implements AfterViewInit {

  currentScreen: ScreenView = ScreenView.mainSelection;

  constructor(
    private elementRef: ElementRef<HTMLDivElement>,
    public stateService: EFAStateServiceService
  ) { }

  get element(): HTMLDivElement {
    return this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {

    this.scrollObserver();

    this.stateService.scrollToView$.pipe(
      takeUntil(this.stateService.subscription$)
    ).subscribe(this.scrollToView.bind(this));

    this.stateService.scrollToScreen$.pipe(
      takeUntil(this.stateService.subscription$)
    ).subscribe(this.scrollTo.bind(this));
  }

  scrollObserver(): void {

    // setTimeout(() => {
    //   this.scrollTo(this.stateService.view[ScreenView.screen2]);
    // }, 200);

    this.desktopScrollObserver();
    this.mobileScrollObserver();
  }

  desktopScrollObserver(): void {

    const SCROLL_UX_DELAY: number = 1000;

    fromEvent(this.element, 'scroll').pipe(
      tap(() => {
        this.element.style.overflowY = 'hidden';
        this.stateService.scrolled$$.next(false);
      }),
      debounceTime(SCROLL_UX_DELAY),
      tap(() => {
        this.element.style.overflowY = 'auto';
        this.stateService.scrolled$$.next(true);
      }),
      takeUntil(this.stateService.subscription$)
    ).subscribe();

    fromEvent(this.element, 'wheel').pipe(
      throttleTime(SCROLL_UX_DELAY),
      map((event: any) => {
        if (event.wheelDeltaX !== 0 || this.stateService.inputPending) return;
        const scrollDirection = event.wheelDeltaY > 0 ? 'up' : 'down'
        const currentIndex = SCREENS.indexOf(this.currentScreen);
  
        if (scrollDirection === 'down' && currentIndex !== -1 && currentIndex < SCREENS.length - 1) {
          const nextScreen = SCREENS[currentIndex + 1];
          this.scrollTo(this.stateService.view[nextScreen])
        }
        if (scrollDirection === 'up' && currentIndex > 0) {
          const nextScreen = SCREENS[currentIndex - 1];
          this.scrollTo(this.stateService.view[nextScreen])
        }
      }),
      takeUntil(this.stateService.subscription$),
    ).subscribe();
  }

  mobileScrollObserver(): void {

    let startY = 0;
    fromEvent<TouchEvent>(this.element, 'touchstart').pipe(
      takeUntil(this.stateService.subscription$),
      switchMap(startEvent => {
        startY = startEvent.touches[0].clientY;
        
        return fromEvent<TouchEvent>(this.element, 'touchend').pipe(
          first()
        );
      })
    ).subscribe(endEvent => {
      const currentIndex = SCREENS.indexOf(this.currentScreen);
      const endY = endEvent.changedTouches[0].clientY;
      const deltaY = startY - endY;
      
      if (deltaY > 50 && currentIndex < SCREENS.length - 1) {
        const newScreen = SCREENS[currentIndex + 1];
        this.scrollTo(this.stateService.view[newScreen] || 0);
      } else if (deltaY < -50 && currentIndex > 0) {
        const newScreen = SCREENS[currentIndex - 1];
        this.scrollTo(this.stateService.view[newScreen] || 0);
      }
    });
  }

  async scrollTo(value: number): Promise<void> {

    const valueIndex = Object.values(this.stateService.view).indexOf(value);
    this.currentScreen = Object.keys(this.stateService.view)[valueIndex] as ScreenView;
    this.currentScreen = value ? this.currentScreen : ScreenView.mainSelection;

    this.stateService.screenViewValue$$.next(this.currentScreen);

    this.element.scrollTo({
      top: value || 0, behavior: 'smooth'
    });
  }

  scrollToView(value: 'next' | 'previous'): void {
    const currentIndex = SCREENS.indexOf(this.currentScreen) as any;
    if (value === 'next') {
      const screen = `screen${currentIndex + 2}` as ScreenView;
      this.scrollTo(this.stateService.view[screen])
    }
    if (value === 'previous') {
      const screen = `screen${currentIndex - 2}` as ScreenView;
      this.scrollTo(this.stateService.view[screen])
    }
  }

}

@NgModule({
  declarations: [ HostScrollDirective ],
  exports:      [ HostScrollDirective ]
})
export class HostScrollDirectiveModule { }
