
<div class="ta-register--banner">
  <div class="ta-register--banner-overlay"></div>
  <img [lazyLoad]="service.destinationSpecialistImg" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" draggable="false" alt="Travel destination banner">
</div>

<div class="ta-register--content">
  
  <h1 translate="ta-register.destination"></h1>
  
  <h3 translate="ta-register.inbound-leads"></h3>
  <h3 translate="ta-register.export-itineraries"></h3>
  <h3 translate="ta-register.direct-messaging"></h3>

  <h3 class="info-text" translate="ta-register.apply-creator-house"></h3>

  <ui-action-button rounded (click)="tourAgentApply()">{{ 'ta-register.apply' | translate }}</ui-action-button>
  <br><br>
  <registration-form [class.disabled]="!(service.authenticated$ | async)" (click)="service.verifyAuthentication()"></registration-form>

</div>
