<ng-container *ngIf="stateService.myTripsViewState$ | async;else defaultView">

  <mat-icon (click)="navigateToDefaultView()">arrow_back</mat-icon>

  <div class="stepper--line stepper--line-mid"
    (click)="navigateToDefaultView()"
    *ngIf="service.animationOffset as animationOffset">
    <p
      [@textFadeTrigger]="{
        value: '1', params: { offset: animationOffset }
      }">
      Home
    </p>
  </div>

  <mat-icon
    class="stepper--circle-indicator"
    [ngClass]="{ active: true }">
    circle
  </mat-icon>

  <div
    class="stepper--line"
    [ngClass]="{ active: true }"
    *ngIf="service.animationOffset as animationOffset">
    <p
      [ngClass]="{ active: true }"
      [@textFadeTrigger]="{
        value: '1', params: { offset: -animationOffset }
      }">
      My Trips
    </p>
  </div>

  <mat-icon
    [ngClass]="{ active: true }">
    arrow_forward
  </mat-icon>

</ng-container>

<ng-template #defaultView>
  <ng-container *ngIf="service.navigationTextWithDirection$ | async as nav">
  
    <mat-icon
      [ngClass]="{ active: nav.highlight === 'prev' }"
      (click)="navigate(nav.prevNav)">
      arrow_back
    </mat-icon>
  
    <div
      class="stepper--line"
      [ngClass]="{ active: nav.highlight === 'prev' }"
      (click)="navigate(nav.prevNav)"
      *ngIf="service.animationOffset as animationOffset">
      <p [ngClass]="{ active: nav.highlight === 'prev' }"
        [@textFadeTrigger]="{
          value: nav.curr,
          params: { offset: nav.direction === 'forward' ? animationOffset : -animationOffset }
        }">
        {{ nav.prev }}
      </p>
    </div>
  
    <mat-icon
      class="stepper--circle-indicator"
      (click)="navigate(nav.prevNav)"
      [ngClass]="{ active: nav.highlight === 'prev' || nav.highlight === 'curr' }">
      circle
    </mat-icon>
  
    <div
      class="stepper--line stepper--line-mid"
      [ngClass]="{ active: nav.highlight === 'curr' }"
      (click)="navigate(nav.currNav)"
      *ngIf="service.animationOffset as animationOffset">
      <p [ngClass]="{ active: nav.highlight === 'curr' }"
        [@textFadeTrigger]="{
          value: nav.curr,
          params: { offset: nav.direction === 'forward' ? animationOffset : -animationOffset }
        }">
        {{ highlight || nav.curr }}
      </p>
    </div>
  
    <mat-icon
      class="stepper--circle-indicator"
      (click)="navigate(nav.nextNav)"
      [ngClass]="{ active: nav.highlight === 'curr' || nav.highlight === 'next' }">
      circle
    </mat-icon>
  
    <div
      class="stepper--line"
      [ngClass]="{ active: nav.highlight === 'next' }"
      (click)="navigate(nav.nextNav)"
      *ngIf="service.animationOffset as animationOffset">
      <p [ngClass]="{ active: nav.highlight === 'next' }"
        [@textFadeTrigger]="{
          value: nav.curr,
          params: { offset: nav.direction === 'forward' ? animationOffset : -animationOffset }
        }">
        {{ nav.next }}
      </p>
    </div>
  
    <mat-icon
      [ngClass]="{ active: nav.highlight === 'next' }"
      (click)="navigate(nav.nextNav)">
      arrow_forward
    </mat-icon>
  
  </ng-container>
</ng-template>
