import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProfileStateService, ProfileTabSelection } from '../../common';

@Injectable()
export class UserInfoMobileToolbarService {

  constructor(
    public stateService: ProfileStateService
  ) { }

  get selection$(): Observable<ProfileTabSelection> {
    return this.stateService.profileTabSelection$$.asObservable();
  }

  get settingsViewState$(): Observable<boolean> {
    return this.stateService.profileSettingsView$$.asObservable()
  }

  switchTab(selection: ProfileTabSelection): void {
    this.stateService.profileTabSelection$$.next(selection);
    this.stateService.profileSettingsView$$.next(false);
  }

  openSettingsView(): void {
    this.stateService.profileTabSelection$$.next('settings');
  }

}
