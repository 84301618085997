import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { ActivityDateData } from '@hiptraveler/data-access/api';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { PromptDialogActionService, PromptDialogStateService } from '@hiptraveler/dialogs/prompt-dialog';
import { SnackbarService } from '@hiptraveler/snackbar';
import { clientVID, currentLang, getWindowRef, requestEndpointParamValue, TranslationService } from '@hiptraveler/common';

@Injectable()
export class ActivityDateService {

  addingActivityDay: boolean = false;

  constructor(
    private store: Store,
    private promptDialog: PromptDialogActionService,
    private promptDialogState: PromptDialogStateService,
    private translation: TranslationService,
    private snackbar: SnackbarService
  ) { }

  /**
   * Adds a new activity day to the itinerary.
   * 
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   */
  async addActivityDay(): Promise<void> {

    const basicInfoId = this.store.selectSnapshot(ItineraryState.basicInfo)?.id || '';
    const activities = this.store.selectSnapshot(ItineraryState.actDateMap);
    const dayIndex = Object.keys(activities || {}).length + 1;
    const location = activities?.[dayIndex - 1].dayLocMap;

    if (dayIndex > 30) {
      const message = this.translation.getText('compose', 'day-limit-exceed-existing-itinerary');
      this.snackbar.open({ message, duration: 5000 });
      return;
    }

    if (this.addingActivityDay) return;
    this.addingActivityDay = true;

    try {
      await firstValueFrom(this.store.dispatch(new ItineraryAction.AddItineraryDay({
        id: basicInfoId,
        locMap: {
          id: location?.id || '',
          location: location?.city || '',
          country: location?.country || '',
          formattedAddr: location?.formatted_address || ''
        },
        param: getWindowRef()[requestEndpointParamValue],
        vId: clientVID(),
        dayIndex
      })));
    } catch (error) {
      this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
    } finally {
      this.addingActivityDay = false;
    }
  }

  /**
   * Deletes an activity day from the itinerary.
   */
  deleteActivityDay(activity: ActivityDateData): void {

    const confirmExecutable = async () => {
      const dialog = this.promptDialog.instance;
      try {

        if (!dialog) return;
        
        dialog.disableClose = true;
        this.promptDialogState.updateButtonState(0, true);
        
        await firstValueFrom(this.store.dispatch(new ItineraryAction.UpdateItineraryActivity({
          action: 'delete-day',
          param: getWindowRef()[requestEndpointParamValue],
          day: activity.day,
          lang: currentLang(),
          id: this.store.selectSnapshot(ItineraryState.basicInfo)?.id || ''
        })))
        this.snackbar.dismiss();
      } catch (response: any) {
        this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
      } finally {
        this.promptDialogState.updateButtonState(0, false);
        dialog!.disableClose = false;
        dialog!.close();
      }
    };
    
    const cancelExecutable = () => {
      this.promptDialog.instance.close();
    };

    const message = activity.hasMultiDayTour ? 'itinerary.clear-confirm' : `itinerary.delete-confirm`;

    this.promptDialog.open({
      message,
      title: 'itinerary.delete-day',
      messageParam: activity.hasMultiDayTour ? '' : activity.day.toString(),
      buttons: [
        { name: 'itinerary.button-text-confirm', theme: 'action', progressSpinner: true, executable: confirmExecutable },
        { name: 'itinerary.button-text-cancel', theme: 'secondary', executable: cancelExecutable }
      ]
    });
  }

}
