import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { distinctUntilChanged, map, tap } from 'rxjs';

import { AppListenerService, ScrollListenerService } from '@hiptraveler/common';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'map',
  template: `
    <div leafletMap [class.scrolled]="isScrolledDown" id="search-page-root-map" [@opacityFadeIn]>
      <div
        class="map--action-button mat-elevation-z4"
        matRipple
        [@opacityFadeIn_actionButton]
        (click)="toggleMapView()">
        {{ (appListener.mapExpansionState$ | async) ? 'Collapse Map View' : 'Expand Map View' }}
      </div>
    </div>
  `,
  styleUrls: ['./map.component.scss'],
  animations: [opacityFadeIn(2000), opacityFadeIn(500, '_actionButton')],
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnInit {

  isScrolledDown: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public appListener: AppListenerService,
    private scrollListener: ScrollListenerService
  ) {}

  ngOnInit(): void {

    const { documentElement, body } = this.document;
    this.isScrolledDown = (documentElement.scrollTop || body.scrollTop) >= 2;
    this.scrollListener.scrollPosition$.subscribe(scrollTop => this.isScrolledDown = scrollTop >= 2);

    this.appListener.clientWidth$.pipe(
      map(width => width < 1100 && width >= 700),
      distinctUntilChanged(),
      tap(state => this.isScrolledDown = state)
    ).subscribe();
  }

  toggleMapView(): void {
    const state = !this.appListener.mapExpansionState$$.value;
    this.appListener.mapExpansionState$$.next(state);
  }

}
