import { Directive, ElementRef, Input, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Map, GeoJSON, Marker, LatLng, DivIcon } from "leaflet";
import { Subject, filter, fromEvent, interval, map, takeUntil, tap } from "rxjs";
import { valid } from "geojson-validation";
import { filter as _filter, has } from 'lodash';

import { LeafletMapControlStateService } from "./leaflet-map-control-state.service";
import { GeoJSONFeatureCollection, GeoJSONProperties, getWindowRef, leafletNamespaceKey } from "@hiptraveler/common";
import { createMarkerIcons, getMarkerPopUpTemplate, getProfileMarkerPopUpTemplate, parseTextData, setNamespace, zoomPanOptions, MarkerIconRecord, SetMarkerOptions } from './utils';
import { computeMarkerZIndexBasedOnActivityCoordinates } from "./leaflet-map-fn";

const MUTATION_OBSERVER_CONFIG: MutationObserverInit = { childList: true, subtree: true };

@Directive()
export class LeafletMap implements OnDestroy {

  @Input() id: string;

  private mutation$ = new Subject<void>();
  private mapMarker: Marker;
  private geoJSONCollection: GeoJSON<any>[] = [];
 
  selectedMarker$ = new Subject<string>();
  leafletInitialized$ = new Subject<void>();
  subscription$ = new Subject<void>();
  mapView?: Map;
  mapMarkers: Marker[] = [];
  markerIcon: MarkerIconRecord;

  constructor(
    protected router: Router,
    protected elementRef: ElementRef<HTMLElement>,
    protected leafletControl: LeafletMapControlStateService
  ) { }

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  get Leaflet(): any {
    return getWindowRef()[leafletNamespaceKey];
  }

  ngOnDestroy(): void {
    try {
      this.mapView?.remove();
      this.mapView = undefined;
      this.mapMarkers = [];
      this.subscription$.next();
    } catch (error) { }
  }

  private setMarkersAndTemplates(leaflet: any): void {

    setNamespace(leaflet);

    this.markerIcon = createMarkerIcons(leaflet);
  }

  async setMap(): Promise<void> {

    if (this.Leaflet) {
      this.setMarkersAndTemplates(this.Leaflet);
      this.leafletInitialized$.next();
    }

    this.Leaflet || interval(100).pipe(
      map(() => this.Leaflet),
      filter(Boolean),
      takeUntil(this.leafletInitialized$)
    ).subscribe((leaflet: any) => {
      this.setMarkersAndTemplates(leaflet);
      this.leafletInitialized$.next();
    });

    try {
      this.mapView = this.Leaflet?.map(this.id, {
        zoomControl: false
      });

      this.Leaflet.control.zoom({ position: 'bottomright' }).addTo(this.mapView);

      this.updateMapView([ 0, 0 ], 2);

      this.Leaflet?.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        subdomains:['mt0','mt1','mt2','mt3']
      })?.addTo(this.mapView);
    } catch (error) {
      console.warn('@@@ leaflet-map.ts (setMap) ::', error);
    }
  }

  /**
   * Updates the map's center and zoom level.
   * @param coordinates - Center coordinates as [latitude, longitude].
   * @param zoom - Zoom level (default is 15).
   */
  updateMapView(coordinates: any, zoom: number = 15): void {
    if (Number.isNaN(coordinates?.[0])) return;
    try {
      this.mapView && this.mapView.setView(coordinates, zoom, zoomPanOptions);
    } catch (error) {
      console.warn('@@@ leaflet-map.ts (setMapView) ::', error);
    }
  }

  /**
   * Updates the map marker using the specified options.
   *
   * Removes any existing marker, creates a new one at the given location with the provided icon,
   * binds a popup based on the data (itinerary or profile), optionally opens the popup, and adds the marker to the map.
   *
   * @param options - The configuration for the marker.
   */
  setMarker(option: SetMarkerOptions): void {
    this.mapMarker?.remove();
    this.mapMarker = this.Leaflet?.marker(option.location, { icon: option.icon, zIndexOffset: 1000 });

    option.data?.image
      ? this.mapMarker.bindPopup(getMarkerPopUpTemplate({
        id: option.data.id || '',
        title: parseTextData(option.data.title),
        location: parseTextData(option.data.locationName),
        image: option.data.image || ''
      }))
      : this.mapMarker.bindPopup(getProfileMarkerPopUpTemplate({
        id: option.data.id || '',
        location: option.data.location as any
      }));

    option?.popup && this.mapMarker?.openPopup();

    this.mapView && this.mapMarker?.addTo(this.mapView);
  }

  setGeoJSON(result: GeoJSONFeatureCollection<GeoJSONProperties>, icon: DivIcon, zIndexOffset: number = 0): void {

    result.features = _filter(result.features, x => has(x.geometry, 'coordinates'));

    if (!valid(result) || !result?.features?.length || !this.Leaflet) return;

    const markerClickedFn = (value: any, latlng: LatLng) => {
      const marker = this.Leaflet.marker(latlng, { icon, zIndexOffset }).bindPopup(
        value.properties?.image
          ? getMarkerPopUpTemplate({
            id: value.properties.id || '',
            title: parseTextData(value.properties.title),
            location: parseTextData(value.properties.location),
            image: value.properties.image || 'assets/img/blank.webp'
          })
          : getProfileMarkerPopUpTemplate({
            id: value.properties.id || '',
            location: parseTextData(value.properties.location)
          })
      );

      this.mapMarkers.push(marker);
      return marker;
    }

    try {

      const geoJSONRef = this.Leaflet?.geoJSON(result, {
        pointToLayer: markerClickedFn
      });
      
      geoJSONRef?.addTo(this.mapView!);
      geoJSONRef && this.geoJSONCollection.push(geoJSONRef);
    } catch (error) {
      console.warn('@@@ leaflet-map.ts (setGeoJSON) ::', error);
    }
  }
  
  /**
   * Adjusts the map view to encompass the specified coordinates.
   *
   * This method creates a bounding box from the provided coordinates and fits the map view
   * to those bounds with a padding of 20 pixels. It also resets the itinerary's position.
   *
   * @param coordinates - An array of numbers representing the coordinates for the bounds.
   * @param callback - An optional callback function to execute after the bounds are set.
   */
  setMapBoundsByCoordinates(coordinates: number[][], callback?: () => void): void {
    try {
      const combinedBounds = this.Leaflet.latLngBounds(coordinates);
      this.mapView?.fitBounds(combinedBounds, { padding: [ 20, 20 ] });
    } catch (error) {
      console.warn('@@@ leaflet-map.ts (setMapBoundsByCoordinates) ::', error);
    } finally {
      callback?.();
    }
  }

  /**
   * Enforces a minimum zoom level after setting map bounds.
   *
   * Retrieves the current center and zoom level of the map, then after a short delay,
   * updates the map view to ensure that the zoom level is not below 2.
   */
  enforceMinimumZoomAfterBounds(): void {
    const mapCenter = [ this.mapView?.getCenter()?.lat, this.mapView?.getCenter()?.lng ];
    const mapZoom = this.mapView?.getZoom() || 2;
    setTimeout(() => this.updateMapView(mapCenter, mapZoom <= 2 ? 2 : mapZoom), 300);
  }

  /**
 * Highlights the marker with the specified property ID by setting its z-index offset to a high value,
 * resets all other markers' z-index offsets to zero, and opens the target marker's popup after a 500ms delay.
 *
 * @param id - The property ID of the marker to highlight and open.
   */
  highlightAndOpenMarkerPopup(id?: string): void {
    const targetMarker = this.mapMarkers.find(marker => marker.feature?.properties?.id === id);
    if (!targetMarker) return;
  
    // Reset z-index for all markers, then elevate the target marker.
    this.mapMarkers.forEach(marker => {
      const defaultZIndex = computeMarkerZIndexBasedOnActivityCoordinates(marker);
      marker.setZIndexOffset(marker === targetMarker ? 10000 : defaultZIndex);
    });
  
    setTimeout(() => targetMarker.openPopup(), 500);
  }

  /**
   * Resets the map state by clearing search results and removing all markers and layers.
   *
   * Sets the marker-bound flag to false, clears search results data, and calls removeAllMarkers()
   * to remove all map markers and associated GeoJSON layers.
   */
  clearAllLayers(): void {
    this.leafletControl.allSearchResultsData$$.next([]);
    this.removeAllMarkers();
  }

  /**
   * Clears all markers and GeoJSON layers from the map.
   * Removes the main marker, empties the markers array, and clears each GeoJSON layer.
   */
  removeAllMarkers(): void {
    this.mapMarker?.remove();
    this.mapMarkers = [];
    this.geoJSONCollection.forEach((res) => {
      res.remove();
      res.clearLayers();
    });
  }

  /**
   * Initializes an observer on the Leaflet popup pane to monitor for new popups.
   *
   * When a new node with the class 'ht-leaflet-popup' is added, waits 200ms and attaches a click observer
   * to its header (the first <h2> element).
   *
   * @param element The element containing the popup pane.
   */
  observePopupPane(element: HTMLElement): void {
    const popUpPane = element.querySelector('.leaflet-popup-pane');
    if (!popUpPane) return;

    const observer = new MutationObserver((mutationsList: MutationRecord[]) => {
      this.mutation$.next();
      mutationsList.forEach(mutation => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node: any) => {
            if (!node?.classList?.contains('ht-leaflet-popup')) return;
            setTimeout(() => {
              const header = node.querySelector('h2');
              if (header) this.observeMarkerClick(header);
            }, 200);
          });
        }
      });
    });

    observer.observe(popUpPane, MUTATION_OBSERVER_CONFIG);
  }

  /**
   * Observes click events on a marker element.
   *
   * When clicked, extracts the marker's identifier (the second class from its parent)
   * and emits it via the selectedMarker$ observable. Unsubscribes when mutation$ fires.
   *
   * @param element The marker element to observe.
   */
  private observeMarkerClick(element: HTMLElement): void {
    fromEvent(element, 'click').pipe(
      tap(() => {
        const identifier = element.parentElement!.classList.item(1) || '';
        this.selectedMarker$.next(identifier);
      }),
      takeUntil(this.mutation$)
    ).subscribe();
  }

}
