import { Directive, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { combineLatest, map, Observable } from 'rxjs';

import { ActivityDateData, HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';
import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryActivityPosition, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { DialogType, SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { LeafletMapControlStateService } from '@hiptraveler/features/leaflet-map';
import { DriveDurationComponent } from './drive-duration/drive-duration.component';
import { AppListenerService, clientVID } from '@hiptraveler/common';

/**
 * Finds the closest ancestor element with a specific class name.
 * 
 * @param {HTMLElement} element - The element to start searching from.
 * @param {string} className - The class name to search for.
 * @returns {HTMLElement | null} The found ancestor element, or null if not found.
 */
function findParentWithClass(element: HTMLElement, className: string): HTMLElement | null {
  let parent = element?.parentElement;
  while (parent && !parent.classList.contains(className)) {
    parent = parent?.parentElement;
  }
  return parent || null;
}

@Directive()
export class FeatureCard<T> {

  @ViewChild(DriveDurationComponent) driveDuration: DriveDurationComponent;

  @Output() addActivity = new EventEmitter<void>();
  @Output() noteClicked = new EventEmitter<void>();
  @Output() removeClicked = new EventEmitter<void>();
  @Output() readMoreClicked = new EventEmitter<void>();
  @Output() positionMoved = new EventEmitter<ItineraryActivityPosition>();

  @Input() activity: ActivityDateData;
  @Input() resultContent: T;
  @Input() index: number;
  @Input() view: boolean;

  pendingSecondary: boolean = false;

  constructor(
    private store: Store,
    private appListener: AppListenerService,
    private searchResultDialog: SearchResultDialogActionService,
    private leafletControl: LeafletMapControlStateService
  ) { }

  /**
   * Handles click events on the feature card.
   * 
   * @param {PointerEvent} event - The click event.
   */
  @HostListener('click', ['$event'])
  hostClicked(event: PointerEvent): void {

    if (this.driveDuration) {
      this.driveDuration.hostIndex = this.activity.day;
    }

    const element = findParentWithClass(event?.target as HTMLElement, 'ht-itinerary-feature-card') as HTMLElement;
    const contentActionContainer = findParentWithClass(event?.target as HTMLElement, 'itinerary-feature-card--content-actions') as HTMLElement;
    const actionButtons = findParentWithClass(event?.target as HTMLElement, 'itinerary-feature-card--action-buttons') as HTMLElement;

    if (contentActionContainer || actionButtons || !element) return;
    
    const type = element?.getAttribute('type');
    const dialogType: DialogType = type === 'amusement' ? 'adventure' : type === 'consume' ? 'food' : 'hotel';

    this.searchResultDialog.open(this.resultContent, dialogType);
  }

  get itineraryOwner$() {
    return combineLatest([
      this.store.select(UserState.profileId),
      this.store.select(ItineraryState.basicInfo),
    ]).pipe(
      map(([ profileId, basicInfo ]) => 
        basicInfo?.author?.authorTitle === profileId
        || clientVID() === basicInfo?.author?.authorProfId
      )
    );
  }

  get activityDurationState$(): Observable<boolean> {
    return this.store.select(ItineraryState.basicInfo).pipe(
      map(e => !!e?.autoGenerated)
    );
  }

  primaryClick(type: 'image' | 'hotel'): void {
    if (type === 'image') {
      const imgCategory = (this.resultContent as any).imgCategory.toLowerCase();
      (imgCategory === 'chill time') ? this.addActivity.emit() : this.noteClicked.emit();
    } else {
      this.noteClicked.emit();
    }
  }

  secondaryClick(): void {
    this.view ? this.readMoreClicked.emit() : this.removeClicked.emit();

    if (this.view) return;

    this.pendingSecondary = true;

    this.appListener.globalSignalListener('removeClickedProgress').subscribe(() => {
      this.pendingSecondary = false;
    });
  }

  onFeatureCardHover(value: any): void {
    const data = value as ImageResultData & HotelResultData;
    this.leafletControl.activeSearchResultData$$.next({
      id: data.id || 'unknown',
      title: data.name || '',
      image: data.imgUrl || '',
      address: data.address,
      location: {
        ...data.loc,
        coordinates: [ +data?.latitude, +data?.longitude ]
      },
      locationName: data.location || `${data.city} ${data.countryCd}`
    });
  }

}
