import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, filter, map, takeUntil } from 'rxjs';

import { GetAmbassadorData, GetAmbassadorDataResponse, ProfileApiService, UserProfile } from '@hiptraveler/data-access/api';
import { ProfileState } from '@hiptraveler/data-access/profile';
import { getWindowRef } from '@hiptraveler/common';

const data: GetAmbassadorData = {
  "channelMetrics": {
    "name": "Swati Baumgartner",
    "email": "swati911@gmail.com",
    "location": "San Diego, CA, USA",
    "referrer": "hiptraveler",
    "totalFollowers": 4470,
    "socialChannels": [{
        "channelName": "Facebook",
        "handle": "hiptraveler",
        "link": "https://facebook.com/131183446931757",
        "engagement": 5.4,
        "likeCount":4531,
        "followerCount": 4470
      },
      {
        "channelName": "Twitter",
        "handle": "hiptraveler",
        "link": "https://x.com/hiptraveler",
        "engagement": 5.4,
        "likeCount":4531,
        "followerCount": 4470
      },
      {
        "channelName": "Instagram",
        "handle": "hiptraveler",
        "link": "https://www.instagram.com/hiptraveler",
        "engagement": 5.4,
        "likeCount":4531,
        "followerCount": 4470
      },
      {
        "channelName": "TikTok",
        "handle": "hiptraveler",
        "link": "https://www.tiktok.com/hiptraveler",
        "engagement": 5.4,
        "likeCount":4531,
        "followerCount": 4470
      }
    ],
    "niche": "cultural_immersion, natural_wonders, family",
    "topMarket": null,
    "date": "Feb 15, 2024"
  },
  "moreResults": false
};

@Injectable()
export class SocialHandlesSectionService implements OnDestroy {

  #response$$ = new BehaviorSubject<GetAmbassadorData | null>(null);
  // #response$$ = new BehaviorSubject<GetAmbassadorData | null>(data);
  response$ = this.#response$$.asObservable();

  subscription = new Subject<void>();

  constructor(
    private store: Store,
    private api: ProfileApiService
  ) { }

  ngOnDestroy(): void {
    this.subscription.next();
  }

  get ambassadorUser$(): Observable<boolean> {
    return this.store.select(ProfileState.profileDetails).pipe(
      map((profileDetails: UserProfile | null) => !!profileDetails?.ambUser),
      filter(Boolean),
      takeUntil(this.subscription)
    );
  }

  getRequestData(): void {
    const profileId = this.store.selectSnapshot(ProfileState.profileDetails)?.profileId;
    profileId && this.api.getAmbassadorDataById(profileId).subscribe((response: GetAmbassadorDataResponse) => {
      response?.data?.channelMetrics?.socialChannels?.length && this.#response$$.next(response?.data);
    });
  }

  visitProfileByLink(link: string): void {
    getWindowRef()?.open(link, '_blank');
  }

}
