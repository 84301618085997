import { Store } from '@ngxs/store';

import { adventure, hotel, foodAndDrinks, getWindowRef, globalStateManagementStoreKey, convertSvgToDataUrl, SearchResultDataType } from '@hiptraveler/common';
import { BrandState } from '@hiptraveler/data-access/brand';

type Activity = 'adventure' | 'hotel' | 'foodAndDrinks';

/**
 * Retrieves a search result icon URL in WebP format based on the given category.
 *
 * This function generates SVG markup for the specified category ('adventure', 'hotel',
 * or 'foodAndDrinks') using a fallback SVG generator, applies the primary color from the
 * global brand state, converts the SVG into a WebP data URL, and returns it as a Promise.
 *
 * @param category - The search result category to generate the icon for.
 * @returns A Promise that resolves to a WebP data URL of the icon.
 */
export function fetchSearchResultIconUrl(category: Activity | SearchResultDataType): Promise<string> {

  const mapCategory = (cat: any): Activity => {
    if (cat === 'food') return 'foodAndDrinks';
    if (cat === 'itinerary') return 'foodAndDrinks';
    return cat;
  };

  // Retrieve the global store from the window reference.
  const store: Store = getWindowRef()[globalStateManagementStoreKey];

  // Fallback SVG generator functions for each category.
  const svgGenerators = { adventure, hotel, foodAndDrinks };

  // Get the primary color from the BrandState; fallback to default if unavailable.
  const primaryColor = store.selectSnapshot(BrandState.campaignCustom)?.colorPalette?.mainColor || '#00a6ff';

  // Generate the SVG markup for the provided category using the corresponding generator.
  const svgMarkup = svgGenerators[mapCategory(category)](primaryColor);

  // Convert the generated SVG into a WebP data URL and return it.
  return convertSvgToDataUrl(svgMarkup);
}
