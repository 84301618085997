import { DivIconOptions, ZoomPanOptions } from "leaflet";

import { getAdventureMarkerTemplate, getFoodMarkerTemplate, getHotelMarkerTemplate, getMapMarkerTemplate, getProfileMarkerTemplate } from "./leaflet-map-templates";
import { MarkerIconRecord } from "./leaflet-map.interface";

export const markerDivIcon: DivIconOptions = {
  className: 'ht-leaflet-marker-container',
  iconSize: [ 28, 43 ],
  iconAnchor: [ 14, 10 ],
  popupAnchor: [ 0, 0 ],
};

export const zoomPanOptions: ZoomPanOptions = {
  animate: true,
  duration: 0.5,
  easeLinearity: 0.1,
  noMoveStart: true
};

export function createMarkerIcons(value: any): MarkerIconRecord {
  
  return {

    adventureMarkerIcon: value.divIcon({
      ...markerDivIcon,
      html: getAdventureMarkerTemplate()
    }),

    hotelMarkerIcon: value.divIcon({
      ...markerDivIcon,
      html: getHotelMarkerTemplate()
    }),

    foodMarkerIcon: value.divIcon({
      ...markerDivIcon,
      html: getFoodMarkerTemplate()
    }),

    profileMarkerIcon: value.divIcon({
      ...markerDivIcon,
      html: getProfileMarkerTemplate()
    }),

    mapMarkerIcon: value.divIcon({
      ...markerDivIcon,
      html: getMapMarkerTemplate()
    })

  }
}
