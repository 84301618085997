
/**
 * Converts SVG markup into a WebP data URL.
 *
 * @param svgMarkup - The SVG markup as a string.
 * @returns A Promise that resolves to the WebP data URL.
 */
export function convertSvgToDataUrl(svgMarkup: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const imageElement = new Image();
    const svgBlob = new Blob([svgMarkup], { type: 'image/svg+xml' });
    const objectUrl = URL.createObjectURL(svgBlob);

    imageElement.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = imageElement.width;
      canvas.height = imageElement.height;

      const context = canvas.getContext('2d');
      context?.drawImage(imageElement, 0, 0);

      const webpDataUrl = canvas.toDataURL('image/webp');
      resolve(webpDataUrl);

      URL.revokeObjectURL(objectUrl);
    };

    imageElement.onerror = (error) => reject(error);
    imageElement.src = objectUrl;
  });
};
