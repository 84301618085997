import { Injectable } from '@angular/core';
import { ActionCompletion, Actions, ofActionCompleted, Store } from '@ngxs/store';
import { delay, filter, Subject, takeUntil, tap } from 'rxjs';
import { Map, Marker } from 'leaflet';

import { ItineraryAction } from '@hiptraveler/data-access/itinerary';
import { getMarkerPopUpTemplate, MarkerIconRecord, parseTextData, toGeoMapData } from '../utils';
import { SearchState } from '@hiptraveler/data-access/search';
import { iconByType } from './search-page-map-fn';

export interface LeafletRef {
  Leaflet: any;
  mapView: Map;
  mapMarkers: Marker[];
  markerIcon: MarkerIconRecord;
  subscription$: Subject<void>;
}

@Injectable()
export class NewItineraryObserverService {

  private mapMarkersAdded: Marker[] = [];

  observing: boolean;

  constructor(
    private store: Store,
    private actions$: Actions
  ) { }

  observe({ Leaflet, mapView, mapMarkers, markerIcon, subscription$ }: LeafletRef) {

    this.observing = true;

    const addNewMarkerByCoordinates = (itinerary: any, day: number, coordinates: number[]) => {

      console.log('@@@ addNewMarkerByCoordinates', itinerary, day, coordinates);
      console.log('@@@ addNewMarkerByCoordinates :: markerIcon', markerIcon);

      const icon = iconByType(itinerary, day, markerIcon);
      const mapMarker = Leaflet.marker(coordinates, { icon, zIndexOffset: 1000 });
      const geoData = toGeoMapData(itinerary);

      const targetMarker = mapMarkers.find(marker => marker.feature?.properties?.id === geoData?.id);
      targetMarker?.remove();
      
      const newMarker = mapMarker.bindPopup(getMarkerPopUpTemplate({
        id: geoData?.id || '',
        title: parseTextData(geoData?.title),
        location: parseTextData(geoData?.locationName),
        image: geoData?.image || 'assets/img/blank.webp'
      })).addTo(mapView);

      this.mapMarkersAdded.push(newMarker);
      newMarker?.openPopup();

      console.log('@@@ addNewMarkerByCoordinates :: mapMarkersAdded', this.mapMarkersAdded);
    };

    this.actions$.pipe(
      ofActionCompleted(ItineraryAction.UpdateItineraryActivityByResponse),
      filter(e => e.result.successful),
      delay(350),
      tap((result: ActionCompletion<ItineraryAction.UpdateItineraryActivityByResponse>) => {

        const {
          dto: { item: itinerary = '', day = 1 },
          response: { actDateMap }
        } = result.action.data;
        
        const { ImgArray, HotelArray } = actDateMap[day] || {};
        const image = ImgArray?.find(e => e.id === itinerary);
        const hotel = HotelArray?.find(e => e.id === itinerary);
        
        console.log('@@@ ItineraryAction.UpdateItineraryActivityByResponse', image, hotel);

        [ image, hotel ].forEach(item => 
          item && addNewMarkerByCoordinates(item, day, [ +item.latitude, +item.longitude ])
        );
      }),
      takeUntil(subscription$)
    ).subscribe();

    this.actions$.pipe(
      ofActionCompleted(ItineraryAction.UpdateItineraryActivity),
      filter(e => e.result.successful),
      delay(350),
      tap((result: ActionCompletion<ItineraryAction.UpdateItineraryActivity>) => {

        const { day = 1, item: itinerary } = result.action.data;
        
        const poi = [
          ...this.store.selectSnapshot(SearchState.adventures) || [],
          ...this.store.selectSnapshot(SearchState.foods) || [],
          ...this.store.selectSnapshot(SearchState.hotels) || []
        ].find(e => e.id === itinerary);
       
        console.log('@@@ ItineraryAction.UpdateItineraryActivity', itinerary, day, poi);
        
        poi && addNewMarkerByCoordinates(poi, day, [ +poi.latitude, +poi.longitude ])
      }),
      takeUntil(subscription$)
    ).subscribe();
  }

  removeAllAddedMarkers(): void {
    this.mapMarkersAdded.forEach((marker: Marker) => {
      marker.remove();
    });
  }

}
