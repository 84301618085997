import { Injectable, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngxs/store';
import { debounceTime, filter, firstValueFrom, map, Subject, takeUntil, tap } from 'rxjs';
import { concat } from 'lodash';

import { BrandState } from '@hiptraveler/data-access/brand';
import { filterQueryStringKey, getLocationData, getWindowRef, queryStringToObject, SearchLocationService, SearchPageControlStateService } from '@hiptraveler/common';
import { ViewService, FiltersStateService } from '.';
import { FiltersService } from '../filters.service';
import { param, paramObject, processFilters, queryParams, toCheckInDateRange } from './filters-state-listener-fn';

/**
 * The time (in milliseconds) to wait before enabling URL updates.
 * This prevents immediate updates during component initialization or rapid events.
 */
const DELAY_BEFORE_URL_UPDATE_ENABLED: number = 700;

@Injectable()
export class FiltersStateListenerService implements OnDestroy {

  /**
   * Flag indicating whether the service is allowed to update the browser's URL.
   * This is set to true after the specified delay to avoid excessive initial updates.
   */
  private isUrlUpdateEnabled: boolean;
  subscription$ = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private store: Store,
    private searchLocation: SearchLocationService,
    private searchPageControl: SearchPageControlStateService,
    private service: FiltersService,
    private viewService: ViewService,
    private filterState: FiltersStateService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  async filterServiceListener(): Promise<void> { // Methods facade

    await firstValueFrom(this.store.select(BrandState.experiences).pipe(filter(Boolean)));
    
    this.activateUrlQueryUpdates();
    this.subscribeToDateQueryParams();
    this.subscribeToKeywordUpdates();
    this.subscribeToFilterQueryUpdates();
    this.applyUrlFilters();
    this.updateUrlWithGlobalFilter();
    this.resetOnItineraryRemoval();
  }

  /**
   * Activates URL query updates after a brief delay.
   */
  private activateUrlQueryUpdates(): void {
    setTimeout(() => {
      this.isUrlUpdateEnabled = true;
    }, DELAY_BEFORE_URL_UPDATE_ENABLED);
  }

  /**
   * Subscribes to form value changes for the check-in and check-out dates,
   * converts them into a query parameter range via `toCheckInDateRange()`,
   * and updates the URL accordingly with the new query parameters.
   */
  private subscribeToDateQueryParams(): void {
    this.service.form.valueChanges.pipe(
      map(({ checkInDate, checkOutDate }) => toCheckInDateRange({ checkInDate, checkOutDate })),
      takeUntil(this.subscription$)
    ).subscribe(queryParams => this.updateBrowserUrlQuery(queryParams));
  }

  /**
   * Subscribes to changes on the 'query' form control,
   * debounces rapid input to improve performance,
   * and updates the URL's query parameters with the latest keyword.
   */
  private subscribeToKeywordUpdates(): void {
    this.service.form.get('query')?.valueChanges.pipe(
      debounceTime(350),
      takeUntil(this.subscription$)
    ).subscribe(keyword => this.updateBrowserUrlQuery({ keyword }));
  }

  /**
   * Subscribes to filter query updates by initializing both page travel style and travel style subscriptions.
   */
  private subscribeToFilterQueryUpdates(): void {
    this.subscribeToPageTravelStyleUpdates();
    this.subscribeToTravelStyleUpdates();
  }

  /**
   * Subscribes to updates of selected filter item keys.
   * Retrieves the current search page parameter, converts the array of selected keys into a comma-separated string,
   * and updates the browser's URL query with this new value.
   */
  private subscribeToPageTravelStyleUpdates(): void {
    this.filterState.selectedItemKeys$.pipe(
      takeUntil(this.subscription$)
    ).subscribe((values: string[]) => {
      const key = param(this.filterState.searchPage);
      this.updateBrowserUrlQuery({ [key]: `${values.join(',')}` });
    });
  }

  /**
   * Subscribes to updates of selected travel style keys.
   * Waits for the view service to provide the list of activities, transforms the received values along with the activities
   * into query parameters, and updates the browser's URL query accordingly.
   */
  private subscribeToTravelStyleUpdates(): void {
    this.filterState.selectedTravelStyleKeys$.pipe(
      takeUntil(this.subscription$)
    ).subscribe(async (values: string[]) => {
      const { activities } = await this.viewService.listItems;
      const queryParams = queryStringToObject(paramObject(values, activities));
      this.updateBrowserUrlQuery(queryParams);
    });
  }

  /**
   * Applies URL query parameters to initialize the filter form and update filter state.
   */
  private applyUrlFilters(): void {

    const query = queryStringToObject(getWindowRef()[filterQueryStringKey]);
    const { activities, all } = this.viewService.listItemsByPath;

      this.service.form.patchValue({
        query: query['keyword'],
        checkInDate: query['checkInDate'] ? new Date(query['checkInDate']) : '',
        checkOutDate: query['checkOutDate'] ? new Date(query['checkOutDate']) : ''
      });
    this.filterState.updateSelectedItems(concat(
      processFilters('activities', all),
      processFilters('amenities', all),
      processFilters('cuisine', all)
    ));
    this.filterState.updateSelectedTravelStyles(concat(
      processFilters('experiences', activities),
      processFilters('travelStyle', activities)
    ));

    const globalFilterQuery: string = getWindowRef()[filterQueryStringKey] || '';
    this.location.replaceState(`${getLocationData().pathname}${globalFilterQuery}`);
  }

  /**
   * Synchronizes the browser URL with the global filter query whenever a navigation ends.
   */
  private updateUrlWithGlobalFilter(): void {
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      filter(() => this.searchLocation.searchPageRoute),
      tap(() => {
        const globalFilterQuery: string = getWindowRef()[filterQueryStringKey] || '';
        this.location.replaceState(`${getLocationData().pathname}${globalFilterQuery}`);
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Listens for itinerary removal events and resets filters and form.
   * When an itinerary is removed, this method clears all filters and resets the form.
   */
  private resetOnItineraryRemoval(): void {
    this.searchPageControl.itineraryRemoved$.pipe(
      takeUntil(this.subscription$)
    ).subscribe(() => {
      this.filterState.clearFilters();
      this.service.form.reset();
    });
  }

  /**
   * Updates the browser URL with the provided query parameters.
   *
   * @param query - An object containing query parameters to be appended to the URL.
   */
  private updateBrowserUrlQuery(query: any): void {

    const queryString = queryParams(query);
    const currentPath = getLocationData().pathname;
    const newUrl = `${currentPath}${queryString}`.replace(/\?$/, '');

    if (!this.isUrlUpdateEnabled || queryString === '?') return;
    
    this.location.replaceState(newUrl);
    getWindowRef()[filterQueryStringKey] = queryString;
  }

}
