import { Directive, HostListener, Input } from '@angular/core';
import { Store } from '@ngxs/store';

import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { getWindowRef, promiseDelay } from '@hiptraveler/common';

@Directive({
  selector: '[driveDurationAction]'
})
export class DriveDurationActionDirective {

  @Input() hostIndex?: number;
  @Input() index?: number;

  constructor(
    private store: Store
  ) { }

  @HostListener('click')
  async hostClick(): Promise<void> {
    await promiseDelay(100);

    const actDateMap = this.store.selectSnapshot(ItineraryState.actDateMap);
    const activityDate = actDateMap?.[this.hostIndex || 0];
    const activityItem = activityDate?.ImgArray?.[this.index || 0];
    const dayTimeActivity = activityDate?.dayTimeActivity?.filter(e => e.activityType === 'commute')?.find(e => e?.objId === activityItem?.id);

    if (!actDateMap || !activityItem?.id || !this.hostIndex) return;

    if (dayTimeActivity?.viatorTour && activityItem?.imgSource) {
      return getWindowRef()?.open(activityItem.imgSource, '_break');
    }

    this.openGoogleMaps();
  }

  openGoogleMaps(): void {

    const actDateMap = this.store.selectSnapshot(ItineraryState.actDateMap);
    const activityDate = actDateMap?.[this.hostIndex || 0];
    const activityItem = activityDate?.ImgArray?.[this.index || 0];

    if (!actDateMap || !activityItem?.id || !this.hostIndex) return;

    const from = this.index ? activityDate?.ImgArray?.[this.index - 1] : activityDate?.HotelArray?.[0];
    const to = activityItem;

    const [ from_lng, from_lat ] = from?.loc?.coordinates || [];
    const [ to_lng, to_lat ] = to?.loc?.coordinates || [];

    if (!from_lat || !from_lng || !to_lat || !to_lng) return;

    const googleMapsUrl = `https://www.google.com/maps/dir/${from_lat},${from_lng}/${to_lat},${to_lng}`;
    getWindowRef()?.open(googleMapsUrl, '_break');
  }

}
