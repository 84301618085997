import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { ToItineraryDateModule } from '@hiptraveler/features/itinerary';
import { CustomMapAutocompleteModule } from '@hiptraveler/features/custom-map-autocomplete';

import { ActivityDayHeaderComponent } from './activity-day-header.component';
import { ToItineraryDatePrefixStringPipe } from './to-itinerary-date-prefix';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const LIBRARIES = [
  ToItineraryDateModule,
  CustomMapAutocompleteModule
];

const COMPONENTS = [
  ActivityDayHeaderComponent
];

const PIPES = [
  ToItineraryDatePrefixStringPipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    ActivityDayHeaderComponent
  ]
})
export class ActivityDayHeaderModule { }
