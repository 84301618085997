import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { ActivityDateData } from '@hiptraveler/data-access/api';
import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';

@Injectable()
export class TravelMediumService {

  constructor(
    private store: Store
  ) { }

  get transitLabelByDay(): string {

    const originLocation = this.store.selectSnapshot(ItineraryState.basicInfo)?.currentLocation || this.store.selectSnapshot(UserState.state)?.userLoc;
    const activities = this.store.selectSnapshot(ItineraryState.actDateMap);
    const activityDate = activities?.[1];

    if (!originLocation || !activityDate?.dayLocMap) return '';

    const { city: to_city, country: to_country } = this.getDestinationLocation(activityDate);

    return `From ${originLocation} to ${to_city}, ${to_country}`;
  }

  private getDestinationLocation(activityDate: Partial<ActivityDateData>): { city: string; country: string } {

    const { dayLocMap, HotelArray, ImgArray } = activityDate || {};
    const hotelAddress = HotelArray?.[0]?.address;
    const imgAddress = ImgArray?.[0]?.address;
  
    return {
      city: dayLocMap?.city || hotelAddress?.city || imgAddress?.city || '',
      country: dayLocMap?.country || hotelAddress?.country || imgAddress?.country || '',
    };
  }

}
