import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { UserItineraryData } from '@hiptraveler/data-access/api';
import { UserState } from '@hiptraveler/data-access/user';
import { CDNReaderService } from '@hiptraveler/common';

@Injectable()
export class MyTripsService {

  @Select(UserState.itineraries) itineraries$: Observable<UserItineraryData[] | null>;

  createNewTripButtonState$$ = new BehaviorSubject<boolean>(false);

  constructor(
    private cdnReader: CDNReaderService
  ) { }

  get myTripIcon$(): Observable<string> {
    return this.cdnReader.getCdnData('base64.experienceFinderAiImages').pipe(
      map(e => e['my-trip-icon'])
    );
  }

}
