import { MatSnackBarConfig } from "@angular/material/snack-bar";

import { getWindowRef, i18nTranslationsKey } from "@hiptraveler/common";
import { SnackbarConfig } from "./snackbar.interface";

/**
 * Builds a Material Snackbar configuration object from the given options.
 * Provides default values for duration, panel class, and positioning.
 */
export function buildSnackbarConfig({
  duration = 4000,
  class: panelClass = 'hiptraveler-snackbar',
  vertical: verticalPosition = 'bottom',
  horizontal: horizontalPosition = 'center'
}: SnackbarConfig): MatSnackBarConfig<SnackbarConfig> {
  return { duration, panelClass, verticalPosition, horizontalPosition };
}

/**
 * Attempts to translate the given message using the global i18n translations, if available.
 */
export function translateMessage(message: string): string {
  const translation = getWindowRef()[i18nTranslationsKey];
  return translation?.[message] ?? message;
}
