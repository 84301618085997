
let _windowRef: any = {};

/** Sets the global window reference. */
export const setWindowRef = (ref: any): void => _windowRef = ref;
/** Retrieves the global window reference. */
export const getWindowRef = (): any => _windowRef;


type LocationDataKey = 'pathname' | 'search' | 'locationString';

/**
 * Retrieves the current window location data.
 *
 * This function extracts the `pathname` and `search` string from the window's location,
 * and constructs a combined `locationString`.
 *
 * @returns An object containing the pathname, search, and a concatenated locationString.
 */
export function getLocationData(): Record<LocationDataKey, string> {
  const { pathname = '', search = '' } = getWindowRef()?.location ?? {};
  return {
    pathname, search,
    locationString: `${pathname}${search}`,
  };
}
