import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map, switchMap } from 'rxjs';

import { BrandCampaign, FlightRoute } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { CDNReaderService, SkyscannerWidgetType } from '@hiptraveler/common';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'skyscanner-view',
  templateUrl: './skyscanner-view.component.html',
  styleUrls: ['./skyscanner-view.component.scss'],
  host: { class: 'skyscanner-view' },
  viewProviders: [ CDNReaderService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFadeIn(2000) ]
})
export class SkyscannerViewComponent {

  @Input() activeWidget: SkyscannerWidgetType = 'FlightSearchWidget';
  @Input() data: FlightRoute;
  
  constructor(
    private store: Store,
    private cdnReader: CDNReaderService
  ) { }

  get travelImage$(): Observable<string> {
    return this.cdnReader.getCdnData('base64.appCommon').pipe(
      switchMap((response: any) => this.store.select(BrandState.brandCampaign).pipe(
        map((brandCampaign: Partial<BrandCampaign> | null) => {
          return brandCampaign?.campaignCustom?.customCss?.travelSvg || response['flight'];
        })
      ))
    )
  }

}
