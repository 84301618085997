import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, Inject, Input, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import Typed from 'typed.js';

import { EFAStateServiceService, ScreenView } from '../../shared';

@Directive({
  selector: '[textTyped]'
})
export class TextTypedDirective implements AfterViewInit, OnDestroy {

  @Input() textTyped: string;

  subscription$ = new Subject<void>();
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private stateService: EFAStateServiceService
  ) { }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;

    this.initializeTextUX();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  initializeTextUX(): void {

    const typed = new Typed(`#welcome-view-header-${this.textTyped}`, {
      strings: [
        'Hiyaa traveler! How can I help you?',
        'Hey there, traveler! Where to next?',
        'Ready for your next adventure? Ask me anything!',
        'Hi traveler! Let\'s plan your perfect journey!',
        'Hi traveler! Where can I take you today?',
        'Hello, adventurer! How can I assist you?',
        'Hey! Ready to explore? How can I help?',
        'Let me guide you! What’s your next destination?',
        'Where would you like to wander today, traveler?',
        'Hiyaa traveler! How can I help you?'
      ],
      typeSpeed: 15,
      cursorChar: '',
      backDelay: 3500,
      loop: true,
      loopCount: 10
    });

    this.stateService.screenViewValue$.subscribe((screen: ScreenView) => {
      if (screen === ScreenView.mainSelection) {
        typed.start();
      } else {
        setTimeout(() => typed.reset(), 300);
      }
    });
  }

}
