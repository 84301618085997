import { Directive, ElementRef, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';

import { HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';
import { LeafletMap, LeafletMapControlStateService } from '@hiptraveler/features/leaflet-map';
import { getTypeByItinerary } from '../utils';
import { mapItineraryToSearchResult } from './search-result-dialog-fn';

@Directive({
  selector: '[leafletMap]',
  host: { class: 'ht-custom-leaflet-map' }
})
export class SearchResultDialogMapDirective extends LeafletMap implements OnInit {

  @Input() data?: ImageResultData | HotelResultData;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    a: Router, b: ElementRef<HTMLElement>, c: LeafletMapControlStateService
  ) {
    super(a, b, c);
  }
  
  ngOnInit(): void {

    if (isPlatformServer(this.platformId)) return;
   
    this.setMap();
    this.displayItineraryMarkerOnMap();
  }

  /**
   * Listens for the Leaflet map initialization event and then displays the itinerary marker on the map.
   */
  private displayItineraryMarkerOnMap(): void {
    this.leafletInitialized$.pipe( takeUntil(this.subscription$) ).subscribe(() => {

      const { data, location } = mapItineraryToSearchResult(this.data)

      this.setMarker({ location, data, icon: this.markerIcon[`${getTypeByItinerary(this.data)}MarkerIcon`] });
      this.updateMapView([ location[0] - 0.0063, location[1] ]);
    });
  }

}
