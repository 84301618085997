import { getWindowRef, PromiseReject, PromiseResolve } from "@hiptraveler/common";

declare const leaflet: any;

export function leafletClient(): Promise<any> {
  return new Promise((resolve: PromiseResolve, reject: PromiseReject) => {

    const windowRef = getWindowRef();

    let
      bodyElement: HTMLBodyElement | null = null,
      scriptElement: HTMLScriptElement | null = null;
  
    if (typeof windowRef === 'object' && windowRef) {
      bodyElement = windowRef?.document?.body as HTMLBodyElement;
      scriptElement = windowRef?.document?.createElement('script') as HTMLScriptElement;
    }
  
    if (!bodyElement || !scriptElement) return;

    scriptElement.src = 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.4/leaflet.js';
    scriptElement.async = true;

    bodyElement.appendChild(scriptElement);

    scriptElement.onload = () => {
      try {

        resolve(leaflet);
        console.log('** 3rd party CDN: Leaflet script loaded successfully.');
      } catch (innerError) {

        reject(innerError);
        console.error('Error initializing Leaflet:', innerError);
      }
    };
  });
}
