import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { SearchPageRootService } from './search-page-root.service';
import { AppListenerService, SearchPageControlStateService } from '@hiptraveler/common';
import { FiltersProviders } from './filters';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'app-search-page-root',
  templateUrl: './search-page-root.component.html',
  styleUrls: ['./search-page-root.component.scss', './search-page-root-default-responsiveness.scss', './search-page-root-search-responsiveness.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ SearchPageRootService, FiltersProviders ],
  animations: [ opacityFade() ]
})
export class SearchPageRootComponent implements OnInit {

  loadMoreVisibility: boolean = false;

  constructor(
    public service: SearchPageRootService,
    public appListener: AppListenerService,
    public searchPageControl: SearchPageControlStateService
  ) { }

  ngOnInit(): void {
    this.service.observeHomepage();
    this.service.observeMapViewState();

    this.service.loadMoreDisplayVisibility$.subscribe(
      (visibility: boolean) => (this.loadMoreVisibility = visibility)
    );
  }

}
