import { AfterViewInit, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';

import { SkeletonUiService } from './skeleton-ui.service';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'skeleton-ui',
  templateUrl: './skeleton-ui.component.html',
  styleUrls: ['./skeleton-ui.component.scss'],
  host: { class: 'my-trips-dropdown-skeleton-ui' },
  viewProviders: [ SkeletonUiService ],
  animations: [ opacityFade() ],
  encapsulation: ViewEncapsulation.None
})
export class SkeletonUiComponent implements AfterViewInit, OnDestroy {

  @Input() tripIcon: string | null;

  constructor(
    private service: SkeletonUiService
  ) { }

  ngAfterViewInit(): void {
    this.service.initSkeletonUIUpdates();
  }

  ngOnDestroy(): void {
    this.service.subscription$.next();
  }

}
