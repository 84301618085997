import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import { UserItineraryData } from '@hiptraveler/data-access/api';

@Pipe({
  name: 'filterTrips'
})
export class FilterTripsPipe implements PipeTransform {

  transform(value$: Observable<UserItineraryData[] | null>, input: string): Observable<any> {

    if (!value$) return of([])

    return value$.pipe(
      map(itineraries => itineraries?.filter(e => !!e?.title)?.map(itinerary => ({
        id: itinerary.id || '',
        title: itinerary.title || '-',
        subTitle: itinerary?.itiDays?.length > 1
          ? `Fiji Island, Asia, Really Far / ${itinerary?.itiDays?.length} days and ${itinerary?.itiDays?.length - 1} nights`
          : `Fiji Island, Asia, Really Far / ${itinerary?.itiDays?.length} day`,
        imgAlt: itinerary.pageTitle
      })) || []),
      map(trips => input ? trips.filter(trip => trip.title.toLowerCase().includes(input.toLowerCase())) : trips)
    );
  }

}
