import { Injectable } from '@angular/core';

import { FiltersService } from '../filters.service';
import { AdventuresDataService, HotelsDataService, FoodsDataService, ItinerariesDataService, ProfileListService } from './../../data-services';
import { FiltersStateService } from '../common';

@Injectable()
export class FilterClearService {

  constructor(
    private service: FiltersService,
    private filtersService: FiltersStateService,
    private adventuresData: AdventuresDataService,
    private hotelsData: HotelsDataService,
    private foodsData: FoodsDataService,
    private itinerariesData: ItinerariesDataService,
    private profileList: ProfileListService
  ) { }

  async clear(): Promise<void> {
    this.filtersService.clearFilters();
    this.service.form.patchValue({
      query: '', checkInDate: '', checkOutDate: ''
    });

    switch (this.filtersService.searchPage) {
      case 'search': {
        this.adventuresData.getResultsByLocationData({ default: true });
        this.hotelsData.getResultsByLocationData({ default: true });
        this.foodsData.getResultsByLocationData({ default: true });
        this.itinerariesData.getResultsByLocationData({ default: true });
        break;
      };
      case 'thingstodo':   return this.adventuresData.getResultsByLocationData({ default: true });
      case 'hotels':       return this.hotelsData.getResultsByLocationData({ default: true });
      case 'foodanddrink': return this.foodsData.getResultsByLocationData({ default: true });
      case 'experiences':  return this.itinerariesData.getResultsByLocationData({ default: true });
      case 'community':    return this.profileList.getResultsByLocationData();
    }
  }

}
