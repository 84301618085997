import { Store } from '@ngxs/store';
import { firstValueFrom, filter as rxFilter } from 'rxjs';
import { chain, flatMap, omit } from 'lodash';
import { DivIconOptions } from 'leaflet';

import { ActivityDateData, SearchData } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { GeoJSONFeatureCollection, getWindowRef, globalStateManagementStoreKey, leafletNamespaceKey, SearchResultData, SearchResultDataType } from "@hiptraveler/common";
import { GeoJsonProp } from './leaflet-map.interface';
import { markerDivIcon } from './leaflet-map-values';
import { getAdventureMarkerTemplate, getFoodMarkerTemplate, getHotelMarkerTemplate } from './leaflet-map-templates';

export var Leaflet = getWindowRef()[leafletNamespaceKey];
export function setNamespace(value: any): void {
  Leaflet = value;
}

export function toGeoJSON(results: SearchResultData[]): GeoJSONFeatureCollection<GeoJsonProp> {
  return {
    type: 'FeatureCollection',
    features: results.map((result: SearchResultData) => ({
      type: 'Feature',
      geometry: {  ...(result.location as any) },
      properties: {
        ...omit(result, [ 'location', 'address' ]) as SearchResultData,
        ...result.address,
        location: result.locationName,
        id: result.id
      } as GeoJsonProp
    }))
  };
}

export function toGeoMapData(value: any): any {
  return {
    id: value?.id,
    title: value?.name,
    image: value?.thumbnail,
    locationName: value?.location,
    address: {
      poi: value.name
    },
    location: {
      type: 'Point',
      coordinates: value?.loc?.coordinates || []
    }
  };
}

export function mapData(data: any[]): GeoJSONFeatureCollection<GeoJsonProp> {
  return toGeoJSON(data.filter(e => e.imgCategory !== 'Chill time').map(toGeoMapData));
};

export function markerIconBuilder(option: { theme: string, type: SearchResultDataType; day: number }) {

  const template = option.type === 'hotel' ? getHotelMarkerTemplate
    : option.type === 'adventure' ? getAdventureMarkerTemplate
    : getFoodMarkerTemplate;
  
  const field = `${option.type}MarkerIcon_colored${option.day}`;

  const icon: DivIconOptions = { ...markerDivIcon, html: template({ theme: option.theme }) };

  return { field, icon };
}

export function getTypeByItinerary(itinerary: any): Extract<SearchResultDataType, 'adventure' | 'food' | 'hotel'> {
  return itinerary?.type === 'hotel' ? 'hotel' 
    : itinerary?.imgCategory.includes('Food')
    ? 'food' : 'adventure';
}

export function parseTextData(value: string): string {
  value = value && !value.includes('undefined') ? value : '-';
  return value;
}

export async function brandStateLoaded(): Promise<unknown> {
  const store: Store = getWindowRef()[globalStateManagementStoreKey];
  if (!store) return;

  await firstValueFrom(store.select(BrandState.state).pipe(rxFilter(Boolean)));
  return;
}

/**
 * Extracts coordinate pairs from activity data.
 *
 * Iterates through each activity, merging its HotelArray and ImgArray,
 * filters out items without valid latitude and longitude, and returns
 * an array of [latitude, longitude] pairs.
 *
 * @param activities - An array of ActivityDateData objects.
 * @returns An array of coordinate pairs.
 */
export function extractActivityCoordinates(activities?: ActivityDateData[]): number[][] {
  const store: Store = getWindowRef()[globalStateManagementStoreKey];
  const itineraryArr = store.selectSnapshot(ItineraryState.actDateArr) || [];
  activities = activities || itineraryArr;

  return chain(activities)
    .flatMap((activity: ActivityDateData) => [
      ...activity?.HotelArray as any ?? [],
      ...activity?.ImgArray as any ?? []
    ])
    .filter((itinerary) => itinerary?.latitude && itinerary?.longitude)
    .map(e => [e.latitude, e.longitude])
    .filter(([ lat, lng ]) => lat !== 0 || lng !== 0)
    .value(); 
}

/**
 * Extracts coordinate pairs from activity data by day.
 *
 * Iterates through each activity, merging its HotelArray and ImgArray,
 * filters out items without valid latitude and longitude, and returns
 * an array of [latitude, longitude] pairs.
 *
 * @param activities - An array of ActivityDateData objects.
 * @returns An array of coordinate pairs.
 */
export function extractActivityCoordinatesByDay(day: number): number[][] {
  const store: Store = getWindowRef()[globalStateManagementStoreKey];
  const activities = store.selectSnapshot(ItineraryState.actDateMap)?.[day] || {};

  return chain(flatMap([
    ...activities.HotelArray as any ?? [],
    ...activities.ImgArray as any ?? []
  ]))
    .filter((value: SearchData) => value?.latitude && value?.longitude)
    .map(e => [ e.latitude, e.longitude ])
    .filter(([ lat, lng ]) => lat !== 0 || lng !== 0)
    .value(); 
}
