<div class="my-trips--dropdown mat-elevation-z4">

  <div class="my-trips--dropdown-input-wrapper">
    <mat-icon>search</mat-icon>
    <input type="text" placeholder="Search My Trips">
  </div>

  <mat-divider></mat-divider>

  <div class="my-trips--dropdown-item-list">

      <div class="my-trips--dropdown-item" [@opacityFade]>
        <img [lazyLoad]="tripIcon!" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" draggable="false">

        <ngx-skeleton-loader count="1" appearance="line" animation="pulse" style="height: 14.4px"
          [theme]="{ 'border-radius': '4px', height: '14.4px', width: '300px' }">
        </ngx-skeleton-loader>

        <ngx-skeleton-loader count="1" appearance="line" animation="pulse" style="height: 9.6px"
          [theme]="{ 'border-radius': '4px', height: '9.6px', width: '200px' }">
        </ngx-skeleton-loader>

        <button mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>

      <mat-divider></mat-divider>

      <div class="my-trips--dropdown-item" [@opacityFade]>
        <img [lazyLoad]="tripIcon!" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" draggable="false">

        <ngx-skeleton-loader count="1" appearance="line" animation="pulse" style="height: 14.4px"
          [theme]="{ 'border-radius': '4px', height: '14.4px', width: '300px' }">
        </ngx-skeleton-loader>

        <ngx-skeleton-loader count="1" appearance="line" animation="pulse" style="height: 9.6px"
          [theme]="{ 'border-radius': '4px', height: '9.6px', width: '200px' }">
        </ngx-skeleton-loader>

        <button mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>

  </div>

</div>
