import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AppListenerService, SearchLocationData, SearchLocationService } from '@hiptraveler/common';

@Component({
  selector: 'app-location-input-overlay',
  templateUrl: './location-input-overlay.component.html',
  styleUrls: ['./location-input-overlay.component.scss'],
  host: { class: 'search-page-root-filters-mobile-location-input-overlay' },
  encapsulation: ViewEncapsulation.None
})
export class LocationInputOverlayComponent implements OnInit, OnDestroy {

  formField: string = '';

  constructor(
    public dialog: MatDialog,
    private appListener: AppListenerService,
    private searchLocation: SearchLocationService
  ) { }

  ngOnInit(): void {
    this.appListener.mapsAutocompleteShadowState$$.next(false);
  }

  ngOnDestroy(): void {
    this.appListener.mapsAutocompleteShadowState$$.next(true);
  }

  locationChanged(value: SearchLocationData): void {
    this.search(value?.location);
    this.searchLocation.updateSearchLocation(value);
    this.dialog.closeAll();
  }

  search(location: string = this.formField): void {
    if (!location || location === this.formField) return;
    this.formField = location;
  }

}
