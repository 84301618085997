<div class="registration-form mat-elevation-z2" [class.page-view]="pageView === ''" [class.processing]="service.submitProcessing$ | async" *ngIf="appListener.languageLoadState$ | async">

  <h2>{{ type | titleByType }}</h2>

  <ng-container *ngIf="(service.existingTravelAgent$ | async) && pageView !== '';else notRegistered">

    <p class="registration-form--element registration-form--highlight-text" translate="reg-form.pending-approval"></p>

    <div class="registration-form--element registration-form--submit">
      <ui-action-button rounded [routerLink]="[ '/', service.lang, 'profile' ]">{{ 'reg-form.button-text-edit-profile' | translate }}</ui-action-button>
    </div>
    
  </ng-container>
  <ng-template #notRegistered>

    <ng-container *ngIf="(service.taSaved$ | async) && pageView !== '';else defaultUI">

      <p class="registration-form--element registration-form--highlight-text" translate="reg-form.dest-program-thank"></p>

      <div class="registration-form--element registration-form--submit">
        <ui-action-button rounded [routerLink]="[ '/', service.lang, 'profile' ]">{{ 'reg-form.button-text-edit-profile' | translate }}</ui-action-button>
      </div>

    </ng-container>
    <ng-template #defaultUI>

      <stepper-header [class]="type" [type]="type"></stepper-header>
    
      <agent-details (socialClicked)="socialClicked.emit($event)" [type]="type"></agent-details>
      <specialization></specialization>
      <activities></activities>
    
      <div [class]="'registration-form--layout-grid ' + type">
        <language-support></language-support>
        <certifications-licenses></certifications-licenses>
        <destination-specialties></destination-specialties>
        <travel-experience></travel-experience>
      </div>

      <promo-code *ngIf="type === 'ambassador'"></promo-code>

      <div class="registration-form--submit">
        <div activeSelection class="registration-form--link-id" id="register-your-interest"></div>
        <ui-action-button rounded (click)="service.registerInterest(type)" [pending]="(service.submitProcessing$ | async)!" #submitButton>
          {{ (pageView === '' ? 'reg-form.button-text-save-changes' : (type | buttonTextByType)) | translate }}
        </ui-action-button>
      </div>

    </ng-template>

  </ng-template>

</div>
