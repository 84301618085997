
<p class="user-profile--name">

  <span (click)="navigateToUserProfile()">{{ author?.authorName || 'Author' }}</span>

  <ng-container *ngIf="(service.buttonVisibility$ | async) && author?.authorReference !== 'htVisitor'">
    <ui-action-button size="medium" theme="white" [pending]="(service.processing$ | async)!" [@opacityFade] (click)="service.followProfile()" *ngIf="!owner">{{ ((service.following$ | async) ? 'page-banner.following' : 'page-banner.follow') | translate }}</ui-action-button>
  </ng-container>

</p>

<div class="user-profile--image" (click)="navigateToUserProfile()">
  <img [lazyLoad]="author?.authorProfPic || ''" [defaultImage]="profilePlaceholder" [errorImage]="profilePlaceholder" draggable="false" [alt]="author?.authorTitle">  
</div>
