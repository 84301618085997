
<feature-card actionCard actionButton toggleFavorite="hotels" type="hotel" [id]="hotel.id" [starRating]="hotel.starRating" [featureCardHover]="hotel" (click)="service.searchResultDialog.open(hotel, 'hotel')" *ngFor="let hotel of service.hotels$ | async;" [@opacityFade]>

  <itinerary-action-bubble [active]="!!(service.searchPageControl.activityDate$ | async) && (hotel.id | validateStatus: (service.actDate$ | async))">
    <itinerary-action [activityId]="hotel.id" [hotel]="true"></itinerary-action>
  </itinerary-action-bubble>
  <img featureCardImage [lazyLoad]="hotel?.thumbnail || ''" [defaultImage]="('hotel' | searchSvgPath | async)!" [errorImage]="('hotel' | searchSvgPath | async)!" [alt]="hotel.name">
  <feature-card-price [currency]="hotel?.lowestOffer?.currency" [amount]="hotel?.lowestOffer?.rate" *ngIf="hotel?.lowestOffer?.rate"></feature-card-price>
  <feature-card-title>{{ hotel.name }}</feature-card-title>
  <feature-card-location>{{ hotel.city }} {{ hotel.countryCd }}</feature-card-location>
  <feature-card-rating>{{ hotel.guestRating }}</feature-card-rating>
  <feature-card-reviews>{{ hotel.numReviews }}</feature-card-reviews>

</feature-card>

<ng-container *ngIf="!(service.hotels$ | async)">

  <ngx-skeleton-loader class="search-result-card" count="1" appearance="line" *ngFor="let _ of skeletons;" [@opacityFadeIn]>
  </ngx-skeleton-loader>

</ng-container>

<ng-container *ngIf="(service.hotels$ | async)?.length === 0">
  <p class="ht-no-results grid-element" translate="search-page.no-results-found"></p>
</ng-container>
