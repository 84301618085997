import { Pipe, PipeTransform } from '@angular/core';
import { ActivityDateDateData } from '@hiptraveler/data-access/api';

@Pipe({
  name: 'toItineraryDatePrefix'
})
export class ToItineraryDatePrefixStringPipe implements PipeTransform {

  /**
   * Transforms the input date string.
   * 
   * @param {string} inputDate - The date string to format, in MM/DD/YYYY format.
   * @returns {string} The formatted date string, in the format 'Day of Week, Month Name'.
   */
  transform(date?: ActivityDateDateData): string {

    if (!date) return '';

    return `${date.week_day}, ${date.month}`;
  }

}
