import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, map, of } from 'rxjs';
import { get, some } from 'lodash';

import { ActivityDate, ActivityDateData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { currentLang, SearchPageControlStateService } from '@hiptraveler/common';

@Pipe({
  name: 'addedToCurrentDay'
})
export class AddedToCurrentDayPipe implements PipeTransform {

  constructor(
    private router: Router,
    private store: Store,
    private searchPageControl: SearchPageControlStateService
  ) { }

  /**
   * Transforms an activity identifier into an Observable<boolean> that determines the UI styling.
   *
   * @param activityId - The unique identifier for the activity to check.
   * @returns An Observable<boolean> that signals whether to apply primary color styling.
   */
  transform(activityId: string): Observable<boolean> {

    if (this.router.url.includes(`/${currentLang()}/itinerary/`)) return of(true);

    return this.store.select(ItineraryState.actDateMap).pipe(
      map((actDateMap: ActivityDate | null) => {

        const day = this.searchPageControl.activityDate?.day || 0;
        const activityDate = get(actDateMap, day, null) as ActivityDateData;

        const hotelExists = some(activityDate?.HotelArray || null, { id: activityId });
        const imageExists = some(activityDate?.ImgArray || null, { id: activityId });

        return !(hotelExists || imageExists);
      })
    );
  }

}
