import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Location, isPlatformServer } from '@angular/common';
import { ActivationEnd, Event, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter, map, switchMap, take, tap } from 'rxjs';

import { LocationData, SearchApiService } from '@hiptraveler/data-access/api';
import { SearchAction, SearchState } from '@hiptraveler/data-access/search';
import { AppListenerService, HT_SEARCH_LOCATION_KEY, LOCAL_STORAGE, RequestCancellationService, SearchLocationData, SearchLocationService, currentLang, getLocationData } from '@hiptraveler/common';

@Injectable()
export class SearchLocationStateService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(LOCAL_STORAGE) private readonly localStorage: Storage,
    private store: Store,
    private router: Router,
    private location: Location,
    private searchApi: SearchApiService,
    private appListener: AppListenerService,
    private requestCancellation: RequestCancellationService,
    private searchLocation: SearchLocationService
  ) { }

  observe(): void {

    if (isPlatformServer(this.platformId)) return;

    // window.onload without placename but with location in storage
    if (this.searchLocation.data && !this.searchLocation.placename) {
      this.localStorage?.removeItem(HT_SEARCH_LOCATION_KEY);
    }

    // url update and location from storage updates on location changes from state
    this.searchLocation.searchLocation$.pipe(
      tap((searchLocation: SearchLocationData) => {
        console.log('@@@ ', 'New location update.', searchLocation);
        this.localStorage?.setItem(HT_SEARCH_LOCATION_KEY, JSON.stringify(searchLocation));
        this.softUpdateUrlPathBySearchLocationData(searchLocation, this.searchLocation.searchRoute);
      })
    ).subscribe();

    // window.onload with placename but no location in storage
     this.store.select(SearchState.locationData).pipe(
      take(1),
      filter(() => !this.appListener.navigated),
      filter(v => !v && !!this.searchLocation.placename && !this.searchLocation.data?.locId),
      switchMap(() => {
        const placename = this.searchLocation.placename || '';
        return this.searchApi.getAutocompleteLocation({ q: decodeURIComponent(placename) }).pipe(
          map(e => ({ q: e.locations[0].city, locId: e.locations[0].id })),
          switchMap(({ q, locId }) => this.store.dispatch(new SearchAction.GetLocationDataByQuery({ q, locId }))),
          take(1)
        );
      }),
      switchMap(() => this.store.select(SearchState.locationData)),
      tap((location: LocationData | null) => this.searchLocation.updateSearchLocation({
        name: location?.city,
        location: location?.formattedAddr || '',
        locId: location?.id || location?.gPlaceId || location?.countryId || '',
        latitude: +(location?.latitude || '0'),
        longitude: +(location?.longitude || '0'),
        type: location?.type || ''
      }))
    ).subscribe();

    // handles remove location from state
    this.searchLocation.searchLocation$$.pipe(filter(e => !e)).subscribe(() => {

      this.localStorage?.removeItem(HT_SEARCH_LOCATION_KEY);
      this.store.selectSnapshot(SearchState.state)?.locationData
        && this.store.dispatch(new SearchAction.ResetSearchState([ 'locationData' ]));

      this.softUpdateUrlPathBySearchLocationData();
      this.requestCancellation.cancelDynamicRequests();
    });

    // url updates on navigation changes
    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      filter(() => !!this.searchLocation.data),
      tap(() => this.softUpdateUrlPathBySearchLocationData(this.searchLocation.data!, true))
    ).subscribe();

    // page visit (except navigation changes) handling for search pages
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd),
      map((event: any) => event.snapshot._routerState.url as string),
      take(1),
      filter(url => this.searchLocation.searchRouteByUrl(url) && !this.store.selectSnapshot(SearchState.state)?.locationData),
      tap(() => this.searchLocation.updateSearchLocation(
        this.searchLocation.data || undefined
      ))
    ).subscribe();

    // page visit (except navigation changes) handling for non search pages
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd),
      map((event: any) => event.snapshot._routerState.url as string),
      take(1),
      filter(url => !this.searchLocation.searchRouteByUrl(url) && !this.store.selectSnapshot(SearchState.state)?.locationData),
      tap(() => this.searchLocation.updateSearchLocation(undefined))
    ).subscribe();
  }

  private softUpdateUrlPathBySearchLocationData(searchLocation?: SearchLocationData, preventNavigation?: boolean): void {

    const ignoredRoutes: string[] = [
      `/${currentLang()}/compose/`, `/${currentLang()}/itinerary/`, `/${currentLang()}/travel-story/`
    ];

    const noNavigation: boolean = ignoredRoutes.some((url: string) => getLocationData().pathname.includes(url));

    if (searchLocation && !noNavigation && !this.searchLocation.searchRoute) {
      preventNavigation || this.router.navigateByUrl(`/${currentLang()}/search/${searchLocation.name}`);
      return;
    }

    if (this.searchLocation.searchRoute) {
      this.replaceUrlState(searchLocation);
    }
  }

  private replaceUrlState(searchLocation?: SearchLocationData): void {
    const locationName = searchLocation ? `/${searchLocation.name}` : '';
    const searchRoutePath = this.searchLocation.searchRoutePath;
    const searchRouteQuery = getLocationData().search;
    const newPath = `/${currentLang()}/${searchRoutePath}${locationName}${searchRouteQuery}`;
    this.location.replaceState(newPath);
  }

}
