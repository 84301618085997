
<div inputControlStyleDynamic></div>

<ng-container *ngIf="service.overlayVisibility$ | async">
  <div
    class="input-control--summary-tags" dynamicHeight
    [ngClass]="{ mobile: stateService.isMobile$ | async}"
    (click)="service.registerOverlayTrigger(true)"
    (mouseenter)="service.registerOverlayTrigger(true)"
    (mouseleave)="service.registerOverlayTrigger(false)">
    <summary-tags [@opacityFade]></summary-tags>
  </div>
</ng-container>

<div
  class="input-control--filter mat-elevation-z1" matRipple
  (click)="service.overlayTriggerMouseOver()"
  (mouseenter)="service.overlayTriggerMouseOver()"
  (mouseleave)="service.triggerCloseOverlay()"
  [ngClass]="{
    pending: (stateService.inputPending$ | async) || (service.summaryTags$ | async) === null,
    hide: !(service.inputControlVisibility$ | async)
  }">

  <ng-container *ngIf="!(stateService.inputPending$ | async) && (service.summaryTags$ | async) !== null">
    <ng-container *ngIf="service.summaryTags$ | async as summaryTags;">
  
      <ng-container *ngIf="summaryTags.length">
        <p>{{ summaryTags.length }}</p>
        <mat-icon>filter_alt</mat-icon>
      </ng-container>
  
      <ng-container *ngIf="summaryTags.length === 0">
        <mat-icon>filter_alt</mat-icon>
      </ng-container>
      
    </ng-container>
  </ng-container>

  <mat-progress-spinner theme="white" mode="indeterminate" [strokeWidth]="2" [diameter]="15"></mat-progress-spinner>

</div>

<div class="input-control--chips" [ngClass]="{ hide: service.inputControlVisibility$ | async }" [@chipListAnimation]="(service.summaryTagChips$ | async)?.length">
  <div class="input-control--chip-item" *ngFor="let item of service.summaryTagChips$ | async;let i = index">
    {{ item.output }}
    <div class="input-control--chip-item-close" (click)="stateService.removeSummaryItem(i)">
      <mat-icon>clear</mat-icon>
    </div>
  </div>
</div>

<input
  type="text"
  class="input-control--field"
  [placeholder]="service.inputPlaceholder$ | async"
  [disabled]="(stateService.inputPending$ | async)!"
  [(ngModel)]="contentInput"
  (input)="onInput()"
  (keyup.enter)="sendMessage()"
  #inputField>

<div class="input-control--action" type="button" matRipple animateSendButton (click)="nextScreen();sendMessage()" (dblclick)="submitMessage()">
  <mat-icon>send</mat-icon>
</div>

<div class="input-control--background"></div>
