import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { SnackbarService } from '@hiptraveler/snackbar';
import { currentLang, getWindowRef, ofActionDispatchedSignOutNoAuthState } from '@hiptraveler/common';

@Injectable()
export class SignOutNoAuthService {

  constructor(
    private router: Router,
    private snackbar: SnackbarService,
  ) { }

  async handle(): Promise<void> {

    await this.router.navigate(this.routePath);

    const message = 'Session expired or not signed in. Please sign in again to continue.';
    this.snackbar.open({ message, duration: 9000 });

    setTimeout(() => (delete getWindowRef()[ofActionDispatchedSignOutNoAuthState]), 1000);
  }

  get routePath(): string[] {

    if (this.router.url.startsWith(`/${currentLang()}/compose/itinerary/`)) {
      const pageTitle = this.router.url.split('/compose/itinerary/')[1];
      return [ currentLang(), 'itinerary', pageTitle ];
    }

    if (this.router.url.startsWith(`/${currentLang()}/compose/travel-story/`)) {
      const pageTitle = this.router.url.split('/compose/travel-story/')[1];
      return [ currentLang(), 'travel-story', pageTitle ];
    }
    
    return [ currentLang() ];
  }

}
