import * as moment from 'moment';

import { CheckInDateRange } from '@hiptraveler/data-access/api';
import { cleanObject, filterQueryStringKey, getWindowRef, objectToQueryString, queryStringToObject } from "@hiptraveler/common";
import { ListItem } from "../list-items";

export function param(value: string): string {
  switch (value) {
    case 'thingstodo':   return 'activities';
    case 'hotels':       return 'amenities';
    case 'foodanddrink': return 'cuisine';
    default:             return 'activities';
  }
}

export function toCheckInDateRange({ checkInDate, checkOutDate }: CheckInDateRange<Date>): CheckInDateRange {
  return { // Format: MM-DD-YYYY
    checkInDate: checkInDate ? moment(checkInDate).format('MM-DD-YYYY') : '',
    checkOutDate: checkInDate ? moment(checkOutDate).format('MM-DD-YYYY') : '',
  }
}

export function paramObject(values: any, listItems: any): any {
  const result: any = {};
  values.forEach((value: any) => {
    const matchedItem = listItems.find((item: any) => item.value === value);
    if (matchedItem) {
      const type = matchedItem.type === 'exp' ? 'experiences' : matchedItem.type;
      result[type] = result[type] || [];
      result[type].push(matchedItem.value);
    }
  });
  return result;
}

export function processFilters(key: string, results: ListItem[]): ListItem[] {
  const filterQuery = getWindowRef()[filterQueryStringKey];
  const searchQuery = queryStringToObject(filterQuery);
  return results.filter(e => searchQuery[key]?.split(',')?.includes(e.value));
}

export function queryParams(params: any): string {
  const filterQuery = getWindowRef()[filterQueryStringKey];
  const searchQuery = queryStringToObject(filterQuery);
  const { keyword = '', checkInDate = '', checkOutDate = '', ...others } = {  ...searchQuery, ...params };
  return `?${objectToQueryString(cleanObject({
    ...others,
    checkInDate,
    checkOutDate,
    keyword,
  }))}`;
}
