<div class="my-trips" layoutObserver>

  <div class="my-trips--nav-back" (click)="navigateToDefaultView()"></div>

  <ng-container *ngIf="service.createNewTripButtonState$$ | async">
    <h2 [@opacityFade]>You haven't created any trips yet. Plan your first trip now!</h2>
    <div class="my-trips--call-to-action" matRipple [@opacityFade]>
      <p>Plan a New Trip</p>
    </div>
  </ng-container>

  <skeleton-ui [tripIcon]="service.myTripIcon$ | async"></skeleton-ui>

  <div class="my-trips--dropdown mat-elevation-z4" *ngIf="(service.itineraries$ | filterTrips: '' | async)?.length">

    <div class="my-trips--dropdown-input-wrapper">
      <mat-icon>search</mat-icon>
      <input type="text" [(ngModel)]="myTripTitleControl" placeholder="Search My Trips">
    </div>

    <mat-divider></mat-divider>

    <div class="my-trips--dropdown-item-list">
      <ng-container *ngFor="let trip of service.itineraries$ | filterTrips: myTripTitleControl | async;let isLast = last">

        <div class="my-trips--dropdown-item" [@opacityFade]>
          <img selectionNavigation [itinerary]="trip.id" [lazyLoad]="(service.myTripIcon$ | async)!" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" draggable="false" [alt]="trip?.imgAlt">
  
          <p selectionNavigation [itinerary]="trip.id">{{ trip?.title }}</p>
  
          <p selectionNavigation [itinerary]="trip.id">{{ trip?.subTitle }}</p>
  
          <button mat-icon-button [matMenuTriggerFor]="itemMenu">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #itemMenu="matMenu" xPosition="before">
            <button mat-menu-item selectionNavigation [itinerary]="trip.id" selection="open">
              <mat-icon>launch</mat-icon>
              <span>Open</span>
            </button>
            <button mat-menu-item selectionNavigation [itinerary]="trip.id" selection="copy-link">
              <mat-icon>content_copy</mat-icon> 
              <span>Copy Link</span>
            </button>
            <button mat-menu-item selectionNavigation [itinerary]="trip.id" selection="share">
              <mat-icon>share</mat-icon>
              <span>Share</span>
            </button>
            <button mat-menu-item selectionNavigation [itinerary]="trip.id" selection="delete">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>

        </div>

        <mat-divider></mat-divider>

      </ng-container>
    </div>

  </div>

</div>
