import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { filter, Subject, takeUntil, tap } from 'rxjs';

import { InputControlService } from './input-control.service';

const HALF_SIZE_VALUE: number = 270;

@Directive({
  selector: '[inputControlStyleDynamic]'
})
export class InputControlStyleDynamicDirective implements AfterViewInit, OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private service: InputControlService
  ) { }

  ngAfterViewInit(): void {
    this.subscribeToSummaryTags();
    this.adjustInputPaddingIfHidden();

    const host = this.element.nativeElement.parentElement;
    const inputControlChips = host?.querySelectorAll('.input-control--chips').item(0) as HTMLElement;

    const observer = new ResizeObserver((entries: readonly ResizeObserverEntry[]) => {
      const entryWidth = entries[0].contentRect.width;
      this.service.inputControlVisibility$$.next(entryWidth > HALF_SIZE_VALUE);

      const inputControlField = host?.querySelectorAll('.input-control--field').item(0) as HTMLElement;
      if (inputControlField && !this.service.inputControlVisibility$$.value) {
        inputControlField.style.paddingLeft = `${entryWidth + 12}px`;
      }
    });

    observer.observe(inputControlChips);


    this.service.inputControlVisibility$.pipe(filter(Boolean)).subscribe(() => {
      const host = this.element.nativeElement.parentElement;
      const inputControlField = host?.querySelectorAll('.input-control--field').item(0) as HTMLElement;
      if (inputControlField) {
        inputControlField.style.paddingLeft = '64px';
      }
    });
  }
  
  subscribeToSummaryTags(): void {
    this.service.summaryTags$.pipe(
      takeUntil(this.subscription$),
      tap(items => this.service.summaryTagChips$$.next(items))
    ).subscribe();
  }

  adjustInputPaddingIfHidden(): void {
    const host = this.element.nativeElement.parentElement;
    const inputControlField = host?.querySelectorAll('.input-control--field').item(0) as HTMLElement;
    if (inputControlField && !this.service.inputControlVisibility$$.value) {
      inputControlField.style.paddingLeft = '14px';
    }
  }

  ngOnDestroy(): void {
    this.subscription$.next()
  }

}
