import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, delay, filter, takeUntil, tap } from 'rxjs';

import { BrandCampaign } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { NavbarControlStateService, ScrollListenerService, currentLang, iframeWindowFixedTop, preventExperienceFinderBubbleStateKey } from '@hiptraveler/common';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';

@Injectable()
export class HomepageBannerService {

  @Select(BrandState.brandCampaign) brandCampaign$: Observable<BrandCampaign | null>;
  @Select(BrandState.hiptravelerBrand) hiptravelerBrand$: Observable<BrandCampaign | null>;

  subscription$ = new Subject<void>();
  experienceFinderState$$ = new BehaviorSubject<boolean>(true);

  constructor(
    private router: Router,
    private scrollListener: ScrollListenerService,
    private navbarControl: NavbarControlStateService,
    private experienceFinder: ExperienceFinderStateService,
  ) { }

  observeScrollChanges(): void {

    sessionStorage.removeItem(preventExperienceFinderBubbleStateKey);

    // this.experienceFinder.overlayState$.pipe(
    //   filter(Boolean),
    //   delay(100),
    //   tap(() => this.experienceFinder.dialogView$$.next('travel-style')),
    //   takeUntil(this.subscription$)
    // ).subscribe();

    this.scrollListener.scrollPosition$.pipe(
      delay(0),
      takeUntil(this.subscription$)
    ).subscribe((scrollTop: number) => {
    
      if (sessionStorage.getItem(preventExperienceFinderBubbleStateKey)) return;

      if (scrollTop > 112) {
        iframeWindowFixedTop(true);
        this.experienceFinderState$$.value
          && this.experienceFinderState$$.next(false);
        !this.navbarControl.navbarToolbarVisibility$$.value
          && this.navbarControl.navbarToolbarVisibility$$.next(true);
      } else {
        iframeWindowFixedTop(false);
        !this.experienceFinderState$$.value
          && this.experienceFinderState$$.next(true);
        this.navbarControl.navbarToolbarVisibility$$.value
          && this.navbarControl.navbarToolbarVisibility$$.next(false);
      }
    });

    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => {
        if (event.url !== `/${currentLang()}`) {
          this.experienceFinder.transparentBackdrop$$.next(false);
        }
      })
    ).subscribe()

    this.experienceFinderState$$.pipe(takeUntil(this.subscription$)).subscribe((state: boolean) => {
      this.experienceFinder.overlayPositionState$$.next(!state);
      this.experienceFinder.transparentBackdrop$$.next(state);
    });
  }

  ngOnDestroy(): void {
    iframeWindowFixedTop(true);
    // this.experienceFinder.dialogView$$.next('main-menu');
    this.subscription$.next();
    setTimeout(() => {
      this.experienceFinder.overlayPositionState$$.next(true);
    }, 1000);
  }

}
