import { Pipe, PipeTransform } from '@angular/core';
import { from, Observable } from 'rxjs';

import { fetchSearchResultIconUrl } from '@hiptraveler/common';

@Pipe({
  name: 'searchSvgPath'
})
export class SearchSvgPathPipe implements PipeTransform {

  transform(type: 'adventure' | 'hotel' | 'foodAndDrinks'): Observable<string> {
    return from(fetchSearchResultIconUrl(type));
  }

}
