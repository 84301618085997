import { Component, ElementRef, HostListener, Input, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, takeUntil, tap } from 'rxjs';

import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { AppListenerService, authenticatedProfilePlaceholder, currentLang, pendingAuthProcessKey } from '@hiptraveler/common';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { PostActionButtonService } from './post-action-button.service';

@Component({
  selector: 'post-action-button',
  templateUrl: './post-action-button.component.html',
  styleUrls: ['./post-action-button.component.scss'],
  host: { class: 'ht-post-action-button' },
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ PostActionButtonService, AuthDialogActionService ]
})
export class PostActionButtonComponent implements OnDestroy {

  @ViewChild('postActionButton') hostContent: ElementRef<HTMLDivElement>;

  @Input() elevation: number = 8;

  authHandle$ = new Subject<void>();
  authenticatedProfilePlaceholder: string = authenticatedProfilePlaceholder;

  constructor(
    private router: Router,
    private store: Store,
    private appListener: AppListenerService,
    private authDialog: AuthDialogActionService,
    public service: PostActionButtonService
  ) { }

  ngOnDestroy(): void {
    this.resetAuthHandle();
  }
  
  @HostListener('click')
  hostClicked(): unknown {
    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'postActionButton';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(this.hostClicked.bind(this));
      return;
    }
    return this.router.navigate([ currentLang(), 'compose', 'new-story' ]);
  }

  private resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

}
