import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { get } from 'lodash';
import { delay, filter, fromEvent, Subject, take, takeUntil, tap } from 'rxjs';

import { BrandState } from '@hiptraveler/data-access/brand';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { AppListenerService, chromaNamespaceKey, getWindowRef, globalStateManagementStoreKey, siteNavigationCloseActionKey } from '@hiptraveler/common';
import { ActivityDateData } from '@hiptraveler/data-access/api';

/**
 * Retrieves the itinerary day corresponding to the specified itinerary ID.
 *
 * Iterates over the activity date map stored in the global state and checks each day's
 * HotelArray and ImgArray to determine if the itinerary ID exists. Returns the day number
 * if a match is found; otherwise, returns 0.
 *
 * @param itineraryId - The unique identifier for the itinerary.
 * @returns The day number associated with the itinerary, or 0 if not found.
 */
export function getItineraryDayById(itineraryId: string): number {
    const store: Store = getWindowRef()[globalStateManagementStoreKey];
    const itineraryData = store.selectSnapshot(ItineraryState.actDateMap) || {};
  
  for (const [day, data] of Object.entries(itineraryData)) {
    if (data.HotelArray?.some(hotel => hotel.id === itineraryId) || 
      data.ImgArray?.some(img => img.id === itineraryId)) {
      return +day;
    }
  }
  return 0;
}

@Directive({
  selector: '[actionColor]'
})
export class ItineraryActionBubbleColorDirective implements AfterViewInit {

  @Input() itineraryId: string;
  @Input() active: boolean;

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private store: Store,
    private actions$: Actions,
    private appListener: AppListenerService
  ) { }

  get elementRef(): HTMLElement {
    return this.element.nativeElement;
  }

  ngAfterViewInit(): void {
    this.initializeItineraryView();
    this.observeItineraryReset();
  }

  /**
   * Initializes the itinerary view.
   *
   * Emits an update to the subscription, determines the current itinerary day,
   * retrieves the corresponding activity data, applies the dynamic button color,
   * and subscribes to itinerary action completion events to reinitialize the view.
   */
  private initializeItineraryView(): void {
    this.subscription$.next();

    const day = getItineraryDayById(this.itineraryId) || 0;
    const activity = this.store.selectSnapshot(ItineraryState.actDateMap)?.[day];

    try { this.applyDynamicButtonColor(activity) } catch (e) {}
    this.subscribeToItineraryActionCompletion();
  }

  /**
   * Applies dynamic button color based on the activity's day color or the primary brand color.
   *
   * Sets the button's background color and registers event listeners to adjust the color on hover.
   *
   * @param activity - Optional activity data used to derive the day-specific color.
   */
  private applyDynamicButtonColor(activity?: Partial<ActivityDateData>): void {
    const chroma = getWindowRef()?.[chromaNamespaceKey];
    const colorPalette = this.store.selectSnapshot(BrandState.campaignCustom)?.colorPalette;
    const primaryColor = get(colorPalette, 'mainColor', '#00a6ff');
    const primaryColorHex = this.active ? get(activity, 'dayColor', primaryColor) : primaryColor;
    const primaryColorRgb = chroma(primaryColorHex).rgb() || [0, 166, 255];
    const [r, g, b] = primaryColorRgb;
    const primaryColorHexHover = chroma.rgb(r, g, b).brighten(0.15).hex() || '#1aafff';

    this.elementRef.style.backgroundColor = primaryColorHex;

    fromEvent(this.elementRef, 'mouseenter').pipe(takeUntil(this.subscription$)).subscribe(() => {
      this.elementRef.style.backgroundColor = primaryColorHexHover;
    });
    fromEvent(this.elementRef, 'mouseleave').pipe(takeUntil(this.subscription$)).subscribe(() => {
      this.elementRef.style.backgroundColor = primaryColorHex;
    });
  }

  /**
   * Subscribes to itinerary action completion events.
   *
   * Listens for successful itinerary activity updates, waits for 1 second, and then
   * reinitializes the itinerary view.
   */
  private subscribeToItineraryActionCompletion(): void {
    this.actions$.pipe(
      ofActionCompleted(ItineraryAction.UpdateItineraryActivityByResponse, ItineraryAction.UpdateItineraryActivity),
      filter(e => e.result.successful),
      delay(1000),
      tap(this.initializeItineraryView.bind(this)),
      take(1)
    ).subscribe();
  }

  private observeItineraryReset(): void {
    this.appListener.globalSignalListener(siteNavigationCloseActionKey).pipe(
      takeUntil(this.subscription$)
    ).subscribe(() => this.applyDynamicButtonColor({}));
  }

}
