import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { UserState } from '@hiptraveler/data-access/user';
import { currentLang, DEFAULT_BRAND_NAME, getWindowRef, isWidget } from '@hiptraveler/common';
import { BrandState } from '@hiptraveler/data-access/brand';
import { environment } from '@hiptraveler/environments';
import { EFAStateServiceService } from '../../shared';

@Directive({
  selector: '[optionAction]'
})
export class OptionActionDirective {

  @Input() optionAction: 'my-trips' | 'my-profile' | 'explore'

  constructor(
    private router: Router,
    private store: Store,
    private authDialog: AuthDialogActionService,
    private stateService: EFAStateServiceService
  ) { }

  @HostListener('click')
  hostClicked(): void {
    if (!this.store.selectSnapshot(BrandState.state)) return;

    if (this.optionAction === 'my-trips') {
      this.stateService.myTripsViewState$$.next(true);
    }

    if (this.optionAction === 'my-profile') {
      const authenticated = this.store.selectSnapshot(UserState.authenticated);
      authenticated ? this.navigateToProfile() : this.authDialog.open();
    }

    if (this.optionAction === 'explore') {
    }

  }

  navigateToProfile(): void {
    const { build } = environment;
    const { cId, cSubDomain } = this.store.selectSnapshot(BrandState.brandCampaign)!;
    const profileId = this.store.selectSnapshot(UserState.profileId);

    if (isWidget()) {

      if (cId === DEFAULT_BRAND_NAME) {
        getWindowRef().location.href = `https://www.hiptraveler.com/${currentLang()}/profile/${profileId}/stories`;
        return;
      } 
      
      if (cId !== DEFAULT_BRAND_NAME || build === 'Staging') {
        getWindowRef().location.href = `https://${cSubDomain}/${currentLang()}/profile/${profileId}/stories`;
      }

    } else {
      this.router.navigateByUrl(`/${currentLang()}/profile/${profileId}/stories`)
    }
  }

}
