import { AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';

import { AUTH_DIALOG_ID } from '@hiptraveler/dialogs/auth-dialog';
import { ExpFinderPanelAction, expFinderPanelAction } from '@hiptraveler/features/experience-finder';
import { ExperienceFinderWidgetService } from './experience-finder-widget.service';
import { getWindowRef, isIframe, isWidget, promiseDelay } from '@hiptraveler/common';

@Component({
  selector: 'app-experience-finder-v2',
  template: `
    <img src="https://d36j46pvk6qi4f.cloudfront.net/site/main/images/Hero2021-2200px.jpg">
    <ng-template #experienceFinder></ng-template>
  `,
  host: { class: 'page-experience-finder-ai' },
  styleUrls: [ './experience-finder-widget.component.scss' ],
  viewProviders: [ ExperienceFinderWidgetService ],
  encapsulation: ViewEncapsulation.None
})
export class ExperienceFinderWidgetComponent implements OnInit, AfterViewInit {

  @ViewChild('experienceFinder', { read: ViewContainerRef }) experienceFinderRef: ViewContainerRef;

  panelAction: ExpFinderPanelAction;
  initialized: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private dialog: MatDialog,
    private service: ExperienceFinderWidgetService
  ) { }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      const dialog = this.dialog.getDialogById(AUTH_DIALOG_ID);
      dialog || this.experienceFinderPanelDisplay(false);
    }
    if (event.code === 'Space') {
      this.experienceFinderPanelDisplay(true)
    }
  }

  ngOnInit(): void {
    this.initialized = false;
    this.service.initialize();
    this.service.widgetDisplayStateListener((state: boolean) => {
      this.experienceFinderPanelDisplay(state);
    })
  }
  
  ngAfterViewInit(): void {
    this.panelAction = expFinderPanelAction();
    this.experienceFinderPanelDisplay(true);
    this.initialized = false;
  }
  
  async experienceFinderPanelDisplay(state: boolean): Promise<void> {
    if (!isPlatformBrowser(this.platformId)) return;

    state || getWindowRef()?.top?.postMessage({ message: 'ht_exp_finder_modal_close' }, '*');

    if (this.initialized) {
      await promiseDelay(500);
    }

    const openOptions = {
      action: this.service.action,
      desktop: !(isWidget() && isIframe())
    };

    state
      ? this.panelAction?.openExperienceFinderPanel(this.experienceFinderRef, openOptions)
      : this.panelAction?.closeExperienceFinder();
  }

}
